import React from "react";
import label from "../../data/labels";
import CustomerType from "../partitions/CustomerType";
import {
  ValidationForm,
  TextInput,
  Checkbox
} from "react-bootstrap4-form-validation";
import MaskWithValidation from "../formElements/MaskWithValidation";
import { sendRegisterClient } from "../../data/dao";
import { blockUIStyleUploading, agreementModal } from "../../data/utility";
import AgreementModal from "../modals/AgreementModal";

function read(onSuccess, file) {
  var reader = new FileReader();

  reader.onload = function() {
    onSuccess(reader.result);
  };

  reader.readAsDataURL(file);
}

class Guarantor extends React.Component {
  constructor(props) {
    super(props);

    this.handler = this.handler.bind(this);
    this.handlerUpload = this.handlerUpload.bind(this);
    this.handlerAgreement = this.handlerAgreement.bind(this);
    this.handlerLocalState = this.handlerLocalState.bind(this);

    // onChange
    // resetForm
    // handlerChange
    // handleSubmit
    // handleErrorSubmit

    this.formRef = React.createRef();
    this.state = {
      ...props,
      support: {
        reset: false,
        immediate: false,
        setFocusOnError: true,
        clearInputOnReset: false
      }
    };
  }

  /**
   * The form is valid. Send data
   */
  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    blockUIStyleUploading();
    // SEND DATA FROM HERE AND WAIT FOR A RESPONSE
    sendRegisterClient(this);
  };

  /**
   * The form is not valid.
   */
  handleErrorSubmit = (e, formData, errorInputs) => {
    let clone = { ...this.state };
    clone.support.reset = true;
    this.setState({ ...clone });
  };

  /**
   * Reset validation's messages
   */
  resetForm = () => {
    // if (typeof this.formRef.current !== 'undefined')
    //     this.formRef = React.createRef();

    let formRef = this.formRef.current;
    if (typeof formRef !== "undefined" && formRef !== null)
      formRef.resetValidationState(this.state.clearInputOnReset);
  };

  /**
   * Handler send data to the parent controller
   * @param {*} newState
   */
  handler(newState) {
    this.setState({ ...newState }, () => this.props.handler(this.state));
  }

  handlerLocalState(newState) {
    this.setState({ ...newState });
  }

  /**
   * Handler collect all changes in the form
   */
  handlerChange = name => event => {
    const { value } = event.target;
    let clone = { ...this.state };
    let obj = name.split(".");

    if (clone.support.reset === true) {
      clone.support.reset = false;
      this.resetForm();
    }

    this.setValue(obj, clone, value);
    this.setState({ ...clone });
  };

  /**
   * Handler gets uploaded files data
   */
  handlerUpload = name => event => {
    const files = Array.from(event.target.files);

    const formData = [];

    files.forEach((file, i) => {
      read(function(r) {
        formData.push({
          fileName: file.name,
          fileType: file.type,
          fileContent: r.split(",")[1]
        });
      }, file);
    });

    let clone = { ...this.state };
    clone.guarantor.attachments[name] = formData; // formData;

    this.setState({ ...clone });
  };

  handlerAgreement = () => event => {
    //const { value } = event.target;
    let clone = { ...this.state };

    clone.uiSupport.agreementStatusG = !clone.uiSupport.agreementStatusG;
    if (clone.uiSupport.agreementStatusG === true) {
      agreementModal();
    }
    this.setState({ ...clone });
  };

  setValue(obj, clone, value) {
    if (obj.length === 1) {
      clone[obj] = value;
    } else {
      this.setValue(obj.slice(1), clone[obj[0]], value);
    }
  }

  componentDidMount() {
    /* Change background */
    let clone = { ...this.state };
    clone.bg = "background0";
    this.setState({ ...clone }, () => this.handler(this.state));

    mountInputFileComponent();
  }

  getCompanyName() {
    if (
      this.state.guarantor.guaranteeInfo.firstName.length !== 0 ||
      this.state.guarantor.guaranteeInfo.lastName.length !== 0
    ) {
      return (
        <TextInput
          className="form-control input-lg"
          id="txtCompanyName"
          name="companyName"
          type="text"
          maxLength="50"
          placeholder={label.namePlaceholder}
          value={this.state.guarantor.guaranteeInfo.companyName}
          onChange={this.handlerChange("guarantor.guaranteeInfo.companyName")}
        />
      );
    } else {
      return (
        <TextInput
          className="form-control input-lg"
          id="txtCompanyName"
          name="companyName"
          type="text"
          maxLength="50"
          placeholder={label.namePlaceholder}
          value={this.state.guarantor.guaranteeInfo.companyName}
          onChange={this.handlerChange("guarantor.guaranteeInfo.companyName")}
          required
        />
      );
    }
  }

  getFirstName() {
    if (this.state.guarantor.guaranteeInfo.companyName.length !== 0) {
      return (
        <TextInput
          className="form-control input-lg"
          id="txtGuaranteeFirstName"
          name="firstNameG"
          type="text"
          maxLength="30"
          placeholder={label.receiverFirstNamePlaceholder}
          value={this.state.guarantor.guaranteeInfo.firstName}
          onChange={this.handlerChange("guarantor.guaranteeInfo.firstName")}
        />
      );
    } else {
      return (
        <TextInput
          className="form-control input-lg"
          id="txtGuaranteeFirstName"
          name="firstNameG"
          type="text"
          maxLength="30"
          placeholder={label.receiverFirstNamePlaceholder}
          value={this.state.guarantor.guaranteeInfo.firstName}
          onChange={this.handlerChange("guarantor.guaranteeInfo.firstName")}
          required
        />
      );
    }
  }

  getLastName() {
    if (this.state.guarantor.guaranteeInfo.companyName.length !== 0) {
      return (
        <TextInput
          className="form-control input-lg"
          id="txtGuaranteeLastName"
          name="lastNameG"
          type="text"
          maxLength="30"
          placeholder={label.receiverLastNamePlaceholder}
          value={this.state.guarantor.guaranteeInfo.lastName}
          onChange={this.handlerChange("guarantor.guaranteeInfo.lastName")}
        />
      );
    } else {
      return (
        <TextInput
          className="form-control input-lg"
          id="txtGuaranteeLastName"
          name="lastNameG"
          type="text"
          maxLength="30"
          placeholder={label.receiverLastNamePlaceholder}
          value={this.state.guarantor.guaranteeInfo.lastName}
          onChange={this.handlerChange("guarantor.guaranteeInfo.lastName")}
          required
        />
      );
    }
  }

  render() {
    const upIcon = (
      <strong>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
      </strong>
    );

    return (
      <div>
        <ValidationForm
          onSubmit={this.handleSubmit}
          onErrorSubmit={this.handleErrorSubmit}
          ref={this.formRef}
          immediate={this.state.support.immediate}
          setFocusOnError={this.state.support.setFocusOnError}
          defaultErrorMessage={{ required: label.required }}
        >
          <div className="formSuggestion">
            <div className="bolder">{label.dearCustomer}</div>
            <div className="thin">{label.dearCustomerInvitation}</div>
          </div>
          <CustomerType handler={this.handler} {...this.state} />
          <div className="row js">
            <div className="col-sm-4 infoBlock">
              <div>
                {/* <div className="form-group">
                                    <label htmlFor="txtCompanyName">{label.nameLabel}</label>
                                    {this.getCompanyName()}
                                </div> */}
                <div className="form-group">
                  <label htmlFor="txtGuaranteeFirstName">
                    {label.firstNameLabel}
                  </label>
                  {this.getFirstName()}
                </div>
                <div className="form-group">
                  <label htmlFor="txtGuaranteeLastName">
                    {label.lastNameLabel}
                  </label>
                  {this.getLastName()}
                </div>
                <hr />
                <div className="form-group">
                  <label htmlFor="txtGuarantorFirstName">
                    {label.firstNameLabel}
                  </label>
                  <TextInput
                    className="form-control input-lg"
                    id="txtGuarantorFirstName"
                    name="firstName"
                    type="text"
                    maxLength="30"
                    placeholder={label.garantorFirstNamePlaceholder}
                    value={this.state.guarantor.personalInfo.firstName}
                    onChange={this.handlerChange(
                      "guarantor.personalInfo.firstName"
                    )}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="txtGuarantorLastName">
                    {label.lastNameLabel}
                  </label>
                  <TextInput
                    className="form-control input-lg"
                    id="txtGuarantorLastName"
                    name="lastName"
                    type="text"
                    maxLength="30"
                    placeholder={label.garantorLastNamePlaceholder}
                    value={this.state.guarantor.personalInfo.lastName}
                    onChange={this.handlerChange(
                      "guarantor.personalInfo.lastName"
                    )}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="txtGuarantorPhoneNumber">
                    {label.yourPhoneLabel}
                  </label>
                  <MaskWithValidation
                    id="txtGuarantorPhoneNumber"
                    name="phone"
                    className="form-control"
                    placeholder={label.textmask}
                    minLength="16"
                    value={this.state.guarantor.personalInfo.phoneNumber}
                    onChange={this.handlerChange(
                      "guarantor.personalInfo.phoneNumber"
                    )}
                    // validator={(value) => value === "+374 ** ** ** **"}
                    // onChange={this.onChange}
                    // successMessage="Looks good!"
                    // errorMessage={{ validator: "Please enter +374 -- -- -- --" }}
                    errorMessage={{
                      minLength: "Մուտքագրեք վավեր հեռախոսահամարը"
                      // minLength: "Minimum {minLength} characters is required"
                    }}
                    mask={[
                      "+",
                      "3",
                      "7",
                      "4",
                      " ",
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/
                    ]}
                    guide={false}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-4 infoBlock">
              <div>
                <div className="form-group">
                  <label>{label.attachImagesLabel}</label>
                  <div className="filesBlock">
                    {/*<div className="">*/}
                    {/*  <div className="box">*/}
                    {/*    <input*/}
                    {/*      type="file"*/}
                    {/*      name="incomeInfo[]"*/}
                    {/*      id="incomeInfo"*/}
                    {/*      className="inputfile inputfile-7"*/}
                    {/*      data-multiple-caption={label.selectedPicCount}*/}
                    {/*      multiple*/}
                    {/*      onChange={this.handlerUpload("incomeInfo")}*/}
                    {/*      accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
                    {/*    />*/}
                    {/*    <label htmlFor="incomeInfo">*/}
                    {/*      <span>{label.incomeInfoLabel}</span>*/}
                    {/*      {upIcon}*/}
                    {/*    </label>*/}
                    {/*    <div className="uploaded-info"></div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="">
                      <div className="box">
                        <input
                          type="file"
                          name="socialCard"
                          id="socialCard"
                          className="inputfile inputfile-7"
                          data-multiple-caption={label.selectedPicCount}
                          onChange={this.handlerUpload("socialCard")}
                          accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                        <label htmlFor="socialCard">
                          <span>{label.socialCartLabel}</span>
                          {upIcon}
                        </label>
                        <div className="uploaded-info"></div>
                      </div>
                    </div>

                    <div className="">
                      <div className="box">
                        <input
                          type="file"
                          name="passportOrId[]"
                          id="passportOrId"
                          className="inputfile inputfile-7"
                          data-multiple-caption={label.selectedPicCount}
                          multiple
                          onChange={this.handlerUpload("passportOrId")}
                          accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                        <label htmlFor="passportOrId">
                          <span>{label.passportLabel}</span>
                          {upIcon}
                        </label>
                        <div className="uploaded-info"></div>
                      </div>
                    </div>

                    {/*<div className="">*/}
                    {/*  <div className="box">*/}
                    {/*    <input*/}
                    {/*      type="file"*/}
                    {/*      name="realEstateCertificate[]"*/}
                    {/*      id="realEstateCertificate"*/}
                    {/*      className="inputfile inputfile-7"*/}
                    {/*      data-multiple-caption={label.selectedPicCount}*/}
                    {/*      multiple*/}
                    {/*      onChange={this.handlerUpload("realEstateCertificate")}*/}
                    {/*      accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
                    {/*    />*/}
                    {/*    <label htmlFor="realEstateCertificate">*/}
                    {/*      <span>{label.realeStateLabel}</span>*/}
                    {/*      {upIcon}*/}
                    {/*    </label>*/}
                    {/*    <div className="uploaded-info"></div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 infoBlock">
              {/* <div><a href="https://acra.am" target="blank" className="link">{label.acraLabel}</a></div> */}
              <div>
                <Checkbox
                  name="agreement"
                  id="agreement"
                  label={label.agreementLabel}
                  onChange={this.handlerAgreement()}
                  errorMessage={label.agreementValidationText}
                  value={this.state.uiSupport.agreementStatusG}
                  required
                />
              </div>
            </div>
            <div className="col-sm-4 infoBlock" style={{ textAlign: "right" }}>
              <button className="preNext preNextFub nextButton" tabIndex="0">
                <span className="label">{label.sendFormLabel}</span>
                <span className="touchRipple"></span>
              </button>
            </div>
            {/* <div className="col-sm-4 infoBlock" style={{ "textAlign": "right" }}>
                            <button className="btn btn-secondary" type="button" onClick={this.resetForm}>Reset</button>
                        </div> */}
          </div>
        </ValidationForm>
        <AgreementModal handler={this.handlerLocalState} {...this.state} />
      </div>
    );
  }
}

export default Guarantor;

function mountInputFileComponent() {
  (function(document, window, index) {
    var inputs = document.querySelectorAll(".inputfile");
    Array.prototype.forEach.call(inputs, function(input) {
      var label = input.nextElementSibling,
        labelVal = label.innerHTML;

      input.addEventListener("change", function(e) {
        var fileName = "";
        if (this.files && this.files.length > 0)
          fileName = (this.getAttribute("data-multiple-caption") || "").replace(
            "{count}",
            this.files.length
          );
        else fileName = e.target.value.split("\\").pop();

        if (fileName) label.querySelector("span").innerHTML = fileName;
        else label.innerHTML = labelVal;
      });

      // Firefox bug fix
      input.addEventListener("focus", function() {
        input.classList.add("has-focus");
      });
      input.addEventListener("blur", function() {
        input.classList.remove("has-focus");
      });
    });
  })(document, window, 0);
}
