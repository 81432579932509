const borrowerMainInfoStyles = theme => ({
    root: {
      width: "auto",
      height: "40px",
      minWidth: "40px",
      borderRadius: "5px",
      color: '#333333',
      backgroundColor: '#FFFFFF;',
      '&:hover': {
        backgroundColor: '#FFFFFF;',
      },
    },
    // input: {
    //   display: 'none',
    // },
    // extendedIcon: {
    //   marginRight: theme.spacing.unit,
    // },
    // upload: {
    //   padding: "0 0px 0 110px;",
    //   margin: "6px -10px auto 22px;",
    // },
    // grid: {
    //   width: "300px;",
    //   display: "inline-block;",
    // },
    // textField: {
    //   width: 250,
    // },
    // label: {
    //   fontSize: "13px;",
    // },
    // bootstrapRoot: {
    //   'label + &': {
    //     marginTop: theme.spacing.unit * 3,
    //   },
    // },
    // bootstrapInput: {
    //   borderRadius: 4,
    //   position: 'relative',
    //   backgroundColor: theme.palette.common.white,
    //   border: '1px solid #ced4da',
    //   fontSize: 16,
    //   width: 'auto',
    //   padding: '10px 12px',
    //   transition: theme.transitions.create(['border-color', 'box-shadow']),
    //   // Use the system font instead of the default Roboto font.
    //   fontFamily: [
    //     'MontserratARM',

    //   ].join(','),
    //   '&:focus': {
    //     borderRadius: 4,
    //     borderColor: '#36c265;',
    //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    //   },
    // },
    // bootstrapFormLabel: {
    //   fontSize: 15,
    //   /*whiteSpace: "nowrap",*/
    //   width: '400px'
    // },
  });

  export default borrowerMainInfoStyles;