const dataSchema = {
    "customerType": "", // borrower or guarantor    
    "borrower": {
        "borrowerType": "personal", // personal, selfEmployee, corp
        "personalInformation": {
            "companyName": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": "+374 ",
            "birthday": {
                "day": "",
                "month": "",
                "year": ""
            }
        },
        "businessType": "",
        "pastDueDates": "",
        "businessPeriod": "",
        "businessMainInformation": {
            "amount": "",
            "currency": "",
            "loanPurposes": "",
            "period": "",
        },
        "companyOptional": {
            "registrationNumber": "",
            "hvhh": "",
            "registrationDate": "",
            "companyRegistrationAddress": "",
            "registrationDistrict": "",
            "registrationCity": "",
            "registrationAddress": ""
        },
        "personOptional": {
            "gender": "",
            "middleName": "",
            "passportNumber": "",
            "passportIssueDate": "",
            "passportIssuedBy": "",
            "socialCardNumber": "",
            "registrationAddress": "",
            "mailingAddress": "",
            "responseWay": "",
            "residence": ""
        },
        "attachments": {
            "certificate": "",
            "socialCard": "",
            "passportOrId": "",
            "businessRules": "",
            "businessLogo": ""
        },
        "businessFinances": {
            "monthlyGross": "",
            "monthlyNet": "",
            "monthlyPayment": ""
        },
    },
    "guarantor": {
        "guaranteeInfo": {
            "companyName": "",
            "firstName": "",
            "lastName": "",
        },
        "personalInfo": {
            "firstName": "",
            "lastName": "",
            "phoneNumber": "+374 ",
        },
        "attachments": {
            "passportOrId": "",
            "socialCard": "",
            "incomeInfo": "",
            "realEstateCertificate": "",
        }
    },
    "bg": "background1",    // "background0" "background1" "background2"
    "page": "data",         // "send"
    "uiSupport": {
        agreementStatusG: false,
        agreementScrolledG: false,
        agreementStatus: false,
        agreementScrolled: false,
        termsAgreementStatus: false,
        termsAgreementScrolled: false
    }
}

export default dataSchema;