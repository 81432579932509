import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import label from "../../data/labels";
import styles from "./../../styles/borrowerMainInfoStyles";
import { SelectGroup, TextInput } from "react-bootstrap4-form-validation";
import MaskWithValidation from "../formElements/MaskWithValidation";

class BorrowerAsCorp extends React.Component {
  constructor(props) {
    super(props);
    const { ...others } = this.props;
    this.state = { ...others };
    this.handler = this.handler.bind(this);
  }

  handler(newState) {
    this.setState({ ...newState }, () => this.props.handler(this.state));
  }

  handlerChange = name => event => {
    const { value } = event.target;
    let clone = { ...this.state };
    let obj = name.split(".");

    this.setValue(obj, clone, value);
    this.setState({ ...clone });
  };
  handlerChangeProductTypes = name => event => {
    const { value } = event.target;
    if (value == 45 && (this.state.borrower.businessPeriod == 3 || this.state.borrower.businessPeriod == 4 )) {
      this.state.borrower.businessPeriod = 1;
    }
    let clone = { ...this.state };
    let obj = name.split(".");
    this.setValue(obj, clone, value);
    this.setState({ ...clone });    
  }
  handlerChangePeriod = name => event => {
    if (this.state.borrower.productTypes == 45 && (this.state.borrower.businessPeriod !== 1 || this.state.borrower.businessPeriod == 2 )) {
      this.state.borrower.businessPeriod = 1;
    }
    let clone = { ...this.state };
    let obj = name.split(".");
    this.setState({ ...clone });    
  }

  setValue(obj, clone, value) {
    if (obj.length === 1) {
      clone[obj] = value;
    } else {
      this.setValue(obj.slice(1), clone[obj[0]], value);
    }
  }

  componentDidMount() {
    /* Change background */
    let clone = { ...this.state };
    clone.bg = "background1";
    this.setState({ ...clone }, () => this.handler(this.state));
  }

  render() {
    let dayOfBirth = label.lists.dayOfBirth.map((day, i) => (
      <option key={i} value={day}>
        {day}
      </option>
    ));
    let monthOfBirth = label.lists.monthOfBirth.map((day, i) => (
      <option key={i} value={day.key}>
        {day.value}
      </option>
    ));
    let yearOfBirth = label.lists.yearOfBirth.map((day, i) => (
      <option key={i} value={day}>
        {day}
      </option>
    ));
    let subEconomicActivityTypes = label.lists.subEconomicActivityTypes.map(
      (lp, i) => (
        <option key={i} value={lp.type + "." + lp.economicActivityType}>
          {lp.description}
        </option>
      )
    );
    let economicActivityExperienceTypes = label.lists.economicActivityExperienceTypes.map(
      (eaet, i) => (
        <option key={i} value={eaet.key}>
          {eaet.value}
        </option>
      )
    );
    let economicActivityExperienceTypesPart = label.lists.economicActivityExperienceTypesPart.map(
      (eaet, i) => (
        <option key={i} value={eaet.key}>
          {eaet.value}
        </option>
      )
    );
    let pastDueTypes = label.lists.pastDue.map((pd, i) => (
      <option key={i} value={pd.key}>
        {pd.value}
      </option>
    ));
    let productTypes = label.lists.productTypes.map((pd, i) => (
      <option key={i} value={pd.id}>
        {pd.name}
      </option>
    ));
    let businessAdvisers = label.lists.businessAdvisers.map((b,i) =>
    <option key={b.key} value = {b.key}> {b.value} </option>
    );
    return (
      <div className="row js">
        <div className="col-sm-4 infoBlock">
          <div>
            <div className="form-group">
              <label htmlFor="txtCompanyName">{label.companyNameLabel}</label>
              <TextInput
                className="form-control input-lg"
                id="txtCompanyName"
                name="companyName"
                type="text"
                maxLength="50"
                placeholder={label.namePlaceholder}
                value={this.state.borrower.personalInformation.companyName}
                onChange={this.handlerChange(
                  "borrower.personalInformation.companyName"
                )}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="txtFirstName">
                {label.directorFirstNameLabel}
              </label>
              <TextInput
                className="form-control input-lg"
                id="txtFirstName"
                name="firstName"
                type="text"
                maxLength="30"
                placeholder={label.yourFirstNamePlaceholder}
                value={this.state.borrower.personalInformation.firstName}
                onChange={this.handlerChange(
                  "borrower.personalInformation.firstName"
                )}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="txtLastName">{label.directorLastNameLabel}</label>
              <TextInput
                className="form-control input-lg"
                id="txtLastName"
                name="lastName"
                type="text"
                maxLength="30"
                placeholder={label.yourLastNamePlaceholder}
                value={this.state.borrower.personalInformation.lastName}
                onChange={this.handlerChange(
                  "borrower.personalInformation.lastName"
                )}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="ddd">{label.birthdayLabel}</label>
              <div className="row">
                <div className="col-sm-4">
                  <SelectGroup
                    name="day"
                    id="ddlDay"
                    value={this.state.borrower.personalInformation.birthday.day}
                    onChange={this.handlerChange(
                      "borrower.personalInformation.birthday.day"
                    )}
                    required
                  >
                    <option value="">օր</option>
                    {dayOfBirth}
                  </SelectGroup>
                </div>
                <div className="col-sm-4">
                  <SelectGroup
                    name="month"
                    id="ddlMonth"
                    value={
                      this.state.borrower.personalInformation.birthday.month
                    }
                    onChange={this.handlerChange(
                      "borrower.personalInformation.birthday.month"
                    )}
                    required
                  >
                    <option value="">ամիս</option>
                    {monthOfBirth}
                  </SelectGroup>
                </div>
                <div className="col-sm-4">
                  <SelectGroup
                    name="year"
                    id="ddlYear"
                    value={
                      this.state.borrower.personalInformation.birthday.year
                    }
                    onChange={this.handlerChange(
                      "borrower.personalInformation.birthday.year"
                    )}
                    required
                  >
                    <option value="">տարի</option>
                    {yearOfBirth}
                  </SelectGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 infoBlock">
          <div>
            <div className="form-group">
              <label htmlFor="txtPhoneNumber">{label.phoneLabel}</label>
              <MaskWithValidation
                id="txtPhoneNumber"
                name="phone"
                className="form-control"
                placeholder={label.textmask}
                minLength="16"
                value={this.state.borrower.personalInformation.phoneNumber}
                onChange={this.handlerChange(
                  "borrower.personalInformation.phoneNumber"
                )}
                // validator={(value) => value === "+374 ** ** ** **"}
                // onChange={this.onChange}
                // successMessage="Looks good!"
                // errorMessage={{ validator: "Please enter +374 -- -- -- --" }}
                errorMessage={{
                  minLength: "Մուտքագրեք վավեր հեռախոսահամարը"
                  // minLength: "Minimum {minLength} characters is required"
                }}
                mask={[
                  "+",
                  "3",
                  "7",
                  "4",
                  " ",
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/
                ]}
                guide={false}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="ddlYourOccupationLabel">
                {label.yourOccupationLabel}
              </label>
              <SelectGroup
                name="loanPurposes"
                id="ddlYourOccupationLabel"
                value={this.state.borrower.businessType}
                onChange={this.handlerChange("borrower.businessType")}
                required
              >
                <option value="">{label.selectPlaceholder}</option>
                {subEconomicActivityTypes}
              </SelectGroup>
            </div>
            {this.state.borrower.productTypes == 45 ?
            <div className="form-group">
            <label htmlFor="ddlBusinessPeriodLabel">
              {label.businessPeriodLabel}
            </label>
            <SelectGroup
              name="businessPeriod"
              id="ddlBusinessPeriodLabel"
              value={this.state.borrower.businessPeriod}
              onChange={this.handlerChange("borrower.businessPeriod")}
              required
            >
              <option value="">{label.selectPlaceholder}</option>
              {economicActivityExperienceTypesPart}
            </SelectGroup>
          </div>:
             <div className="form-group">
             <label htmlFor="ddlBusinessPeriodLabel">
               {label.businessPeriodLabel}
             </label>
             <SelectGroup
               name="businessPeriod"
               id="ddlBusinessPeriodLabel"
               value={this.state.borrower.businessPeriod}
               onChange={this.handlerChange("borrower.businessPeriod")}
               required
             >
               <option value="">{label.selectPlaceholder}</option>
               {economicActivityExperienceTypes}
             </SelectGroup>
           </div>
            }
            
            <div className="form-group">
              <label htmlFor="ddlPastDueTypes">{label.pastDueLabel}</label>
              <SelectGroup
                name="pastDueDates"
                id="ddlPastDueTypes"
                value={this.state.borrower.pastDueDates}
                onChange={this.handlerChange("borrower.pastDueDates")}
                required
              >
                <option value="">{label.selectPlaceholder}</option>
                {pastDueTypes}
              </SelectGroup>
            </div>
            <div className="form-group">
              <label htmlFor="productTypes">{label.productTypes}</label>
              <SelectGroup
                name="productTypes"
                id="productTypes"
                value={this.state.borrower.productTypes}
                onChange={this.handlerChangeProductTypes("borrower.productTypes")}
                required
              >
                <option value="">{label.selectPlaceholder}</option>
                {productTypes}
              </SelectGroup>
            </div>
            <div className="form-group">
                            <label htmlFor="ddlBusinessAdvisor">{label.businessAdviserLabel}</label>
                            <SelectGroup name="businessAdvisor" id="ddlBusinessAdvisor"                                
                                value={this.state.borrower.businessAdvisor}
                                onChange={this.handlerChange('borrower.businessAdvisor')}
                                >
                                <option value="">{label.selectPlaceholder}</option>
                                {businessAdvisers}
                            </SelectGroup>
                        </div> 
          </div>
        </div>
      </div>
    );
  }
}
BorrowerAsCorp.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(BorrowerAsCorp);
