import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import '../App.css';
import logo from "../images/logo-mk-main.png";
import { Fab } from '@material-ui/core';
import label from '../data/labels';
// import data from '../data/schema';
import $ from 'jquery';
// import Popper from 'popper.js';
import styles from './../styles/businessDevelopmentStyles';

class BusinessDevelopment extends React.Component {

  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
  }

  handler() {
    this.props.handler();
  }

  scrollAnimation(e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: document.body.scrollHeight - 1300 }, 500, 'linear');
    this.handler();
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className="pageSection business background">
          <div className="compLogo">
            <a href="https://mikrokapital.am/loan-product/loan-micro.html">
              <img src={logo} alt="MikroKapital" className="compLogo" />
            </a>
          </div>
          <div className="info">
            <h1 dangerouslySetInnerHTML={{ __html: label.yourCredit }} />
            <div dangerouslySetInnerHTML={{ __html: label.yourCreditAttention }} />
            <div dangerouslySetInnerHTML={{ __html: label.yourCreditAttention1 }} />
            <div dangerouslySetInnerHTML={{ __html: label.yourCreditProposal }} />
            <div>
              <Fab size="medium" className={classes.root} onClick={(e) => { this.scrollAnimation(e); }} >
                {label.fillFormLabel}
              </Fab>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BusinessDevelopment.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(BusinessDevelopment);

// function scrollAnimation(e) {
//   e.preventDefault();
//   $('html, body').animate({ scrollTop: document.body.scrollHeight - 1300 }, 500, 'linear');
// }
