import { red, yellow, green, blueGrey } from '@material-ui/core/colors';

const borrowerStyles = theme => ({
    /* Styles applied to the root element. */
    root: {
        //display: 'flex',
        width: '100%',
        alignItems: 'center',
        '&$alternativeLabel': {
            flexDirection: 'column',
        },
        '&$disabled': {
            cursor: 'default',
        },
        backgroundColor: 'transparent'
    },
    rootButton: {
        padding: "0 35px",
        width: "auto",
        height: "40px",
        minWidth: "40px",
        borderRadius: "3px",
        color: 'white',
        backgroundColor: '#36c265;',
        '&:hover': {
            backgroundColor: '#47d376;',
        },
    },
    radioRoot: {
        color: green[600],
        '&$checked': {
            color: green[500],
        },
    },
    checked: {},
    /* Styles applied to the root element if `orientation="horizontal". */
    horizontal: {
        backgroundColor: 'transparent',
    },
    /* Styles applied to the root element if `orientation="vertical". */
    vertical: {},
    /* Styles applied to the `Typography` component which wraps `children`. */
    label: {
        '&$active': {
            backgroundColor: blueGrey,
            color: yellow,
            fontWeight: 500,
        },
        '&$completed': {
            backgroundColor: red,
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
        '&$alternativeLabel': {
            backgroundColor: green,
            textAlign: 'center',
            marginTop: 16,
        },
        '&$error': {
            color: theme.palette.error.main,
        },
    },
    /* Styles applied to the `Typography` component if `active={true}`. */
    active: {},
    /* Styles applied to the `Typography` component if `completed={true}`. */
    completed: {},
    /* Styles applied to the root element and `Typography` component if `error={true}`. */
    error: {},
    /* Styles applied to the root element and `Typography` component if `disabled={true}`. */
    disabled: {},
    /* Styles applied to the `icon` container element. */
    iconContainer: {
        flexShrink: 0, // Fix IE 11 issue
        display: 'flex',
        paddingRight: 8,
        '&$alternativeLabel': {
            paddingRight: 0,
        },
    },
    /* Styles applied to the root & icon container and `Typography` if `alternativeLabel={true}`. */
    alternativeLabel: {},
    /* Styles applied to the container element which wraps `Typography` and `optional`. */
    labelContainer: {
        width: '100%',
        color: yellow,
    },

    button: {
        marginRight: theme.spacing.unit,
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});

export default borrowerStyles;