
const businessDevelopmentStyles = theme => ({
    root: {
      padding: "0 73px",
      width: "auto",
      height: "48px",
      minWidth: "50px",
      borderRadius: "15px",
      fontSize: 17,
      color: 'white',
      backgroundColor: '#36c265;',
      '&:hover': {
        backgroundColor: '#47d376;',
      },
    },
  });

  export default businessDevelopmentStyles;