import React from "react";
import "./Terms.css";

class TermsAcraAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...props};
  }

  render() {
    return (
      <div className='arca-agreement'>
        <p><strong>Ի՞նչ է վարկային զեկույցը։</strong></p>
        <p>
          Վարկային զեկույցը թղթային կամ էլեկտրոնային գրավոր փաստաթուղթ է,
          որը պարունակում է վարկային տեղեկատվության սուբյեկտի (ֆիզիկական կամ իրավաբանական) և
          նրա կողմից ստանձնած տնտեսվարող կազմակերպությունների նկատմամբ ֆինանսական պարտավորությունների
          կատարման վերաբերյալ տեղեկատվություն:
        </p>

        <p>
          Զեկույցը կազմված է ԱՔՌԱ անդամ կազմակերպություններից ստացված տվյալների
          հիման վրա և արտացոլում է հարցման պահից վերջին 5 տարվա վարկային տվյալները:
        </p>

        <p>
          Վարկային զեկույցը պարունակում է անձի ֆինանսական պարտավորությունների
          և դրանց կատարման վերաբերյալ տեղեկություններ, մասնավորապես՝

          <ul className='list'>
            <li>անձի նույնականացման տվյալներ,</li>
            <li>վարկային պատմություն,</li>
            <li>հանրային տվյալների բազաներից տեղեկություններ,</li>
            <li>հարցումների վերաբերյալ տեղեկություններ:</li>
          </ul>
        </p>

        <p>Վարկային զեկույցի վերաբերյալ այլ մանրամասներին կարող եք
          ծանոթանալ՝ անցնելով <a className='terms__link' rel="noopener noreferrer" target="_blank" href='https://acra.am/%d5%bd%d5%ba%d5%a1%d5%bc%d5%b8%d5%b2%d5%a1%d5%af%d5%a1%d5%b6-%d5%be%d5%a1%d6%80%d5%af%d5%a1%d5%b5%d5%ab%d5%b6-%d5%a6%d5%a5%d5%af%d5%b8%d6%82%d5%b5%d6%81/'>հետևյալ հղումով։</a>
        </p>

        <p className='paragraph__header'><strong>Վարկային պատմություն և ՍՔՈՐ գնահատական</strong></p>

        <p>Ի՞նչ է վարկային պատմությունը և որքանո՞վ է այն կարևոր։</p>

        <p>Վարկային պատմությունը վարկային տեղեկատվության սուբյեկտի կողմից ստանձնած
          պարտավորությունների վերաբերյալ տեղեկատվությունն է, որը ցույց է տալիս վարկային
          տեղեկատվության սուբյեկտի պարտքերը, վճարումները, վճարումների սովորությունները կամ
          պարտավորությունների կամ դրանց կատարման վերաբերյալ տեղեկատվություններ:
          Վարկային պատմությունը պարբերաբար նորացվում է ինչպես վարկ տրամադրած առանձին
          վարկատուների, այնպես էլ Վարկային բյուրոյի (ԱՔՌԱ) կողմից:
        </p>

        <p>Վարկային պատմությունն օգտագործվում է ֆինանսական կազմակերպությունների կողմից վարկերի
          տրամադրման, ինչպես նաև արտադրող և վաճառող կամ ծառայություններ մատուցող կազմակերպությունների
          կողմից՝ տարաժամկետ վճարման սկզբունքով («ապառիկ») ապրանքների/ծառայությունների իրացման
          վերաբերյալ որոշում կայացնելու ընթացքում:
        </p>

        <p>Մանրամասների համար՝</p>

        <p><a className='terms__link' rel="noopener noreferrer" target="_blank" href='https://www.abcfinance.am/lifesituations/credit-history.html'>www.abcfinance.am</a></p>

        <p><a className='terms__link' rel="noopener noreferrer" target="_blank" href='https://acra.am/%d5%af%d5%a1%d5%ba-%d6%87-%d5%a1%d5%bb%d5%a1%d5%af%d6%81%d5%b8%d6%82%d5%a9%d5%b5%d5%b8%d6%82%d5%b6/%d5%b0-%d5%bf-%d5%b0/'>www.acra.am</a></p>

        <p className='paragraph__header'><strong>Որո՞նք են վարկային պատմության ձևվորման աղբյուրները:</strong></p>

        <p>
            Վարկային պատմության ձևավորման աղբյուրներն են ԱՔՌԱ գործընկերները (բանկերը, վարկային
            կազմակերպությունները, ապահովագրական ընկերությունները, հետաձգված վճարումներով գործարքներ
            իրականացնող կազմակերպությունները, ինչպես նաև՝ հանրային տվյալների բազաներից ՀՀ կառավարության
            սահմանած կարգով վարկային բյուրոյին անձանց վերաբերյալ տեղեկություններ տրամադրող պետական մարմինները),
            որոնք տրամադրում են վարկային տեղեկատվության սուբյեկտի վերաբերյալ տեղեկություններ(տվյալներ):
        </p>

        <p><strong>Ինչպես վարվել սխալ կամ թերի վարկային պատմությունը փոխելու համար՝</strong></p>

        <p>
            Եթե վարկառուի վարկային պատմության մեջ առկա են սխալ կամ թերի տվյալներ, ապա պարզաբանման և
            ուղղման նպատակով վարկառուն՝ համաձայն «Վարկային տեղեկատվության շրջանառության և վարկային
            բյուրոների գործունեության մասին» ՀՀ օրենքի 20-րդ հոդվածի դրույթների, կարող է այդ մասին տեղեկացնել
            վարկային բյուրոյին կամ ուղղակիորեն դիմել տեղեկատվություն տրամադրող ֆինանսական կազմակերպություններին:
            Տեղեկացումը կարող է իրականացվել համապատասխան գրավոր դիմում վարկային բյուրոյին ներկայացնելու միջոցով
            կամ էլեկտրոնային եղանակով:
        </p>

        <p><strong>Ինչպե՞ս կարող եմ բարելավել իմ վարկային պատմությունը:</strong></p>

        <p>
            Նախ, անհրաժեշտ է լիովին մարել նախկինում գոյացած ժամկետանց պարտավորությունների վճարումները:
            Բացի այդ, ներկա պահին գործող վարկերի մասով անհրաժեշտ է բացառել ժամկետանց՝ նույնիսկ մեկ օրվա
            կտրվածքով պարտավորությունների գոյացումը:
        </p>

          <p className='paragraph__header'>
              «Միկրո Կապիտալ Հայաստան» ՈւՎԿ ՓԲԸ-ն որոշումների կայացման համար կիրառում է նաև սեփական
              վարկարժանության գնահատման համակարգը, որի մաս են կազմում և կարող են ունենալ բացասական
              ազդեցություն որոշման կայացման վրա Սպառողի վարկային պատմությունը, այդ թվում՝

              <ul className='terms__list_mg'>
                  <li>նախորդ 12 (տասներկու) ամիսների ընթացքում ժամկետանց օրերի քանակը,</li>
                  <li>գործող վարկերի քանակը,</li>
                  <li>վարկային ծանրաբեռնվածության աստիճանը:</li>
              </ul>
          </p>

          <p>
              «Միկրո Կապիտալ Հայաստան» ՈւՎԿ ՓԲԸ կատարում է վարկային պատմության
              հարցումներ հետևյալ նպատակներով.

              <ul className='terms__list_mg'>
                  <li>նոր վարկ տրամադրելու համար (հարցման ձևանմուշներ՝ «նոր վարկային դիմում», «փոխկապակցված անձ»),</li>
                  <li>երաշխավորության պայմանագիր կնքելու համար (հարցման ձևանմուշ՝ «երաշխավոր անձ»),</li>
                  <li>գործող պարտավորության դեպքում մշտադիտարկում իրականացնելու համար («գործող վարկի մոնիտորինգ»),</li>
                  <li>գործող պարտավորության դեպքում նոր առաջարկներ ներկայացնելու համար («գործող վարկի մոնիտորինգ»),</li>
                  <li>այլ նպատակների համար, այդ թվում՝ «նոր աշխատակից» դիմում, «Ֆայկո Սքոր միջակայք» և այլն:</li>
              </ul>
          </p>

          <p>
              Նոր վարկ տրամադրելու, երաշխավոր հանդես գալու և նոր աշխատակցի համար կատարվող հարցումները
              իրականացվում են համապատասխան հայտ ներկայացնելու դեպքում, իսկ մշտադիտարկումների և նոր
              առաջարկների դեպքում պարբերականությունը սահմանվում է «Միկրո Կապիտալ Հայաստան» ՈւՎԿ ՓԲԸ
              ներքին ընթացակարգերով:
          </p>

          <p>
              Սպառողի կողմից ներկայացվող վարկային դիմումների քանակը (թվային մեծությունը) մեծ հավանկանությամբ
              բացասական ազդեցություն է ունենում սպառողի վարկային ամփոփ գնահատականի վրա:

              <ul className='terms__list_mg'>
                  <li>
                      ԱՔՌԱ Սքոր (ՖԱՅԿՈ Սքոր) գնահատականը մեծ հավանականությամբ իջեցնում են
                      հետևյալ նպատակով կատարված հարցումները ՝Նոր վարկային դիմում,
                  </li>
                  <li>Երաշխավոր անձ (նոր վարկային դիմում ենթանպատակ):</li>
              </ul>
          </p>

          <p>
              ՖԱՅԿՈ Սքոր գնահատականի վրա ազդեցություն չեն ունենում կամ ցածր հավանականությամբ
              ազդում են հետևյալ նպատակով կատարված հարցումները՝

              <ul className='terms__list_mg'>
                  <li>Գործող վարկի մոնիտորինգ,</li>
                  <li>Երաշխավոր անձ (գործող վարկ, գործող երաշխավորի մոնիտորինգ ենթանպատակներ),</li>
                  <li>Փոխկապակցված անձ,</li>
                  <li>Այլ բանկային ծառայություններ,</li>
                  <li>Ֆայկո Սքոր միջակայք:</li>
              </ul>
          </p>

          <p>
              Վարկային տեղեկատվության հարցումներ կատարելու համաձայնությունը պարտադիր պայման է
              հայտ ներկայացնելու համար։
          </p>

          <p className='paragraph__header header__center'><strong>ՀԱՄԱՁԱՅՆՈՒԹՅՈՒՆ</strong></p>

          <p>
              Սույնով տալիս եմ իմ համաձայնությունը, որպեսզի «Միկրո Կապիտալ Հայաստան ՈւՎԿ» ՓԲԸ-ն
              առանց ինձ նախապես տեղեկացնելու հարցումներ կատարի և ստանա տեղեկատվություն
              (ներառյալ՝ իմ/այն կազմակերպության, որի տնօրենն եմ հանդիսանում և «Միկրո Կապիտալ
              Հայաստան ՈւՎԿ» ՓԲԸ-ի միջև կնքվելիք/կնքված պայմանագրերից բխող գործողությունների իրականացման
              նպատակով մշակել/ներառյալ՝ հավաքել, ամրագրել և օգտագործել/)՝

              <ul className='list__confirm'>
                  <li>
                      ԱՔՌԱ Քրեդիտ Ռեփորթինգ» ՓԲԸ-ից, այլ վարկային բյուրոներից՝ իմ և/կամ կազմակերպության
                      ներկա և անցյալ (բայց ոչ ավել, քան հարցման պահին 5/հինգ/ տարվա վաղեմության)
                      ֆինանսական պարտավորությունների, այդ թվում՝ երրորդ անձանց պարտավորությունների
                      կատարման ապահովման համար երաշխավոր լինելու մասին, ստանա ցանացած այլ տվյալ
                      և/կամ տեղեկատվությունիմ և/կամ կազմակերպության վերաբերյալ, որը կարող է հաշվի առնվել
                      ինձ հետ վարկային (փոխառության, երաշխավորության, գրավի և այլն) պայմանագիր
                      (այսուհետ՝ Պայմանագիր) կնքելու վերաբերյալ որոշում կայացնելիս, ինչպես նաև ինձ
                      և/կամ կազմակերպության հետ վարկային (փոխառության և այլն) և այլ պայմանագրեր
                      կնքելու դեպքում  այդ պայմանագրերի գործողության ողջ ընթացքում ցանկացած պահի,
                      առանց ինձ նախապես տեղյակ պահելու  և իմ համաձայնությունը ստանալու հարցում
                      անի և ստանա իմ և/կամ կազմակերպության ֆինանսական պարտավորությունների
                      վերաբերյալ տեղեկություններ և այլ տվյալներ։
                  </li>
                  <li>
                      «Էլեկտրոնային կառավարման ենթակառուցվածքների ներդրումների գրասենյակից»`
                      իմ անձնական տվյալների մասին:

                      <p className='nested__margin'>
                          ՀՀ օրենսդրությամբ սահմանված հանրային տվյալների բազաներ մշակող անձանցից,
                          իրավապահ մարմիններից, ՀՀ պետական և/կամ տեղական ինքնակառավարման
                          մարմիններից (այդ թվում՝ ՀՀ Ոստիկանության բնակչության պետական ռեգիստր,
                          ՀՀ Ճանապարհային ոստիկանություն, ՀՀ ԱՆ Իրավաբանական անձանց պետական
                          ռեգիստրի գործակալություն, ՀՀ Անշարժ գույքի կադաստրի պետական կոմիտե,
                          ՀՀ ԱՆ Քաղաքացիական կացության ակտերի գրանցման գործակալություն,
                          ՀՀ հարկադիր կատարման ծառայություն, ՀՀ պետական եկամուտների կոմիտե,
                          «Նորք» սոցիալական ծառայությունների տեխնոլոգիական և իրազեկման կետրոն
                          հիմնադրամ, «Էլեկտրոնային կառավարման ենթակառուցվածքների ներդրման
                          գրասենյակ» ՓԲԸ և այլն)` իմ և/կամ այն կազմակերպության վերաբերյալ, որի
                          տնօրենն եմ հանդիսանում ցանկացած տեղեկատվություն (այդ թվում՝ անձնական տվյալներ)՝
                          բացառապես ՀՀ օրենսդրության շրջանակներում և համապատասխան պայմանագրերի կնքման
                          և/կամ երկկողմանի կատարման նպատակով և անհրաժեշտ ծավալով:
                      </p>
                  </li>

                  <li>
                      «Միկրո Կապիտալ Հայաստան ՈւՎԿ» ՓԲԸ կողմից իմ և/կամ Կազմակերպության տվյալները
                      (այդ թվում՝ անձնական տվյալները) փոխանցվեն «ԱՔՌԱ Քրեդիտ Ռեփորթինգ» ՓԲԸ,
                      ՀՀ Կենտրոնական բանկ, այլ վարկային բյուրոների և հաճախորդների վերաբերյալ տվյալներ
                      մշակող վերոնշյալ այլ անձանց և/կամ պետական և տեղական ինքնակառավարման մարմիններին՝
                      բացառապես ՀՀ օրենսդրության շրջանակներում և սահմանված նպատակների համար:Տեղեկացված եմ,
                      որ օրենսդրությամբ նախատեսված դեպքերում և կարգով իրավասու եմ պահանջել հարցման անձնական
                      տվյալների ուղղում կամ ոչնչացում, եթե այլ բան նախատեսված չէ ՀՀ օրենսդրությամբ։

                      <p className='nested__margin'>
                          Սույն համաձայնությունը վերոնշյալ բոլոր նպատակների համար կիրառելի է
                          «Միկրո Կապիտալ Հայաստան ՈւՎԿ» ՓԲԸ հետ հարաբերությունների գործողության
                          ամբողջ ժամանակահատվածում:
                      </p>
                  </li>

                  <li>
                      Հայտարարում եմ, որ ամբողջությամբ կարդացել եմ սույն համաձայնության տեքստը,
                      հասկացել եմ դրա նշանակությունը և հետևանքները և համաձայն եմ դրա հետ։
                  </li>
              </ul>
          </p>

      </div>
    );
  }
}

export default TermsAcraAgreement;
