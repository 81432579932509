import React from 'react';
import label from '../../data/labels';
import chromeLogo from "../../images/logo-mk-chrome.png";
import { unblockUI } from '../../data/utility';
import  TermsAntivirus1 from '../partitions/TermsAntivirus1';

class TermsAntivirus1Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...props, messageButtonDisabled: true };
        this.closeAgreement = this.closeAgreement.bind(this);
    }

    closeWarning() {
        unblockUI();
    }

    closeAgreement() {
        let clone = { ...this.state };
        clone.uiSupport.termsAgreementStatus = false;   
        this.setState({ ...clone }, () => this.props.handler(this.state));

        unblockUI();
    }

    handleScroll = e => {
        let element = e.target;
        if (element.scrollHeight - element.scrollTop < element.clientHeight + 10) {
            let clone = { ...this.state };
            clone.messageButtonDisabled = false;
            this.setState({ ...clone });
        }
    } 

    render() {
        return (
            <div id="modalTerms" style={{ "display": "none", "cursor": "default" }}>
                <div className="modalHeaderContainer">
                    <img src={chromeLogo} alt="MikroKapital" className="compLogo chromeLogo" />                    
                    <span className="closebtn" onClick={this.closeAgreement}>×</span>
                </div>
                <div className="modalMessagesContainer" onScroll={this.handleScroll}>
                    <div className="innerContainer">
                        <TermsAntivirus1 />
                    </div>
                </div>
                <div className="modalButtonsContainer">
                    <button className="preNext preNextFub messageButton" tabIndex="0" onClick={this.closeWarning}  disabled={this.state.messageButtonDisabled} >
                        <span className="label">{label.informed}</span>
                        <span className="touchRipple"></span>
                    </button>
                </div>
            </div>
        );
    }
}

export default TermsAntivirus1Modal;