import React from 'react';
import PropTypes from 'prop-types';
import label from '../../data/labels';
import logo from "../../images/email_send.png";

class Final extends React.Component {

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.handler = this.handler.bind(this);
  }

  handler(newState) {
      this.setState({ ...newState }, () => this.props.handler(this.state));
  }

  componentDidMount() {
    /* Change background */
    let clone = { ...this.state };
    clone.bg = "background0";
    this.setState({ ...clone }, () => this.handler(this.state));
  }

  render() {
    return (
      <div className="formFinal">
        <div className="col-lg-6">
          <div className="">
            <div className="compLogo">
              <img src={logo} alt="MikroKapital" className="compLogo" />
            </div>
          </div>
          <div className="row">
            <div className="info">
              <h1 dangerouslySetInnerHTML={{ __html: label.thanks }} />
              <div>
                <span dangerouslySetInnerHTML={{ __html: label.messageSent }} />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

Final.propTypes = {
  classes: PropTypes.object,
};

export default Final;