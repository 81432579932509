import React from 'react';
import './App.css';
import BusinessDevelopment from './components/BusinessDevelopment';
import WeProvide from './components/WeProvide';
import FormFilling from './components/FormFilling';
import data from './data/schema';
import label from './data/labels';
import cloneDeep from 'clone-deep';
import {
    loadSubEconomicActivityTypes,
    loadEconomicActivityExperienceTypes,
    loadLoanPurposes,
    loadInformationSourceTypes,
    loadDocumentTypes,
    loadDocumentStatuses,
    loadCurrencies,
    loadAddressTypes,
    loadCountries,
    loadRegions,
    loadProductTypes,
    // loadCities,
    // loadDistricts,
    loadYearOfBirth,
    loadBusinessAdvisers
} from './data/dao';
import Footer from './components/Footer';
import joker from "./images/warning.png";
import { unblockUI } from './data/utility';

class App extends React.Component {

    constructor(props) {
        super(props);
        let clone = cloneDeep(data);
        this.state = { ...clone };
        this.handler = this.handler.bind(this);
    }

    handler() {
        let clone = cloneDeep(data);
        this.setState({ ...clone });
    }

    closeWarning() {
        unblockUI();
    }

    componentWillMount() {
        loadSubEconomicActivityTypes(1);
        loadEconomicActivityExperienceTypes();
        loadLoanPurposes();
        loadInformationSourceTypes();
        loadDocumentTypes();
        loadDocumentStatuses();
        loadCurrencies();
        loadAddressTypes();
        loadCountries();
        loadRegions(7);
        loadProductTypes();
        loadBusinessAdvisers();
        // loadCities(1);
        // loadDistricts(1);

        loadYearOfBirth();
    }

    render() {
        return (
            <div className="app">
                <header></header>
                <section>
                    <BusinessDevelopment handler={this.handler} />
                    <FormFilling {...this.state} />

                    <div id="pastDueWarning" style={{ "display": "none", "cursor": "default" }}>
                        <div className="messagesContainer">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={joker} alt="MikroKapital" className="compLogo" />
                                        </td>
                                        <td id="messageErrorValid">

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="buttonsContainer">
                            <button className="preNext preNextFub errorButton" tabIndex="0" onClick={this.closeWarning}>
                                <span className="label">{label.ok}</span>
                                <span className="touchRipple"></span>
                            </button>

                        </div>
                    </div>

                </section>

                <footer>
                    <Footer />
                </footer>
            </div >
        );
    }
}

export default App;
