import React from "react";
import "./Terms.css";
import { green } from "@material-ui/core/colors";

class TermsQaxvacq extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <div>
        <div className="tHead">«Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ</div>
        <div className="tHead">
          Հաստատված է Տնօրենների Խորհրդի Թիվ 12/19 որոշմամբ
        </div>
        <div className="mainHeader">
          ԲԻԶՆԵՍ ՎԱՐԿ «ՔԱՂՎԱԾՔՈՎ» ՎԱՐԿԱՏԵՍԱԿԻ ՊԱՅՄԱՆՆԵՐԸ ԵՎ ՍԱԿԱԳՆԵՐԸ
        </div>
        <div className="termsContainer">
          <table className="termsTable smallAmount">
            <tbody>
              <tr>
                <td></td>
                <td colSpan="3">ՀՀ դրամ</td>
                <td colSpan="3">ԱՄՆ դոլար</td>
              </tr>
              <tr>
                <td>Վարկի գումար</td>
                <td colSpan="3">500,000 – 7,500,000</td>
                <td colSpan="3">1,000 – 15,000</td>
              </tr>
              <tr>
                <td>Անվանական տարեկան տոկոսադրույք</td>
                <td colSpan="3">14% – 22%</td>
                <td colSpan="3">14% – 22%</td>
              </tr>
              <tr>
                <td>Տարեկան փաստացի տոկոսադրույք</td>
                <td colSpan="3">24.7% – 55.8%</td>
                <td colSpan="3">21.1% – 51.4%</td>
              </tr>
              <tr>
                <td>Սպասարկման ամենամսյա վճար</td>
                <td colSpan="6">0.3% - 0.5%, նվազագույնը 4,000 ՀՀ դրամ</td>
              </tr>
              <tr>
                <td>Տրամադրման միանվագ միջնորդավճար</td>
                <td colSpan="6">3%, առավելագույնը 150,000 ՀՀ դրամ</td>
              </tr>
              <tr>
                <td>Վարկի ժամկետ (ամիս) 6 – 18</td>
                <td colSpan="6">6 – 18</td>
              </tr>
              <tr>
                <td>Ապահովվածություն (նվազագույն)</td>
                <td colSpan="6">
                  <ul>
                    <li>
                      500,000 ՀՀ դրամից (1,000 ԱՄՆ դոլար) - 2,500,000 ՀՀ դրամ
                      (համարժեք 5,000 ԱՄՆ դոլար) – 1 (մեկ) երաշխավոր
                    </li>
                    <li>
                      2.500,001 ՀՀ դրամից (համարժեք 5,001 ԱՄՆ դոլար) - 7,500,000
                      ՀՀ Դրամ (համարժեք 15,000 ԱՄՆ դոլար) 2 (երկու) երաշխավոր,
                      որից մեկը ֆինանսական
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Մարման եղանակ</td>
                <td colSpan="6">անուիտետային, ճկուն</td>
              </tr>
              <tr>
                <td>Արտոնյալ ժամկետ</td>
                <td colSpan="6">առավելագույնը 2 ամիս</td>
              </tr>
              <tr>
                <td>Վաղաժամկետ մարում</td>
                <td colSpan="6">0%</td>
              </tr>
              <tr>
                <td>Տույժեր</td>
                <td colSpan="6">
                  ժամկետանց տոկոսագումարի և մայր գումարի նկատմամբ օրական 0.13%
                </td>
              </tr>
              <tr>
                <td>Նշումներ</td>
                <td colSpan="6">
                  * Հաճախորդի ձեռնարկատիրական (բիզնես) գործունեությունում առկա
                  այլ անձանց մասնակցության (փայատիրության) առկայության դեպքում
                  անկախ վարկի գումարի չափից, տվյալ մասնակցի երաշխավորությունը
                  հանդիսանում է վարկի ապահովման պարտադիր պայման
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="termsContainer">
          <table className="termsTable mainDesc">
            <tbody>
              <tr>
                <td></td>
                <td>ՉԱՓԱՆԻՇՆԵՐ</td>
                <td>ՀԻՄՆԱԿԱՆ ԲՆՈՒԹԱԳՐԵՐ</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Վարկառուի նկարագիր</td>
                <td>
                  ՀՀ ռեզիդենտ իրավաբանական անձինք և անհատ ձեռնարկատերեր, որոնք
                  ունեն եկամուտներ ձեռնարկատիրական (բիզնես) գործունեությունից
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Վարկառուին/երաշխավորին ներկայացվող նվազագույն պահանջներ</td>
                <td>
                  <ul>
                    <li>
                      Վարկառուի տարիքը 21 – 65 տարեկան` վարկային պայմանագրի
                      ժամկետի ավարտի պահին, ընդ որում 21 – 27 տարեկան արական
                      սեռի վարկառուների համար պարտադիր պայման է հանդիսանում
                      զինվորական գրքույկի առկայությունը, որը կհավաստի զինվորական
                      ծառայության ավարտը (հակառակ դեպքում` ֆինանսական երաշխավորի
                      կամ ընտանիքի անդամի երաշխավորության առկայություն),
                    </li>
                    <li>
                      «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ վճարունակության
                      գնահատման ներքին չափանիշներին համապատասխանող վարկառուի
                      ձեռնարկատիրական (բիզնես) գործունեության առկայություն`
                      առնվազն 12 ամիս,
                    </li>
                    <li>
                      Վարկառուի և երաշխավորների դրական վարկային պատմություն՝
                      «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ–ի ներքին չափանիշներին
                      համապատասխան,
                    </li>
                    <li>
                      Ֆինանսական երաշխավորներ կարող են համարվել
                      ֆիզիկական/իրավաբանական անձիք, ովքեր ունեն կայուն եկամուտ
                      կամ անշարժ գույք, որը համարժեք է վարկի գումարին,
                    </li>
                    <li>
                      Վարկառուի և երաշխավորների համապատասխանություն «Միկրո
                      Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ - ի վարկային քաղաքականությանը և
                      ներքին կանոնակարգերով սահմանված այլ չափանիշներին:
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Վարկի նպատակ</td>
                <td>
                  <ul>
                    <li>Շրջանառու միջոցների ձեռքբերում</li>
                    <li>Հիմնական միջոցների ձեռքբերում</li>
                    <li>Գործառնական ծախսերի կատարում</li>
                    <li>
                      Ձեռնարկատիրական (բիզնես) գործունեության հետ կապված
                      նպատակներ
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Վարկի արժույթ</td>
                <td>ՀՀ դրամ, ԱՄՆ դոլար</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Վարկի տրամադրման նվազագույն և առավելագույն սահմանաչափեր</td>
                <td>
                  <ul>
                    <li>500,000 – 7,500,000 ՀՀ դրամ</li>
                    <li>1,000 – 15,000 ԱՄՆ դոլար </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>Վարկի նվազագույն և առավելագույն ժամկետներ</td>
                <td>6 - 18 ամիս</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Մարման ժամանակացույց</td>
                <td>
                  <ul>
                    <li>
                      Անուիտետային վճարումներ – հավասարաչափ վճարումներ, որի
                      դեպքում վարկի ամբողջ ժամանակահատվածի ընթացքում
                      յուրաքանչյուր ամիս հաճախորդը կատարում է միևնույն չափի
                      վճարումներ,
                    </li>
                    <li>
                      Հավասարաչափ վճարումներ – հաճախորդը վճարում է յուրաքանչյուր
                      ամիս հավասարաչափ մայր գումար, որի արդյունքում
                      տոկոսավճարների հանրագումարը ամեն ամիս նվազում է
                    </li>
                    <li>
                      Ճկուն մարման ժամանակացույց – վարկի մայր գումարի մարումները
                      բաշխվում են մարման ժամանակաշրջանի վրա անհավասարաչափ
                      ամսական կտրվածքով{" "}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>Արտոնյալ ժամկետ մայր գումարի համար</td>
                <td>Առավելագույնը 2 ամիս</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Վարկի տրամադրման եղանակ</td>
                <td>
                  Վարկը տրամադրվում է անկանխիկ եղանակով՝ Վարկառուի բանկային
                  հաշվին կամ «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ գործընկեր բանկում
                  առկա համապատասխան հաշվին փոխանցում կատարելու միջոցով կամ
                  բանկային չեկի միջոցով
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>Վաղաժամկետ մարում</td>
                <td>
                  <ul>
                    <li>
                      Վարկառուն իրավունք ունի վաղաժամկետ ամբողջական կամ մասնակի
                      մարելու վարկը և կատարելու պայմանագրով սահմանված այլ
                      պարտականությունները առանց տույժ վճարելու,
                    </li>
                    <li>
                      Վարկի վաղաժամկետ ամբողջական մարման պարագայում վարկառուն
                      վճարում է վճարման օրվա դրությամբ հաշվարկված վարկի
                      սպասարկման և այլ վճարները, տոկոսները և վարկի մայր գումարը,
                    </li>
                    <li>
                      Վարկի վաղաժամկետ մասնակի մարման պարագայում գումարը
                      ուղղվում է վարկի մայր գումարի մարմանը և համամասնորեն
                      նվազեցվում են վարկի հետագա ամսվա (ամիսների) մարման ենթակա
                      տոկոսագումարները, եթե հաճախորդը չի տրամադրել այլ
                      հանձնարարական:{" "}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td>
                  Զուգահեռ վարկեր վարկառուների խմբին տվյալ վարկատեսակի
                  շրջանակներում
                </td>
                <td>
                  <ul>
                    <li>Զուգահեռ վարկերի առավելագույն քանակ - 2</li>
                    <li>
                      Զուգահեռ վարկերի առավելագույն գումարը – 7,500,000 ՀՀ դրամ
                      կամ 15,000 ԱՄՆ դոլար
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>12</td>
                <td>Տրանշեր</td>
                <td>-</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Ապահովվածություն (նվազագույն)</td>
                <td>
                  <ul>
                    <li>3-րդ անձանց երաշխավորություն</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>14</td>
                <td>Վճարունակության գնահատում</td>
                <td>
                  <ul>
                    <li> հաճախորդի սոցիալական բնութագիր,</li>
                    <li>հաճախորդի վարկային պատմություն,</li>
                    <li>
                      հաճախորդի բանկային քաղվածքների վերլուծություն (համաձայն
                      ներքին ընթացակարգերի)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>15</td>
                <td>Վարկերի դիտարկում (հաստատում/մերժում)</td>
                <td>
                  Համաձայն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ Վարկավորման
                  ընթացակարգով սահմանված որոշումների կայացման իրավասությունների,
                </td>
              </tr>
              <tr>
                <td>16</td>
                <td>Որոշման կայացման ժամկետ</td>
                <td>
                  4 աշխատանքային ժամ (հաճախորդի կողմից ամբողջական փաթեթի
                  առկայության դեպքում)
                </td>
              </tr>
              <tr>
                <td>17</td>
                <td>Որոշման մասին տեղեկացնելու ժամկետ</td>
                <td>1 աշխատանքային օր</td>
              </tr>
              <tr>
                <td>18</td>
                <td>Վարկը տրամադրելու ժամկետ</td>
                <td>
                  1 աշխատանքային օր (վարկի ձևակերպման համար անհրաժեշտ
                  փաստաթղթերը ներկայացնելու դեպքում)
                </td>
              </tr>
              <tr>
                <td>19</td>
                <td>Վարկային հայտի մերժման հնարավոր պատճառներ</td>
                <td>
                  «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ գրասենյակներում կամ «Միկրո
                  Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ հետ համագործակցող/գործընկեր
                  կազմակերպության գործունեության վայրում
                </td>
              </tr>
              <tr>
                <td>20</td>
                <td>Վարկային հայտի մերժման հնարավոր պատճառներ</td>
                <td>
                  <ul>
                    <li>
                      սահմանված նվազագույն պահանջներին չբավարարման դեպքում (1 –
                      3 կետեր),
                    </li>
                    <li>
                      հաճախորդի կողմից անհրաժեշտ փաստաթղթերի և այլ
                      հիմնավորումների չներկայացման, կեղծ փաստաթղթերի կամ
                      տեղեկատվության ներկայացման դեպքում,
                    </li>
                    <li>
                      վարկային կոմիտեն չի բավարարել վարկային հայտը` հաշվի
                      առնելով գործարքի ռիսկայնությունը կամ առկա այլ ռիսկերը,
                      վարկային կոմիտեի իրավասությամբ սահմանված այլ դեպքերում,
                      այդ թվում.
                    </li>
                    <li>
                      <ul>
                        <li>դրական վարկային պատմության բացակայություն,</li>
                        <li>իրավական ռիսկերի առկայություն,</li>
                        <li>
                          «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ կողմից
                          չֆինանսավորվող ոլորտների ցանկին համապատասխանություն,
                        </li>
                        <li>ֆինանսական ցուցանիշների անբավարարություն</li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>21</td>
                <td>Մոնիթորինգ</td>
                <td>
                  Համաձայն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ – ի Վարկավորման
                  Ընթացակարգի
                </td>
              </tr>
              <tr>
                <td>22</td>
                <td>Անհրաժեշտ փաստաթղթերի ցանկ</td>
                <td>
                  <ul>
                    <li>
                      Հաճախորդի (առկայության դեպքում կազմակերպության
                      հիմնադիրների, կոլեգիալ կամ միանձնյա գործադիր մարմնի
                      (տնօրենի, գլխավոր տնօրենի, գործադիր տնօրենի և այլն),
                      բաժնետերերի և երաշխավորների) անձնագիր և սոցիալական քարտ
                      կամ նույնականացման քարտ,
                    </li>
                    <li>Պետական գրանցման կամ հաշվառման վկայական, ներդիր,</li>
                    <li>ՀՎՀՀ,</li>
                    <li>Կանոնադրություն (իրավաբանական անձանց դեպքում),</li>
                    <li>
                      Լիազորված մարմնի որոշում վարկի համար դիմելու վերաբերյալ
                      (անհրաժեշտության դեպքում),
                    </li>
                    <li>Բանկային հաշվի քաղվածք վերջին 12 ամսվա տվյալներով,</li>
                    <li>Այլ փաստաթղթեր (Վարկային Կոմիտեի պահանջով):</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>23</td>
                <td>Տարեկան տոկոսադրույք և վարկատեսակի այլ պայմաններ</td>
                <td>
                  Վարկատեսակի տոկոսադրույքը, տրամադրման վճարը և այլ պայմանները
                  սահմանվում են «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ լիազոր մարմնի
                  որոշմամբ սահմանված վարկատեսակների պայմաններով և սակագներով,
                  որի մասին հաճախորդները ծանուցվում են կազմակերպության բոլոր
                  տարածքային ստորաբաժանումներում, ինչպես նաև պայմանները
                  նկարագրող ինֆորմացիոն թերթիկների և կազմակերպության
                  ինտերնետային կայքի միջոցով
                </td>
              </tr>
              <tr>
                <td>24</td>
                <td>Այլ պայմաններ</td>
                <td>
                  <ul>
                    <li>
                      Հաճախորդի գործունեության վայր այցելությունը չի հանդիսանում
                      պարտադիր պայման,
                    </li>
                    <li>
                      1 (մեկ) հաճախորդին տրամադրված առանց գրավ վարկերի ընդհանուր
                      մնացորդը բոլոր վարկատեսակների գծով չի կարող գերազանցել
                      7,500,000 ՀՀ դրամը (համարժեք – 15,000 ԱՄՆ դոլար)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="2">ԶԳՈՒՇԱՑՈՒՄ</td>
                <td>
                  <ul>
                    <li>
                      ԱՄՆ ԴՈԼԱՐՈՎ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԵՐԻ ՊԱՐԱԳԱՅՈՒՄ ԱՐՏԱՐԺՈՒՅԹԻ
                      ՓՈԽԱՐԺԵՔԻ ՓՈՓՈԽՈՒԹՅՈՒՆՆԵՐԸ ԿԱՐՈՂ ԵՆ ԱԶԴԵՑՈՒԹՅՈՒՆ ՈՒՆԵՆԱԼ
                      ՎԱՐԿԻ ՄԱՐՄԱՆ ԳՈՒՄԱՐՆԵՐԻ ՎՐԱ,
                    </li>
                    <li>
                      ԱՄՆ ԴՈԼԱՐՈՎ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԵՐԻ ՊԱՐԱԳԱՅՈՒՄ ԱՐՏԱՐԺՈՒՅԹԻ
                      ՓՈՓՈԽՈՒԹՅԱՆ ԳՈՐԾՈՆԸ ՉԻ ՆԵՐԱՌՎՈՒՄ ՎԱՐԿԻ ՏՐԱՄԱԴՐՄԱՆ ԺԱՄԱՆԱԿ
                      ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՄԱՆ ՄԵՋ, ՀԵՏԵՎԱԲԱՐ ՏԱՐԵԿԱՆ
                      ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԸ ԿԱՐՈՂ Է ՓՈՓՈԽՎԵԼ՝ ԿԱԽՎԱԾ ՀԱՅԱՍՏԱՆԻ
                      ՀԱՆՐԱՊԵՏՈՒԹՅԱՆ ԿԵՆՏՐՈՆԱԿԱՆ ԲԱՆԿԻ ՊԱՇՏՈՆԱԿԱՆ ԿԱՅՔ ԷՋՈՒՄ
                      ՀՐԱՊԱՐԱԿՎԱԾ ՓՈԽԱՐԺԵՔԻ ՄԵԾՈՒԹՅԱՆ ՓՈՓՈԽՈՒԹՅՈՒՆԻՑ,{" "}
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՎԱՐԿԻ ՄԱՅՐ ԳՈՒՄԱՐԻ, ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ ԵՎ
                      ՍՊԱՍԱՐԿՄԱՆ ՎՃԱՐՆԵՐԻ ՄԱՐՈՒՄՆԵՐԸ ԺԱՄԱՆԱԿԻՆ ԵՎ ՊԱՏՇԱՃ
                      ՉԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ ՎԱՐԿԱՌՈՒԻ ԵՎ ԵՐԱՇԽԱՎՈՐԻ ՄԱՍԻՆ
                      ՀԱՄԱՊԱՏԱՍԽԱՆ ՏԵՂԵԿԱՏՎՈՒԹՅՈՒՆԸ ԿԳՐԱՆՑՎԻ ԿԲ ՎԱՐԿԱՅԻՆ
                      ՌԵԳԻՍՏՐՈՒՄ ԵՎ «ԱՔՌԱ» ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈՅԻ ՌԵԳԻՍՏՐՈՒՄ,
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒՆ ԻՐ ՊԱՅՄԱՆԱԳՐԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԿԱՏԱՐՄԱՆ
                      ՀԱՄԱՐ ՆՅՈՒԹԱԿԱՆ ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ Է ԿՐՈՒՄ ՆԱԵՎ
                      ՍԵՓԱԿԱՆՈՒԹՅԱՆ ԻՐԱՎՈՒՆՔՈՎ ԻՐԵՆ ՊԱՏԿԱՆՈՂ ՑԱՆԿԱՑԱԾ ԱՅԼ
                      ԳՈՒՅՔՈՎ (ՏՈՒՆ, ԲՆԱԿԱՐԱՆ, ԱՎՏՈՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԵՎ ԱՅԼՆ),{" "}
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒՆ ԵՎ ԵՐԱՇԽԱՎՈՐԸ ԻՐԵՆՑ ԱՆՁՆԱԿԱՆ ԱՄԲՈՂՋ ԳՈՒՅՔՈՎ
                      (ՏՈՒՆ, ՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԿԱՄ ԱՅԼ ԳՈՒՅՔ) ՀԱՎԱՍԱՐԱՊԵՍ
                      ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ ԵՆ ԿՐՈՒՄ «ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ
                      ՓԲԸ – Ի ԱՌՋԵՎ ՍՏԱՆՁՆԱԾ ԻՐԵՆՑ ՎԱՐԿԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ
                      ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒԻ ԵՎ/ԿԱՄ ԵՐԱՇԽԱՎՈՐԻ ԱՆՁՆԱԿԱՆ ԳՈՒՅՔԸ ԿԱՐՈՂ Է
                      ԲՌՆԱԳԱՆՁՎԵԼ ՕՐԵՆՔՈՎ ՍԱՀՄԱՆՎԱԾ ԿԱՐԳՈՎ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ
                      ԺԱՄԱՆԱԿԻՆ ՉԻ ԿԱՏԱՐՈՒՄ ԻՐ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ, ՍՊԱՍԱՐԿՄԱՆ
                      ՎՃԱՐՆԵՐԻ ԵՎ ՎԱՐԿԻ ԳՈՒՄԱՐԻ ՄԱՐՈՒՄՆԵՐԸ,
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆԸ ՉԿԱՏԱՐԵԼՈՒ ԿԱՄ ԹԵՐԻ
                      ԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ ՎԱՐԿԱՏՈՒՆ 3 (ԵՐԵՔ) ԱՇԽԱՏԱՆՔԱՅԻՆ ՕՐ ՀԵՏՈ
                      ԱՅԴ ՏՎՅԱԼՆԵՐԸ ՈՒՂԱՐԿՈՒՄ Է ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈ, ՈՐՏԵՂ
                      ՁԵՎԱՎՈՐՎՈՒՄ Է ՎԱՐԿԱՌՈՒԻ ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ: ՎԱՐԿԱՌՈՒՆ
                      ԻՐԱՎՈՒՆՔ ՈՒՆԻ ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈՅԻՑ ՏԱՐԵԿԱՆ ՄԵԿ ԱՆԳԱՄ ԱՆՎՃԱՐ
                      ՁԵՌՔ ԲԵՐԵԼ ԻՐ ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ: ՎԱՏ ՎԱՐԿԱՅԻՆ
                      ՊԱՏՄՈՒԹՅՈՒՆԸ ԿԱՐՈՂ Է ԽՈՉԸՆԴՈՏԵԼ ՎԱՐԿԱՌՈՒԻ ԱՊԱԳԱՅՈՒՄ ՎԱՐԿ
                      ՍՏԱՆԱԼՈՒ ԺԱՄԱՆԱԿ:
                    </li>
                    <li>
                      ՄԻՆՉԵՎ 5,000,000 ՀՀ ԴՐԱՄ ԿԱՄ 11,000 ԱՄՆ ԴՈԼԱՐ ՎԱՐԿ
                      ՍՏԱՆԱԼՈՒ ՀԱՄԱՐ ԴԻՄԵԼԻՍ ՎԱՐԿԱՏՈՒՆ ՎԱՐԿԱՌՈՒԻՆ ԿՏՐԱՄԱԴՐԻ
                      ՎԱՐԿԻ ԷԱԿԱՆ ՊԱՅՄԱՆՆԵՐԻ ԱՆՀԱՏԱԿԱՆ ԹԵՐԹԻԿ, ՈՐՈՒՄ
                      ԿՆԵՐԿԱՅԱՑՎԵՆ ՎԱՐԿԱՌՈՒԻՆ ՏՐԱՄԱԴՐՎԵԼԻՔ ՎԱՐԿԻ ԱՆՀԱՏԱԿԱՆ
                      ՊԱՅՄԱՆՆԵՐԸ,
                    </li>
                    <li>
                      ՄԻՆՉԵՎ 5,000,000 ՀՀ ԴՐԱՄ ԿԱՄ 11,000 ԱՄՆ ԴՈԼԱՐ ՏՐԱՄԱԴՐՎԱԾ
                      ՎԱՐԿԱՅԻՆ ՊԱՅՄԱՆԱԳՐԻ ԳՈՐԾՈՂՈՒԹՅԱՆ ԸՆԹԱՑՔՈՒՄ ՎԱՐԿԱՌՈՒԻ
                      ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ՎԵՐԱԲԵՐՅԱԼ ՔԱՂՎԱԾՔՆԵՐԸ ԱՄԻՍԸ ՄԵԿ ԱՆԳԱՄ
                      ՈՒՂԱՐԿՎՈՒՄ ԵՆ ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՏՐԱՄԱԴՐՎԱԾ ԷԼԵԿՏՐՈՆԱՅԻՆ
                      ՓԱՍՏԻ ՀԱՍՑԵԻՆ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ ՔԱՂՎԱԾՔԻ ՍՏԱՑՄԱՆ ԱՅԼ ԵՂԱՆԱԿ
                      ՉԻ ԸՆՏՐՈՒՄ: ԱՆԿԱԽ ՆՇՎԱԾ ԵՂԱՆԱԿՈՎ ՔԱՂՎԱԾՔԸ ՍՏԱՆԱԼՈՒՑ՝
                      ՎԱՐԿԱՌՈՒՆ ԻՐԱՎՈՒՆՔ ՈՒՆԻ ԱՅՆ ՍՏԱՆԱԼ (ԴԻՄԵԼՈՒ ՊԱՀԻՆ)
                      ՎԱՐԿԱՏՈՒԻ ՏԱՐԱԾՔՈՒՄ՝ ԱՌՁԵՌՆ։ ՔԱՂՎԱԾՔՆԵՐԻ ՏՐԱՄԱԴՐՈՒՄՆ
                      ԱՆՎՃԱՐ Է:
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="termsContainer">
            <table className="termsTable mainDesc">
              <tbody>
                <tr>
                  <td colSpan="2" style={{ backgroundColor: "green" }}>
                    ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՈՒՄԸ
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    ՈՒՇԱԴՐՈՒԹՅՈՒՆ՝ ՎԱՐԿԻ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԸ ՀԱՇՎԱՐԿՎՈՒՄ ԵՆ
                    ԱՆՎԱՆԱԿԱՆ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԻՄԱՆ ՎՐԱ: ԻՍԿ ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ
                    ՏՈԿՈՍԱԴՐՈՒՅՔԸ ՑՈՒՅՑ Է ՏԱԼԻՍ, ԹԵ ՈՐՔԱՆ ԿԱՐԺԵՆԱ ՎԱՐԿԸ ՁԵԶ
                    ՀԱՄԱՐ՝ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԸ ԵՎ ՎԱՐԿԻ ՏՐԱՄԱԴՐՄԱՆ ԵՎ ՍՊԱՍԱՐԿՄԱՆ
                    ԳԾՈՎ ԲՈԼՈՐ ՊԱՐՏԱԴԻՐ ՎՃԱՐՆԵՐԸ ՍԱՀՄԱՆՎԱԾ ԺԱՄԿԵՏՆԵՐՈՒՄ ԵՎ
                    ՉԱՓԵՐՈՎ ԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ: Տարեկան փաստացի տոկոսադրույքի
                    չափը կախված է վարկի պայմաններից (գումար, ժամկետ, վարկի
                    մարմանն ուղված վճարների գումար և մարումների քանակ)
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    Տարեկան փաստացի տոկոսադրույքը հաշվարկվում է հետևյալ
                    բանաձևով`
                    <img width="180" height="60" class="banacev" />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    որտեղ`
                    <strong>A</strong> վարկի գումարը (վարկատուի կողմից
                    վարկառուին տրամադրված վարկի սկզբնական գումարը)
                    <strong>n</strong> վարկի մարմանն ուղղված վճարի թիվը N վարկի
                    մարմանն ուղղված վերջին վճարի թիվը
                    <strong>Kn</strong> վարկի մարմանն ուղղված n-րդ վճարի գումարը
                    <strong>Dn</strong> վարկի տրամադրման օրից մինչև վարկի
                    մարմանն ուղղված n-րդ վճարի կատարման օրն ընկած
                    ժամանակահատվածը` արտահայտված օրերի թվով
                    <strong>i</strong> տարեկան փաստացի տոկոսադրույքը, որը կարող
                    է հաշվարկվել, եթե հավասարման մյուս տվյալները հայտնի են
                    վարկավորման պայմանագրից կամ այլ կերպ
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՄԱՆ ՕՐԻՆԱԿ
                  </td>
                </tr>
                <tr>
                  <td>Վարկի գումար</td>
                  <td>15,000 ԱՄՆ դոլար</td>
                </tr>
                <tr>
                  <td>Անվանական տարեկան տոկոսադրույք</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>Վարկի ժամկետ</td>
                  <td>18 ամիս</td>
                </tr>
                <tr>
                  <td>Վարկի տրամադրման միանվագ վճար</td>
                  <td>վարկի գումարի 3%, առավելագույնը 150,000 ՀՀ դրամ</td>
                </tr>
                <tr>
                  <td>Սպասարկման ամենամսյա վճար 0.3%</td>
                  <td>0.3%</td>
                </tr>
                <tr>
                  <td>Մարումներ</td>
                  <td>Մարումներ</td>
                </tr>
                <tr>
                  <td>Վարկի տրամադրման օր</td>
                  <td>01 հոկտեմբեր 2019թ</td>
                </tr>
                <tr>
                  <td>Առաջին մարման օր</td>
                  <td>01 նոյեմբեր 2019թ.</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <strong> Տարեկան փաստացի տոկոսադրույք` 26.85% </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsQaxvacq;
