import React from 'react';
import label from '../../data/labels';
import './CustomerType.css';
import {
    loadSubEconomicActivityTypes,
    loadEconomicActivityExperienceTypes,
    loadLoanPurposes,
    loadInformationSourceTypes,
    loadDocumentTypes,
    loadDocumentStatuses,
    loadCurrencies,
    loadAddressTypes,
    loadCountries,
    loadRegions
} from '../../data/dao';
import { blockUIStyleUploading, unblockUI } from '../../data/utility';

class CustomerType extends React.Component {

    constructor(props) {
        super(props);
        this.state = { ...props };
        this.handler = this.handler.bind(this);
    }

    reloadLabelLists() {
        loadSubEconomicActivityTypes(1);
        loadEconomicActivityExperienceTypes();
        loadLoanPurposes();
        loadInformationSourceTypes();
        loadDocumentTypes();
        loadDocumentStatuses();
        loadCurrencies();
        loadAddressTypes();
        loadCountries();
        loadRegions(7);
    }

    checkLists = val => {
        if (
            label.lists.subEconomicActivityTypes.length === 0 ||
            label.lists.economicActivityExperienceTypes.length === 0 ||
            label.lists.loanPurposes.length === 0 ||
            label.lists.informationSourceTypes.length === 0 ||
            label.lists.documentTypes.length === 0 ||
            label.lists.documentStatuses.length === 0 ||
            label.lists.regions.length === 0
        ) {
            this.reloadLabelLists();
            blockUIStyleUploading();
            return false;
        }
        else {
            let clone = { ...this.state };
            clone.customerType = val;
            this.setState({ ...clone }, () => this.props.handler(this.state));
            return true;
        }
    }

    handler = event => {
        const val = event.target.value;
        const self = this;
        if(!this.checkLists(val)) {
            setTimeout(function() { 
                if(!self.checkLists(val)) {
                    unblockUI();                    
                }
                else {
                    unblockUI();
                }

            }, 5000);
        }


        // check if labels are loaded

        // let self = this;
        // var promise = new Promise(function (resolve, reject) {
        //     // do a thing, possibly async, then…

        //     if (self.checkLists(event)) {
        //         resolve("Stuff worked!");
        //     }
        //     else {
        //         reject(Error("It broke"));
        //     }
        // });

        // promise.then(
        //     function () {
                
        //     },
        //     function () {
        //         alert('Please reload');
        //     }
        // )
    };

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-4 customerGroup">
                        <div className="inputCustomerGroup">
                            <input id="rdbCustomerB" name="customerType" type="radio" value="1" checked={this.state.customerType === "1"} onChange={this.handler} />
                            <label htmlFor="rdbCustomerB">{label.borrowerLabel}</label>
                        </div>
                    </div>
                    <div className="col-lg-4 customerGroup">
                        <div className="inputCustomerGroup">
                            <input id="rdbCustomerG" name="customerType" type="radio" value="2" checked={this.state.customerType === "2"} onChange={this.handler} />
                            <label htmlFor="rdbCustomerG">{label.guarantorLabel}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerType;