import React from 'react';
import label from '../../data/labels';
import MaskWithValidation from '../formElements/MaskWithValidation';
import { TextInput, SelectGroup } from 'react-bootstrap4-form-validation';

function read(onSuccess, file) {
    var reader = new FileReader();

    reader.onload = function() {
        onSuccess(reader.result);
    };

    reader.readAsDataURL(file);
}

class BorrowerAsPerson extends React.Component {
    constructor(props) {
        super(props);
        const { ...others } = this.props;
        this.state = { ...others };
        this.handler = this.handler.bind(this);
        this.getBusinessRulesField = this.getBusinessRulesField.bind(this);
        this.getCertificatesField = this.getCertificatesField.bind(this);
        this.handlerUpload = this.handlerUpload.bind(this);
    }

    /**
     *
     * @param {*} newState
     */
    handler(newState) {
        this.setState({ ...newState }, () => this.props.handler(this.state));
    }

    /**
     *
     */
    handlerChange = (name) => event => {
        const { value } = event.target;
        let clone = { ...this.state };
        let obj = name.split(".");

        this.setValue(obj, clone, value);
        this.setState({ ...clone });
    }

    setValue(obj, clone, value) {
        if (obj.length === 1) {
            clone[obj] = value;
        }
        else {
            this.setValue(obj.slice(1), clone[obj[0]], value);
        }
    }

    componentDidMount() {
        /* Change background */
        let clone = { ...this.state };
        clone.bg = "background1";
        this.setState({ ...clone }, () => this.handler(this.state));
    }

    getCertificatesField() {
        const upIcon = (
          <strong>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="17"
                viewBox="0 0 20 17"
              >
                  <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
              </svg>
          </strong>
        );

        if (this.state.borrower.borrowerType !== "personal") {
            return (
              <div className="">
                  <div className="box">
                      <input
                        type="file"
                        name="certificate[]"
                        id="certificate"
                        className="inputfile inputfile-7"
                        data-multiple-caption={label.selectedPicCount}
                        multiple
                        onChange={this.handlerUpload("certificate")}
                        accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                      <label htmlFor="certificate">
              <span>
                {this.state.borrower.borrowerType === "corp"
                  ? label.registrationLabel
                  : label.registrationExistsLabel}
              </span>
                          {upIcon}
                      </label>
                      <div className="uploaded-info"></div>
                  </div>
              </div>
            );
        } else return <></>;
    }

    handlerUpload = name => event => {
        const files = Array.from(event.target.files);

        const formData = [];

        files.forEach((file, i) => {
            read(function(r) {
                formData.push({
                    fileName: file.name,
                    fileType: file.type,
                    fileContent: r.split(",")[1]
                });
            }, file);
        });

        let clone = { ...this.state };
        clone.borrower.attachments[name] = formData; // formData;

        this.setState({ ...clone });
    };

    getBusinessRulesField() {
        const upIcon = (
          <strong>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="17"
                viewBox="0 0 20 17"
              >
                  <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
              </svg>
          </strong>
        );

        if (this.state.borrower.borrowerType === "corp") {
            return (
              <div className="">
                  <div className="box">
                      <TextInput
                        type="file"
                        name="businessRules[]"
                        id="businessRules"
                        className="inputfile inputfile-7"
                        data-multiple-caption={label.selectedPicCount}
                        multiple
                        onChange={this.handlerUpload("businessRules")}
                        accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        // errorMessage={{
                        //   validator: "Please choose a business rules",
                        //   required: ""
                        // }}
                        // required
                      />
                      <label htmlFor="businessRules">
                          <span>{label.rulesLabel}</span>
                          {upIcon}
                      </label>
                      <div className="uploaded-info"></div>
                  </div>
              </div>
            );
        } else return <></>;
    }

    /**
     *
     */
    render() {
        let pastDueTypes = label.lists.pastDue.map((pd, i) =>
          <option key={i} value={pd.key}>{pd.value}</option>
        );

        const upIcon = (
          <strong>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="17"
                viewBox="0 0 20 17"
              >
                  <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
              </svg>
          </strong>
        );

        return (
            <div className="row js">
                <div className="col-sm-4 infoBlock">
                    <div>
                        <div className="form-group">
                            <label htmlFor="txtFirstName">{label.firstNameLabel}</label>
                            <TextInput className="form-control input-lg" id="txtFirstName" name="firstName" type="text"
                                maxLength="30" placeholder={label.yourFirstNamePlaceholder}
                                value={this.state.borrower.personalInformation.firstName}
                                onChange={this.handlerChange('borrower.personalInformation.firstName')}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="txtLastName">{label.lastNameLabel}</label>
                            <TextInput className="form-control input-lg" id="txtLastName" name="lastName" type="text"
                                maxLength="30" placeholder={label.yourLastNamePlaceholder}
                                value={this.state.borrower.personalInformation.lastName}
                                onChange={this.handlerChange('borrower.personalInformation.lastName')}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="txtPhoneNumber">{label.phoneLabel}</label>
                            <MaskWithValidation id="txtPhoneNumber" name="phone" className="form-control"
                                placeholder={label.textmask}
                                minLength="16"
                                value={this.state.borrower.personalInformation.phoneNumber}
                                onChange={this.handlerChange('borrower.personalInformation.phoneNumber')}
                                // validator={(value) => value === "+374 ** ** ** **"}
                                // onChange={this.onChange}
                                // successMessage="Looks good!"
                                // errorMessage={{ validator: "Please enter +374 -- -- -- --" }}
                                errorMessage={{
                                    minLength: "Մուտքագրեք վավեր հեռախոսահամարը"
                                    // minLength: "Minimum {minLength} characters is required"
                                }}
                                mask={['+', '3', '7', '4', " ", /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/]}
                                guide={false}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 infoBlock">
                    <div>
                        <div className="form-group">
                            <label htmlFor="ddlPastDueTypes">{label.pastDueLabel}</label>
                            <SelectGroup name="pastDueDates" id="ddlPastDueTypes"
                                value={this.state.borrower.pastDueDates}
                                onChange={this.handlerChange('borrower.pastDueDates')}
                                required>
                                <option value="">{label.selectPlaceholder}</option>
                                {pastDueTypes}
                            </SelectGroup>
                        </div>
                        <div>
                            <div className="form-group">
                                <label>{label.attachImagesLabel}</label>
                                <div className="filesBlock">
                                    {this.getCertificatesField()}

                                    <div className="">
                                        <div className="box">
                                            <TextInput
                                              type="file"
                                              name="socialCard[]"
                                              id="socialCard"
                                              className="inputfile inputfile-7"
                                              data-multiple-caption={label.selectedPicCount}
                                              multiple
                                              onChange={this.handlerUpload("socialCard")}
                                              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                              errorMessage={{
                                                  validator: "Please choose a socialCard",
                                                  required: ""
                                              }}
                                              required
                                            />
                                            <label htmlFor="socialCard">
                                                <span>{label.socialCartLabel}</span>
                                                {upIcon}
                                            </label>
                                            <div className="uploaded-info"></div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="box">
                                            <TextInput
                                              type="file"
                                              name="passportOrId[]"
                                              id="passportOrId"
                                              className="inputfile inputfile-7"
                                              data-multiple-caption={label.selectedPicCount}
                                              multiple
                                              onChange={this.handlerUpload("passportOrId")}
                                              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                              errorMessage={{
                                                  validator: "Please choose a passport",
                                                  required: ""
                                              }}
                                              required
                                            />
                                            <label htmlFor="passportOrId">
                                                <span>{label.passportLabel}</span>
                                                {upIcon}
                                            </label>
                                            <div className="uploaded-info"></div>
                                        </div>
                                    </div>

                                    {this.getBusinessRulesField()}
                                    {/*{this.getCompanyLogoLabel()}*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BorrowerAsPerson;
