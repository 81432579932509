import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import label from './../../data/labels';
import BorrowerMainInfo from './BorrowerMainInfo';
import BorrowerOptionalInfo from './BorrowerOptionalInfo';
import BorrowerFinInfo from './BorrowerFinInfo';
import styles from './../../styles/borrowerStyles';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { sendRegisterClientAndLoan } from '../../data/dao';
import { blockUIError, blockUIStyleUploading } from '../../data/utility';
import labels from './../../data/labels';

function getSteps() {
    return ['', '', ''];
}

class Borrower extends React.Component {
    constructor(props) {
        super(props);
        const { classes, ...others } = this.props;
        this.state = {
            ...others,
            activeStep: 0,
            immediate: false,
            setFocusOnError: true,
            clearInputOnReset: false
        };
        this.handler = this.handler.bind(this);
        this.formRef = React.createRef();
    }

    /**
     * Set a new state and send it up by chain to the parent component
     * @param {*} newState
     */
    handler(newState) {
        this.setState({ ...newState }, () => this.props.handler(this.state));
    }

    /**
     * Load the next form
     */
    handleNext = () => {
        if (this.state.borrower.pastDueDates === "2") {
            blockUIError(label.currentPastDue);
        }
        else if (this.state.borrower.businessPeriod === "4") {
            blockUIError(label.shortLastOfBusiness);
        }
        else if(["72","73"].includes(this.state.borrower.productTypes,0) && !labels.lists.product7273AllowedActivities.includes(this.state.borrower.businessType,0))
        {
            blockUIError(label.productActivityRestriction);
        }
        else if(["72","73","33"].includes(this.state.borrower.productTypes,0) && this.state.borrower.businessPeriod==="3")
        {
            blockUIError(label.productbusinessPeriodRestriction12)
        }
        else if (this.state.borrower.productTypes=="33" && this.state.borrower.businessType=="47.9")
        {
            blockUIError(label.productActivityRestriction)
        } else {
            blockUIStyleUploading();
            sendRegisterClientAndLoan(this);
        }

        // switch (this.state.activeStep) {
        //     case 0:
        //         if (this.state.borrower.pastDueDates === "2") {
        //             blockUIError(label.currentPastDue);
        //         }
        //         else if (this.state.borrower.businessPeriod === "4") {
        //             blockUIError(label.shortLastOfBusiness);
        //         }
        //         else if(["72","73"].includes(this.state.borrower.productTypes,0) && !labels.lists.product7273AllowedActivities.includes(this.state.borrower.businessType,0))
        //         {
        //                 blockUIError(label.productActivityRestriction);
        //         }
        //         else if(["72","73","33"].includes(this.state.borrower.productTypes,0) && this.state.borrower.businessPeriod==="3")
        //         {
        //             blockUIError(label.productbusinessPeriodRestriction12)
        //         }
        //         else if (this.state.borrower.productTypes=="33" && this.state.borrower.businessType=="47.9")
        //         {
        //             blockUIError(label.productActivityRestriction)
        //         }
        //         break;
        //     case 2:
        //         // validate before sending data
        //
        //         // SEND DATA FROM HERE AND WAIT FOR A RESPONSE
        //         blockUIStyleUploading();
        //         sendRegisterClientAndLoan(this);
        //         break;
        //     default:
        //         const { activeStep } = this.state;
        //         this.setState({
        //             activeStep: activeStep + 1
        //         });
        // }
    };

    /**
     * Load previous form
     */
    // handleBack = () => {
    //     this.setState(state => ({
    //         activeStep: state.activeStep - 1,
    //     }));
    // };

    /**
     * Return the Step marker
     */
    getStep = (stepNumber) => {

        let stepStage = stepNumber < this.state.activeStep ? "completed" : (stepNumber === this.state.activeStep ? "active" : "passive");
        let stepContent = stepStage === "completed" ?
            <span>
                <svg className="svgIcon-root-90" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"></path>
                </svg>
            </span>
            :
            <span>
                <svg className="svgIcon-root-90" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <circle cx="12" cy="12" r="12"></circle>
                    <text className="stepIcon-text-86" x="12" y="16" textAnchor="middle">{stepNumber + 1}</text>
                </svg>
            </span>;

        return <li className={stepStage}>
            <div>
                <span>
                    {stepContent}
                    <span className="">
                        <span className=""></span>
                    </span>
                </span>
            </div>
        </li>
    }

    /**
     * Retrurn current active form
     */
    getStepContent() {
        return (
          <>
              <BorrowerMainInfo handler={this.handler} {...this.state} />
              <BorrowerFinInfo handler={this.handler} reset={this.resetForm} {...this.state} />
              <BorrowerOptionalInfo handler={this.handler} {...this.state} />
          </>
        );
    }

    /**
    * The form is valid. Send data
    */
    handleSubmit = (e, formData, inputs) => {
        e.preventDefault();
        this.handleNext();
        this.resetForm();
    }

    /**
     * The form is not valid.
     */
    handleErrorSubmit = (e, formData, errorInputs) => {

    }

    /**
     * Reset validation's messages
     */
    resetForm = () => {
        // debugger
        // if (typeof this.formRef.current !== 'undefined')
        //     this.formRef = React.createRef();

        let formRef = this.formRef.current;
        if (typeof formRef !== 'undefined' && formRef !== null)
            formRef.resetValidationState(this.state.clearInputOnReset);
    }

    /**
     * Native function
     */
    componentWillMount() {

    }

    /**
     * Native function
     */
    componentDidMount() {
        /* Change background */
        let clone = { ...this.state };
        clone.bg = "background1";
        this.setState({ ...clone }, () => this.handler(this.state));
    }

    render() {
        return (
            <div style={{ "maxWidth": "1180px" }}>
                <div>
                    <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                        ref={this.formRef}
                        immediate={this.state.immediate}
                        setFocusOnError={this.state.setFocusOnError}
                        defaultErrorMessage={{ required: label.required }} >
                        {this.getStepContent()}
                        <div className="row">
                            <div className="col-sm-4 infoBlock">
                            </div>
                            <div className="col-sm-4 infoBlock" style={{ "textAlign": "right" }}>
                                <button className="preNext preNextFub nextButton" tabIndex="0">
                                    <span className="label">{label.sendFormLabel}</span>
                                    <span className="touchRipple"></span>
                                    <i />
                                </button>
                            </div>
                        </div>
                    </ValidationForm>
                </div>
            </div>
        );
    }
}

Borrower.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(Borrower);
