import React from 'react';
import label from '../../data/labels';
import { TextInput, SelectGroup, Checkbox } from 'react-bootstrap4-form-validation';
import { loadRegionCities } from '../../data/dao';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskWithValidation from '../formElements/MaskWithValidation';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { agreementModal } from '../../data/utility';
import AgreementModal from '../modals/AgreementModal';


// First, you need to create the `numberMask` with your desired configurations
const numberMask = createNumberMask({
    prefix: '',
    suffix: '' // This will put the dollar sign at the end, with a space.
})
const simpleNumberMask = createNumberMask({
    prefix: '',
    suffix: '', // This will put the dollar sign at the end, with a space.
    allowLeadingZeroes: true
})
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy', {
    minYear: new Date().getFullYear() - 15,
    maxYear: new Date().getFullYear()
});

class BorrowerOptionalInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            optionalActivated: false
        };
        this.handler = this.handler.bind(this);
        this.handleActivateOptional = this.handleActivateOptional.bind(this);
        this.handlerLocalState = this.handlerLocalState.bind(this);
    }

    /**
    * Handler send data to the parent controller
    * @param {*} newState
    */
    handler(newState) {
        this.setState({ ...newState }, () => this.props.handler(this.state));
    }

    /**
     * Handler collect all changes in the form
     */
    handlerChange = (name) => event => {
        const { value } = event.target;
        let clone = { ...this.state };
        let obj = name.split(".");

        this.setValue(obj, clone, value);
        this.setState({ ...clone });
    }

    handlerLocalState(newState) {
        //this.setState({ ...newState });
    }

    setValue(obj, clone, value) {
        if (obj.length === 1) {
            clone[obj] = value;
        }
        else {
            this.setValue(obj.slice(1), clone[obj[0]], value);
        }
    }

    handlerChangeRegion = (name) => event => {
        const { value } = event.target;
        let clone = { ...this.state };
        let obj = name.split(".");

        this.setValue(obj, clone, value);
        this.setState({ ...clone }, () => loadRegionCities(this.state.borrower.companyOptional.registrationDistrict, this));
    }

    handleActivateOptional() {
        let clone = { ...this.state };
        clone.optionalActivated = !clone.optionalActivated;
        this.setState({ ...clone });
    }

    handlerAgreement = () => event => {
        //const { value } = event.target;
        let clone = { ...this.state };

        clone.uiSupport.agreementStatus = !clone.uiSupport.agreementStatus;
        if (clone.uiSupport.agreementStatus === true) {
            agreementModal();
        }
        this.setState({ ...clone });
    }

    /**
     * Native Function
     */
    componentWillMount() {
        label.lists.cities = [];
    }

    /**
     * Native Function
     */
    componentDidMount() {
        /* Change background */
        let clone = { ...this.state };
        clone.bg = "background0";
        this.setState({ ...clone }, () => this.handler(this.state));
    }

    render() {

        let regions = label.lists.regions.map((region, i) =>
            <option key={i} value={region.key}>{region.value}</option>
        );

        let cities = label.lists.cities.map((city, i) =>
            <option key={i} value={city.key}>{city.value}</option>
        );

        let responseWays = label.lists.responseWays.map((responseWay, i) =>
            <option key={i} value={responseWay}>{responseWay}</option>
        );

        let documentTypes = label.lists.documentTypes.map((documentType, i) =>
            <option key={i} value={documentType.id}>{documentType.description}</option>
        );

        let residences = label.lists.residences.map((residence, i) =>
            <option key={i} value={residence.key}>{residence.value}</option>
        );

        let genders = label.lists.genders.map((gender, i) =>
            <option key={i} value={gender.key}>{gender.value}</option>
        );

        const { optionalActivated } = this.state;

        return (
            <div>
                {/*<div className="row js">*/}
                {/*    <div className="col-sm-4 infoBlock">*/}
                {/*        <div>*/}
                {/*            <div className="form-group">*/}
                {/*                <label htmlFor="txtMonthlyNet">{label.monthlyNetLabel}</label>*/}
                {/*                <MaskWithValidation className="form-control input-lg" id="txtMonthlyNet" name="monthlyNet"*/}
                {/*                    minLength="3"*/}
                {/*                    maxLength="15" placeholder="0"*/}
                {/*                    value={this.state.borrower.businessFinances.monthlyNet}*/}
                {/*                    onChange={this.handlerChange('borrower.businessFinances.monthlyNet')}*/}
                {/*                    errorMessage={{*/}
                {/*                        minLength: "Խնդրում ենք մուտքագրել վավեր գումար",*/}
                {/*                        validator: "Խնդրում ենք մուտքագրել վավեր գումար"*/}
                {/*                    }}*/}
                {/*                    mask={numberMask}*/}
                {/*                    required*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="form-group">*/}
                {/*                <label htmlFor="txtMonthlyGross">{label.monthlyGrossLabel}</label>*/}
                {/*                <MaskWithValidation className="form-control input-lg" id="txtMonthlyGross" name="monthlyGross"*/}
                {/*                    minLength="3"*/}
                {/*                    maxLength="15" placeholder="0"*/}
                {/*                    value={this.state.borrower.businessFinances.monthlyGross}*/}
                {/*                    onChange={this.handlerChange('borrower.businessFinances.monthlyGross')}*/}
                {/*                    errorMessage={{*/}
                {/*                        minLength: "Խնդրում ենք մուտքագրել վավեր գումար",*/}
                {/*                        validator: "Խնդրում ենք մուտքագրել վավեր գումար"*/}
                {/*                    }}*/}
                {/*                    mask={numberMask}*/}
                {/*                    required*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="form-group">*/}
                {/*                <label htmlFor="txtMonthlyPayment">{label.monthlyPaymentLabel}</label>*/}
                {/*                <MaskWithValidation className="form-control input-lg" id="txtMonthlyPayment" name="monthlyPayment"*/}
                {/*                    minLength="3"*/}
                {/*                    maxLength="15" placeholder="0"*/}
                {/*                    value={this.state.borrower.businessFinances.monthlyPayment}*/}
                {/*                    onChange={this.handlerChange('borrower.businessFinances.monthlyPayment')}*/}
                {/*                    errorMessage={{*/}
                {/*                        minLength: "Խնդրում ենք մուտքագրել վավեր գումար",*/}
                {/*                        validator: "Խնդրում ենք մուտքագրել վավեր գումար"*/}
                {/*                    }}*/}
                {/*                    mask={numberMask}*/}
                {/*                    required*/}
                {/*                />*/}
                {/*            </div>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-sm-4 infoBlock">*/}
                {/*        <div>*/}
                {/*            <div className="form-group">*/}
                {/*                <label htmlFor="ddlCompanyRegistrationDistrict"><br />{label.mailingRegionLabel}</label>*/}
                {/*                <SelectGroup name="companyRegistrationDistrict" id="ddlCompanyRegistrationDistrict"*/}
                {/*                    value={this.state.borrower.companyOptional.registrationDistrict}*/}
                {/*                    onChange={this.handlerChangeRegion('borrower.companyOptional.registrationDistrict')}*/}
                {/*                    required*/}
                {/*                >*/}
                {/*                    <option value="">{label.selectPlaceholder}</option>*/}
                {/*                    {regions}*/}
                {/*                </SelectGroup>*/}
                {/*            </div>*/}
                {/*            <div className="form-group">*/}
                {/*                <label htmlFor="ddlCompanyRegistrationCity"><br />{label.mailingCityLabel}</label>*/}
                {/*                <SelectGroup name="companyRegistrationCity" id="ddlCompanyRegistrationCity"*/}
                {/*                    value={this.state.borrower.companyOptional.registrationCity}*/}
                {/*                    onChange={this.handlerChange('borrower.companyOptional.registrationCity')}*/}
                {/*                    required*/}
                {/*                >*/}
                {/*                    <option value="">{label.selectPlaceholder}</option>*/}
                {/*                    {cities}*/}
                {/*                </SelectGroup>*/}
                {/*            </div>*/}
                {/*            <div className="form-group">*/}
                {/*                <label htmlFor="txtCompanyRegistrationAddress"><br />{label.businessAddressLabel}</label>*/}
                {/*                <TextInput className="form-control input-lg" id="txtCompanyRegistrationAddress" name="companyRegistrationAddress" type="text"*/}
                {/*                    maxLength="80" placeholder={label.addressPlaceholder}*/}
                {/*                    value={this.state.borrower.companyOptional.registrationAddress}*/}
                {/*                    onChange={this.handlerChange('borrower.companyOptional.registrationAddress')}*/}
                {/*                    required*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="form-group">*/}
                {/*                <label htmlFor="ddlResponseWay"><br />{label.responseWayLabel}</label>*/}
                {/*                <SelectGroup name="responseWay" id="ddlResponseWay"*/}
                {/*                    value={this.state.borrower.personOptional.responseWay}*/}
                {/*                    onChange={this.handlerChange('borrower.personOptional.responseWay')}*/}
                {/*                    required*/}
                {/*                >*/}
                {/*                    <option value="">{label.selectPlaceholder}</option>*/}
                {/*                    {responseWays}*/}
                {/*                </SelectGroup>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col-sm-4 infoBlock">*/}
                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="txtMonthlyPayment">{label.optional}</label>*/}
                {/*        <button className="preNext preNextFub nextButton" tabIndex="0" type="button" style={{ "width": "100%" }} onClick={this.handleActivateOptional}>*/}
                {/*            <span className="label">*/}
                {/*                {*/}
                {/*                    optionalActivated === false ?*/}
                {/*                        label.openOptionalFields*/}
                {/*                        :*/}
                {/*                        label.closeOptionalFields*/}
                {/*                }*/}
                {/*            </span>*/}
                {/*            <span className="touchRipple"></span>*/}
                {/*            {*/}
                {/*                optionalActivated === false ?*/}
                {/*                    <i className="fa fa-angle-down arrow-down" aria-hidden="true"></i>*/}
                {/*                    :*/}
                {/*                    <i className="fa fa-angle-up arrow-up" aria-hidden="true"></i>*/}
                {/*            }*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="row js" style={{ "display": optionalActivated === true ? "" : "none" }}>*/}
                {/*    <div className="optionalBox col-sm-9 row">*/}
                {/*        <div className="col-sm-6 infoBlock">*/}
                {/*            <div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtRegistrationNumber">{label.registrationNumberLabel}</label>*/}
                {/*                    <TextInput className="form-control input-lg" id="txtRegistrationNumber" name="registrationNumber" type="text"*/}
                {/*                        maxLength="20" placeholder={label.registrationNumberPlaceholder}*/}
                {/*                        value={this.state.borrower.companyOptional.registrationNumber}*/}
                {/*                        onChange={this.handlerChange('borrower.companyOptional.registrationNumber')}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="ddlDocumentType">{label.documentTypeLabel}</label>*/}
                {/*                    <SelectGroup name="documentType" id="ddlDocumentType"*/}
                {/*                        value={this.state.borrower.personOptional.documentType}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.documentType')}*/}
                {/*                    >*/}
                {/*                        <option value="">{label.selectPlaceholder}</option>*/}
                {/*                        {documentTypes}*/}
                {/*                    </SelectGroup>*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtPassportNumber">{label.passportNumberLabel}</label>*/}
                {/*                    <TextInput className="form-control input-lg" id="txtPassportNumber" name="passportNumber" type="text"*/}
                {/*                        maxLength="10" placeholder={label.passportNumberPlaceholder}*/}
                {/*                        value={this.state.borrower.personOptional.passportNumber}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.passportNumber')}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtPassportIssueDate">{label.passportIssueDateLabel}</label>*/}
                {/*                    <MaskWithValidation className="form-control input-lg" id="txtPassportIssueDate" name="passportIssuedBy"*/}
                {/*                        minLength={this.state.borrower.personOptional.passportIssueDate === "" ? "0" : "10"}*/}
                {/*                        maxLength="10" placeholder={label.datePlaceholder}*/}
                {/*                        value={this.state.borrower.personOptional.passportIssueDate}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.passportIssueDate')}*/}
                {/*                        errorMessage={{*/}
                {/*                            minLength: "Մուտքագրեք վավեր ամսաթիվ",*/}
                {/*                            validator: "Մուտքագրեք վավեր ամսաթիվ"*/}
                {/*                        }}*/}
                {/*                        mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}*/}
                {/*                        guide={false}*/}
                {/*                        pipe={autoCorrectedDatePipe}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtPassportIssuedBy">{label.passportIssuedByLabel}</label>*/}
                {/*                    <MaskWithValidation className="form-control input-lg" id="txtPassportIssuedBy" name="passportIssuedBy"*/}
                {/*                        maxLength="3" placeholder="xxx"*/}
                {/*                        value={this.state.borrower.personOptional.passportIssuedBy}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.passportIssuedBy')}*/}
                {/*                        errorMessage={{ validator: "Please enter a valid data" }}*/}
                {/*                        mask={simpleNumberMask}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtSocialCardNumber">{label.socialCardNumberLabel}</label>*/}
                {/*                    <MaskWithValidation className="form-control input-lg" id="txtSocialCardNumber" name="socialCardNumber"*/}
                {/*                        minLength={this.state.borrower.personOptional.socialCardNumber === "" ? "0" : "10"}*/}
                {/*                        maxLength="10" placeholder="xxxxxxxxxxxxx"*/}
                {/*                        value={this.state.borrower.personOptional.socialCardNumber}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.socialCardNumber')}*/}
                {/*                        errorMessage={{*/}
                {/*                            minLength: "Մուտքագրեք վավեր սոցիալական քարտի համարը",*/}
                {/*                            validator: "Մուտքագրեք վավեր սոցիալական քարտի համարը"*/}
                {/*                        }}*/}
                {/*                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}*/}
                {/*                        guide={false}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="ddlResidence">{label.residenceLabel}</label>*/}
                {/*                    <SelectGroup name="residence" id="ddlResidence"*/}
                {/*                        value={this.state.borrower.personOptional.residence}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.residence')}*/}
                {/*                    >*/}
                {/*                        <option value="">{label.selectPlaceholder}</option>*/}
                {/*                        {residences}*/}
                {/*                    </SelectGroup>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-sm-6 infoBlock">*/}
                {/*            <div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtHvhh"><br />{label.hvhhLabel}</label>*/}
                {/*                    <MaskWithValidation className="form-control input-lg" id="txtHvhh" name="hvhh"*/}
                {/*                        minLength={this.state.borrower.companyOptional.hvhh === "" ? "0" : "8"}*/}
                {/*                        maxLength="8" placeholder="xxxxxxxx"*/}
                {/*                        value={this.state.borrower.companyOptional.hvhh}*/}
                {/*                        onChange={this.handlerChange('borrower.companyOptional.hvhh')}*/}
                {/*                        errorMessage={{*/}
                {/*                            minLength: "Պահանջվում է նվազագույն {minLength} նիշ",*/}
                {/*                            validator: "Խնդրում ենք մուտքագրել վավեր համար"*/}
                {/*                        }}*/}
                {/*                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}*/}
                {/*                        guide={false}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtHvhhRegistrationDate">{label.hvhhRegistrationDateLabel}</label>*/}
                {/*                    <MaskWithValidation className="form-control input-lg" id="txtHvhhRegistrationDate" name="registrationDate"*/}
                {/*                        minLength={this.state.borrower.companyOptional.registrationDate === "" ? "0" : "10"}*/}
                {/*                        maxLength="10" placeholder={label.datePlaceholder}*/}
                {/*                        value={this.state.borrower.companyOptional.registrationDate}*/}
                {/*                        onChange={this.handlerChange('borrower.companyOptional.registrationDate')}*/}
                {/*                        errorMessage={{*/}
                {/*                            minLength: "Մուտքագրեք վավեր ամսաթիվ",*/}
                {/*                            validator: "Մուտքագրեք վավեր ամսաթիվ"*/}
                {/*                        }}*/}
                {/*                        mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}*/}
                {/*                        guide={false}*/}
                {/*                        pipe={autoCorrectedDatePipe}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtRegistrationAddress">{label.hvhhRegistrationAddressLabel}</label>*/}
                {/*                    <TextInput className="form-control input-lg" id="txtRegistrationAddress" name="registrationAddress" type="text" placeholder={label.addressPlaceholder}*/}
                {/*                        maxLength="150"*/}
                {/*                        value={this.state.borrower.personOptional.registrationAddress}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.registrationAddress')}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="ddlGender">{label.genderLabel}</label>*/}
                {/*                    <SelectGroup name="gender" id="ddlGender"*/}
                {/*                        value={this.state.borrower.personOptional.gender}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.gender')}*/}
                {/*                    >*/}
                {/*                        <option value="">{label.selectPlaceholder}</option>*/}
                {/*                        {genders}*/}
                {/*                    </SelectGroup>*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtMiddleName">{label.middleNameLabel}</label>*/}
                {/*                    <TextInput className="form-control input-lg" id="txtMiddleName" name="middleName" type="text"*/}
                {/*                        maxLength="20" placeholder={label.yourMiddleNamePlaceholder}*/}
                {/*                        value={this.state.borrower.personOptional.middleName}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.middleName')}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className="form-group">*/}
                {/*                    <label htmlFor="txtMailingAddress">{label.mailingAddressLabel}</label>*/}
                {/*                    <TextInput className="form-control input-lg" id="txtMailingAddress" name="mailingAddress" type="text" placeholder={label.addressPlaceholder}*/}
                {/*                        maxLength="150"*/}
                {/*                        value={this.state.borrower.personOptional.mailingAddress}*/}
                {/*                        onChange={this.handlerChange('borrower.personOptional.mailingAddress')}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="row js">
                    <div className="col-sm-4 infoBlock">
                        <Checkbox name="agreement"
                            id="agreement"
                            label={label.agreementLabel}
                            onChange={this.handlerAgreement()}
                            errorMessage={label.agreementValidationText}
                            value={this.state.uiSupport.agreementStatus}
                            required />
                    </div>
                    <AgreementModal handler={this.handlerLocalState} {...this.state} />
                </div>
            </div>
        );
    }
}

export default BorrowerOptionalInfo;
