import label from './labels';
/**
 * Provided by http://malsup.com/jquery/block/#download
 */

/**
 * 
 */
export function unblockUI() {
    global.jQuery.unblockUI();
}

/**
 * 
 */
export function blockUIStyle() {
    global.jQuery.blockUI({
        css: {
            border: 'none',
            padding: '15px',
            backgroundColor: '#000',
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',
            opacity: .6,
            color: '#fff'
        },
        overlayCSS: {
            backgroundColor: '#ccc',
            opacity: 0.3,
            cursor: 'wait'
        },
        baseZ: 31000
    });
}

/**
 * 
 */
export function blockUIStyleUploading() {
    blockUIStyle();
    global.jQuery.blockUI({
        css: {
            border: 'none',
            padding: '15px',
            backgroundColor: '#000',
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',
            opacity: .6,
            color: '#fff'
        },
        overlayCSS: {
            backgroundColor: '#ccc',
            opacity: 0.3,
            cursor: 'wait'
        },
        message: label.pleaseWait
    });
}

export function blockUIError(message) {

    global.jQuery('#messageErrorValid').text(message);

    setTimeout(function() {
        let content = global.jQuery('#pastDueWarning');

        global.jQuery.blockUI({ 
            message: content, 
            css: { 
                width: '410px',
                border: '1px solid #e64100',
                borderRadius: '25px',
            } 
        }, 1500); 
    })    
}

export function showEffectiveRate()
{

}
export function hideEffectiveRate()
{
    global.jQuery(".effective-rate").hide();
}

export function agreementModal() {
    // global.jQuery('.modalHeaderContainer').text(label.acraLabel);
    // global.jQuery('.modalMessagesContainer').text(label.acraAgreementDesc);

    setTimeout(function() {
        let content = global.jQuery('#modal');

        global.jQuery.blockUI({ 
            message: content, 
            css: { 
                width:          '60%', 
                top:            '10%', 
                left:           '20%', 
                border: '2px solid #36c265',
                borderRadius: '15px',
            }
        }, 1500); 
    })
}

export function termsAgreementModal() {
    setTimeout(function() {
        let content = global.jQuery('#modalTerms');

        global.jQuery.blockUI({ 
            message: content, 
            css: { 
                width:          '90%', 
                top:            '10%', 
                left:           '5%', 
                border: '2px solid #36c265',
                borderRadius: '15px',
            }
        }, 1500); 
    })
}