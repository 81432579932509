import label from './labels';
import { unblockUI, blockUIError } from './utility';

//const url = "https://localhost:44375/api/Info/";
//const urlPost = "https://localhost:44375/api/Clients/";

const url = "https://online.mikrokapital.am:8081/api/info/";
const urlPost = "https://online.mikrokapital.am:8081/api/Clients/";

// CALL ALL FUNCTIONS HERE AND LOAD UP LISTS

/* Response anotation

"message","resultCode","result"
resultCode = 1 - OK
resultCode = 2 - Failed
resultCode = 3 - Validation errormessage
*/


/**
 * Sub Economic Activity Types
 * @param {*} economicId
 */
export function loadSubEconomicActivityTypes(economicId) {
    let xhr = new XMLHttpRequest();
    // xhr.open('GEt', url + 'SubEconomicActivityTypes?economicId=' + economicId, true);
    xhr.open('GEt', url + 'SubEconomicActivityTypes', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.subEconomicActivityTypes = data.result;
            }
        }
    };
    xhr.send();
}

/**
 * Economic Activity Experience Types
 */
export function loadEconomicActivityExperienceTypes() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'EconomicActivityExperienceTypes', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.economicActivityExperienceTypes = data.result;
                label.lists.economicActivityExperienceTypesPart = [data.result[0], data.result[1]];
            }
        }
    };
    xhr.send();
};

export function loadProductTypes() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'ProductTypes', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.productTypes = data.result;
            }
        }
    };
    xhr.send();
};
/**
 * Loan Purposes
 */
export function loadLoanPurposes() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'LoanPurposes', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.loanPurposes = data.result;
            }
        }
    };
    xhr.send();
};

/**
 * Information Source Types
 */
export function loadInformationSourceTypes() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'InformationSourceTypes', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.informationSourceTypes = data.result;
            }
        }
    };
    xhr.send();
};

/**
 * Document Types
 */
export function loadDocumentTypes() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'DocumentTypes', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.documentTypes = data.result;
            }
        }
    };
    xhr.send();
};

/**
 * Document Statuses
 */
export function loadDocumentStatuses() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'DocumentStatuses', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.documentStatuses = data.result;
            }
        }
    };
    xhr.send();
};

/**
 * Currencies
 */
export function loadCurrencies() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'Currencies', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.currencies = data.result;
            }
        }
    };
    xhr.send();
};

/**
 * Address Types
 */
export function loadAddressTypes() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'AddressTypes', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.addressTypes = data.result;
            }
        }
    };
    xhr.send();
};

/**
 * Countries
 */
export function loadCountries() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'Countries', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.countries = data.result;
            }
        }
    };
    xhr.send();
};

/**
 * Regions
 * @param {*} countryId
 */
export function loadRegions(countryId) {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'Regions?countryId=' + countryId, true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.regions = data.result;
            }
        } else {
            label.lists.regions = [];
        }
    };
    xhr.send();
};

/**
 * Cities
 * @param {*} region
 */
export function loadCities(region) {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'Cities?region=' + region, true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.cities = data.result;
            }
        } else {
            label.lists.cities = [];
        }
    };
    xhr.send();
};

/**
 * Cities
 * @param {*} region
 */
export function loadRegionCities(region, self) {
    if (region !== "") {
        let xhr = new XMLHttpRequest();
        xhr.open('GEt', url + 'Cities?region=' + region, true);
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.setRequestHeader('Content-type', 'application/json');
        // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
        xhr.onload = function () {
            if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
                let data = JSON.parse(xhr.responseText);
                if (data.result !== null) {
                    label.lists.cities = data.result;
                    setTimeout(function () {
                        self.forceUpdate();
                    }, 100)
                }
            } else {
                label.lists.cities = [];
                setTimeout(function () {
                    self.forceUpdate();
                }, 100)
            }
        };
        xhr.send();
    } else {
        label.lists.cities = [];
        setTimeout(function () {
            self.forceUpdate();
        }, 100)
    }
};

/**
 * Districts
 * @param {*} cityId
 */
export function loadDistricts(cityId) {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'Districts?cityId=' + cityId, true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.districts = data.result;
            }
        } else {
            label.lists.districts = [];
        }
    };
    xhr.send();
};

/**
 * Generate Year of Birth
 */
export function loadYearOfBirth() {
    if (label.lists.yearOfBirth.length === 0) {
        var dt = new Date();
        for (let x = dt.getFullYear() - 65; x < dt.getFullYear() - 20; x++) {
            label.lists.yearOfBirth.push(x);
        }
    }
}

export function loadBusinessAdvisers() {
    let xhr = new XMLHttpRequest();
    xhr.open('GEt', url + 'BusinessAdvisers', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null) {
            let data = JSON.parse(xhr.responseText);
            if (data.result !== null) {
                label.lists.businessAdvisers  = data.result;
//                label.lists.economicActivityExperienceTypesPart = [data.result[0], data.result[1]];
            }
        }
    };
    xhr.send();
};

/**
 * Save Guarantor's information
 * @param {pointer} self
 */
export function sendRegisterClient(self) {
    let files = [];

    if (self.state.guarantor.attachments.incomeInfo !== "")
        self.state.guarantor.attachments.incomeInfo.forEach((file, i) => {
            files.push({
                "fileName": file.fileName,
                "fileType": file.fileType,
                "fileContent": file.fileContent,
                "fileContentType": 16
            });
        });
    if (self.state.guarantor.attachments.socialCard !== "")
        self.state.guarantor.attachments.socialCard.forEach((file, i) => {
            files.push({
                "fileName": file.fileName,
                "fileType": file.fileType,
                "fileContent": file.fileContent,
                "fileContentType": 1
            });
        });

    if (self.state.guarantor.attachments.passportOrId !== "")
        self.state.guarantor.attachments.passportOrId.forEach((file, i) => {
            files.push({
                "fileName": file.fileName,
                "fileType": file.fileType,
                "fileContent": file.fileContent,
                "fileContentType": 1
            });
        });

    if (self.state.guarantor.attachments.realEstateCertificate !== "")
        self.state.guarantor.attachments.realEstateCertificate.forEach((file, i) => {
            files.push({
                "fileName": file.fileName,
                "fileType": file.fileType,
                "fileContent": file.fileContent,
                "fileContentType": 17
            });
        });

    let xhr = new XMLHttpRequest();
    xhr.open('POST', urlPost + 'RegisterClient', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 0) {
            unblockUI();
        }
    };
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null && xhr.responseText !== "") {
            let data = JSON.parse(xhr.responseText);
            //{"messages":[],"resultCode":0,"result":3074} - normal
            //{"messages":[message],"resultCode":1,"result":0} - failed
            //{"messages":[message],"resultCode":2,"result":0} - validation failed
            if (data.result !== null && data.resultCode === 0) {
                setTimeout(function () {
                    // self.forceUpdate();
                    let clone = { ...self.state };
                    clone.page = "send";
                    self.setState({ ...clone }, () => self.handler(self.state));
                }, 100)
            }
        } else {
            setTimeout(function () {
                // self.forceUpdate();
            }, 100)
        }
        unblockUI();
    };

    var data = JSON.stringify({
        "id": 0,
        "branchCode": 100,
        "clientType": 1,
        "status": 1,
        "firstName": self.state.guarantor.personalInfo.firstName,
        "lastName": self.state.guarantor.personalInfo.lastName,
        "sureName": "",
        "residence": 0,
        "gender": 0,
        // "dateOfBirth": "1900-01-01T05:46:43.088Z",
        "name": "",
        "contacts": [{
            "id": 0,
            "contactType": 1,
            "contactValue": self.state.guarantor.personalInfo.phoneNumber.replace("+374", "").replace(/ /g, ""),
            "countryPhoneCode": "+374",
            "comment": ""
        }],
        "files": files,
        "comment": self.state.guarantor.guaranteeInfo.companyName + ", " + self.state.guarantor.guaranteeInfo.firstName + ", " + self.state.guarantor.guaranteeInfo.lastName
    });
    xhr.send(data);
};

/**
 * Save Client's information
 * @param {pointer} self
 */
export function sendRegisterClientAndLoan(self) {
    let files = [];

    let documents = getDocuments(self.state.borrower, self.state.borrower.attachments);

    if (self.state.borrower.attachments.businessLogo !== "")
        self.state.borrower.attachments.businessLogo.forEach((file, i) => {
            files.push({
                "fileName": file.fileName,
                "fileType": file.fileType,
                "fileContent": file.fileContent,
                "fileContentType": 14
            });
        });

    if (self.state.borrower.attachments.businessRules !== "")
        self.state.borrower.attachments.businessRules.forEach((file, i) => {
            files.push({
                "fileName": file.fileName,
                "fileType": file.fileType,
                "fileContent": file.fileContent,
                "fileContentType": 15
            });
        });

    // This block added to reorganize files
        if (self.state.borrower.attachments.passportOrId !== "")
            self.state.borrower.attachments.passportOrId.forEach((file, i) => {
                files.push({
                    "fileName": file.fileName,
                    "fileType": file.fileType,
                    "fileContent": file.fileContent,
                    "fileContentType": 6 // 6->2, 7->3
                });
            });

        if (self.state.borrower.attachments.socialCard !== "")
            self.state.borrower.attachments.socialCard.forEach((file, i) => {
                files.push({
                    "fileName": file.fileName,
                    "fileType": file.fileType,
                    "fileContent": file.fileContent,
                    "fileContentType": 9
                });
            });
        if (self.state.borrower.borrowerType !== "personal" && self.state.borrower.attachments.certificate !== "")
            self.state.borrower.attachments.certificate.forEach((file, i) => {
                files.push({
                    "id": 0,
                    "fileName": file.fileName,
                    "fileType": file.fileType,
                    "description": "",
                    "quality": 0,
                    "fileContent": file.fileContent,
                    "fileContentType": 12
                });
            });

    // Block ends here

    let xhr = new XMLHttpRequest();
    xhr.open('POST', urlPost + 'RegisterClientAndLoan', true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-selfs', 'POST');
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 0) {
            unblockUI();
        }
    };
    xhr.onload = function () {
        if (typeof xhr !== 'undefined' && xhr.responseText !== null && xhr.responseText !== "") {
            let data = JSON.parse(xhr.responseText);
            //{"messages":[],"resultCode":0,"result":3074} - normal
            //{"messages":[message],"resultCode":1,"result":0} - failed
            //{"messages":[message],"resultCode":2,"result":0} - validation failed
            if (data.result !== null && data.resultCode === 0) {
                setTimeout(function () {
                    // self.forceUpdate();
                    let clone = { ...self.state };
                    clone.page = "send";
                    self.setState({ ...clone }, () => self.handler(self.state));
                    if (typeof data.messages !== 'undefined' && data.messages !== '' && data.messages.length > 0)
                        blockUIError(data.messages);
                    else
                        unblockUI();
                }, 100)
            } else {
                if (typeof data.messages !== 'undefined' && data.messages !== '')
                    blockUIError(data.messages);
                else
                    unblockUI();
            }
        } else {
            setTimeout(function () {
                unblockUI();
            }, 100)
        }
    };

    var data = JSON.stringify({
        "clientData": {
            "id": 0,
            "branchCode": 100,
            "clientType": self.state.borrower.borrowerType === "personal" ? 1 : (self.state.borrower.borrowerType === "corp" ? 2 : 3),
            "status": 1,
            "firstName": self.state.borrower.personalInformation.firstName,
            "lastName": self.state.borrower.personalInformation.lastName,
            "sureName": self.state.borrower.personOptional.middleName,
            "residence": self.state.borrower.personOptional.residence === "" ? 0 : self.state.borrower.personOptional.residence,
            "gender": self.state.borrower.personOptional.gender === "" ? 0 : self.state.borrower.personOptional.gender,
            "dateOfBirth": "1900-1-1",

            "name": self.state.borrower.borrowerType === "corp" ? self.state.borrower.personalInformation.companyName : self.state.borrower.personalInformation.firstName + " " + self.state.borrower.personalInformation.lastName,
            "addresses": addresses(self.state.borrower),
            "contacts": [{
                "id": 0,
                "contactType": 2,
                "contactValue": self.state.borrower.personalInformation.phoneNumber.replace("+374", "").replace(/ /g, ""),
                "countryPhoneCode": "+374",
                "comment": self.state.borrower.borrowerType === "corp" ? self.state.borrower.personalInformation.firstName + " " + self.state.borrower.personalInformation.lastName + " " + self.state.borrower.personOptional.responseWay : self.state.borrower.personOptional.responseWay
            }],
            "documents": documents,
            "economicActivities": [{
                "id": 0,
                "sysEconomicActivityType": 16,
                "economicActivityType": "16",
                "subEconomicActivityType": "16",
                "isMain": false,
                "experience": "1",
                "managingPersons": 0
            }],
            "files": files,
            "comment": ""
        },
        "loanApplication": {
            "id": 0,
            "clientID": 0,
            "businessAdvisor":self.state.borrower.businessAdvisor,
            "productId": self.state.borrower.borrowerType==="personal"?"34":self.state.borrower.productTypes,
            "branchCode": 100,
            "informationSource": 16,
            "appliedDate": new Date(),
            "appliedAmount": self.state.borrower.businessMainInformation.amount.replace(/,/g, ''),
            "appliedTerm": self.state.borrower.businessMainInformation.period,
            "purpose": "9",
            "paymentAmount": "1",
            "additionalFields": [],
            "files": []
        }
    });

    xhr.send(data);
}

function concatenateDate(obj) {
    // format YYYY-MM-DD
    return obj.year + "-" + obj.month + "-" + obj.day;

}

function addresses(obj) {

    let addresses = [];

    addresses.push({
        "id": 0,
        "addressType": obj.borrowerType === "personal" ? 5 : 4,
        "street": ".",
        "region": "1",
        "city": "1",
        "dstrict": 0,
        "country": 7
    });

    if (obj.personOptional.registrationAddress !== "") {
        addresses.push({
            "id": 0,
            "addressType": obj.borrowerType === "personal" ? 1 : 3,
            "street": obj.personOptional.registrationAddress,
            "region": 0,
            "city": 0,
            "dstrict": 0,
            "country": 7
        });
    }

    if (obj.personOptional.mailingAddress !== "" && obj.borrowerType === "personal") {
        addresses.push({
            "id": 0,
            "addressType": 2,
            "street": obj.personOptional.mailingAddress,
            "region": 0,
            "city": 0,
            "dstrict": 0,
            "country": 7
        })
    }

    return addresses;
}

function getDocuments(obj, attachments) {

    let returnValue = [];

    if (obj.borrowerType === "personal") {
        if (obj.personOptional.documentType === "2") { // Passport

            let files = [];
            // if (attachments.passportOrId !== "")
            //     attachments.passportOrId.forEach((file, i) => {
            //         files.push({
            //             "fileName": file.fileName,
            //             "fileType": file.fileType,
            //             "fileContent": file.fileContent,
            //             "fileContentType": 6 // 6->2, 7->3
            //         });
            //     });

            returnValue.push({
                "id": 0,
                "type": obj.personOptional.documentType,
                "number": obj.personOptional.passportNumber,
                "issuer": obj.personOptional.passportIssuedBy,
                "dateOfIssue": formatDate(obj.personOptional.passportIssueDate, 0), // "2019-05-09T03:37:42.199Z"
                "dateOfExpiry": formatDate(obj.personOptional.passportIssueDate, 10), // "2019-05-09T03:37:42.199Z", + 10
                "status": 1,
                "files": files
            });
        } else if (obj.personOptional.documentType === "3") { // ID Card

            let files = [];
            // if (attachments.passportOrId !== "")
            //     attachments.passportOrId.forEach((file, i) => {
            //         files.push({
            //             "fileName": file.fileName,
            //             "fileType": file.fileType,
            //             "fileContent": file.fileContent,
            //             "fileContentType": 7 // 6->2, 7->3
            //         })
            //     });

            returnValue.push({
                "id": 0,
                "type": obj.personOptional.documentType,
                "number": obj.personOptional.passportNumber,
                "issuer": obj.personOptional.passportIssuedBy,
                "dateOfIssue": formatDate(obj.personOptional.passportIssueDate, 0), // "2019-05-09T03:37:42.199Z"
                "dateOfExpiry": formatDate(obj.personOptional.passportIssueDate, 10), // "2019-05-09T03:37:42.199Z", + 10
                "status": 1,
                "files": files
            });
        }

        if (obj.personOptional.socialCardNumber !== "") {

            let files = [];
            // if (attachments.socialCard !== "")
            //     attachments.socialCard.forEach((file, i) => {
            //         files.push({
            //             "fileName": file.fileName,
            //             "fileType": file.fileType,
            //             "fileContent": file.fileContent,
            //             "fileContentType": 9
            //         });
            //     });

            returnValue.push({
                "id": 0,
                "type": 15,
                "number": obj.personOptional.socialCardNumber,
                "issuer": "",
                "dateOfIssue": "",
                "dateOfExpiry": "",
                "status": 1,
                "files": files
            });
        }

    } else {
        if (obj.companyOptional.registrationNumber !== "") {

            let files = [];
            // if (attachments.certificate !== "")
            //     attachments.certificate.forEach((file, i) => {
            //         files.push({
            //             "id": 0,
            //             "fileName": file.fileName,
            //             "fileType": file.fileType,
            //             "description": "",
            //             "quality": 0,
            //             "fileContent": file.fileContent,
            //             "fileContentType": 12
            //         });
            //     });

            returnValue.push({
                "id": 0,
                "type": 17,
                "number": obj.companyOptional.registrationNumber,
                "issuer": "",
                "dateOfIssue": formatDate(obj.companyOptional.registrationDate, 0),
                "dateOfExpiry": "",
                "status": 1,
                "files": files
            });
        }

        if (obj.companyOptional.hvhh !== "") {

            returnValue.push({
                "id": 0,
                "type": 18,
                "number": obj.companyOptional.hvhh,
                "issuer": "",
                "dateOfIssue": "",
                "dateOfExpiry": "",
                "status": 1,
                "files": []
            });
        }
    }

    return returnValue;
}

function formatDate(date, roll) {

    let returnValue = "";

    if (date.length === 10) {
        const dateAparts = date.split(".");
        returnValue = (parseInt(dateAparts[2], 10) + roll) + "-" + dateAparts[1] + "-" + dateAparts[0];
    }

    return returnValue
}
