import React from "react";
import "./Terms.css";
import { green } from "@material-ui/core/colors";

class TermsTetevacum extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
        <div>
        <div className="tHead"> «ՀԱՏՈՒԿ ԱՌԱՋԱՐԿ»</div>
        <div className="tHead">
Ուժի մեջ է 20.10.2021թ - 31.12.2021թ․ <br/>
«ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ <br/>
ՀԱՍՏԱՏՎԱԾ Է ՎԱՐՉՈՒԹՅԱՆ ԹԻՎ 11/21 ՈՐՈՇՄԱՄԲ
        </div>
        
        
        <h2> ԲԻԶՆԵՍ ՎԱՐԿ «ԹԵԹԵՎԱՑՈՒՄ» <br/>
        ՎԱՐԿԱՏԵՍԱԿԻ ՆԿԱՐԱԳԻՐԸ ԵՎ ՀԻՄՆԱԿԱՆ ԲՆՈՒԹԱԳՐԵՐԸ
        </h2>
        <div className="termsContainer">
          <table className="termsTable mainDesc">
            <tbody>
              <tr>
                <td colSpan="1"></td>
                <td colSpan="2">ՉԱՓԱՆԻՇՆԵՐ</td>
                <td colSpan="2">ՀԻՄՆԱԿԱՆ ԲՆՈՒԹԱԳՐԵՐ</td>
              </tr>
              <tr>
                <td colSpan="1">1</td>
                <td colSpan="2">Վարկառուի նկարագիր</td>
                <td colSpan="2">
                ՀՀ ռեզիդենտ իրավաբանական անձինք և անհատ ձեռնարկատերեր, որոնք ունեն եկամուտներ ձեռնարկատիրական (բիզնես) գործունեությունից:
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td colSpan="2">Վարկառուին/երաշխավորին ներկայացվող նվազագույն պահանջներ</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Վարկառուի տարիքը 21 – 65 տարեկան` վարկային պայմանագրի
                      ժամկետի ավարտի պահին, ընդ որում 21 – 27 տարեկան արական
                      սեռի վարկառուների համար պարտադիր պայման է հանդիսանում
                      զինվորական գրքույկի առկայությունը, որը կհավաստի զինվորական
                      ծառայության ավարտը (հակառակ դեպքում` ֆինանսական երաշխավորի
                      կամ ընտանիքի անդամի երաշխավորության առկայություն),
                    </li>
                    <li>
                      «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ վճարունակության
                      գնահատման ներքին չափանիշներին համապատասխանող վարկառուի
                      ձեռնարկատիրական (բիզնես) գործունեության առկայություն`
                      առնվազն 12 (տասներկու) ամիս,
                    </li>
                    <li>
                      Վարկառուի և երաշխավորների դրական վարկային պատմություն՝
                      «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ–ի ներքին չափանիշներին
                      համապատասխան,
                    </li>
                    <li>
                      Ֆինանսական երաշխավորներ կարող են համարվել
                      ֆիզիկական/իրավաբանական անձիք, ովքեր ունեն փաստաթղթորեն հիմնավորված կայուն եկամուտ
                      կամ անշարժ գույք, որը համարժեք է վարկի գումարին,
                    </li>
                    <li>
                      Վարկառուի և երաշխավորների համապատասխանություն «Միկրո
                      Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ - ի վարկային քաղաքականությանը և
                      ներքին կանոնակարգերով սահմանված այլ չափանիշներին:
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">3</td>
                <td colSpan="2">Վարկի նպատակ</td>
                <td colSpan="2">
                  <ul>
                    <li>Հաճախորդի՝ ՀՀ ֆինանսական կազմակերպություններում առկա վարկային պարտավորությունների մարում:</li>
                    <li>Հաճախորդի ձեռնարկատիրական գործունեության հետ կապված այլ նպատակներ:</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">4</td>
                <td colSpan="2">Վարկի արժույթ</td>
                <td colSpan="2">ՀՀ դրամ, ԱՄՆ դոլար</td>
              </tr>
              <tr>
                <td colSpan="1">5</td>
                <td colSpan="2">
                  Վարկի տրամադրման նվազագույն և առավելագույն սահմանաչափեր
                </td>
                <td colSpan="2">
                    <ul>
                        <li>1,000,000  – 10,000,000 ՀՀ դրամ</li>
                        <li>2,000 – 20,000 ԱՄՆ դոլար</li>
                    </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">6</td>
                <td colSpan="2">
                    Վարկի նվազագույն և առավելագույն ժամկետներ
                </td>
                <td colSpan="2">12 – 60 ամիս</td>
              </tr>
              <tr>
                <td colSpan="1">7</td>
                <td colSpan="2">Մարման ժամանակացույց</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Անուիտետային վճարումներ – հավասարաչափ վճարումներ, որի
                      դեպքում վարկի ամբողջ ժամանակահատվածի ընթացքում
                      յուրաքանչյուր ամիս հաճախորդը կատարում է միևնույն չափի
                      վճարումներ,
                    </li>
                    <li>
                      Հավասարաչափ վճարումներ – հաճախորդը վճարում է յուրաքանչյուր
                      ամիս հավասարաչափ մայր գումար, որի արդյունքում
                      տոկոսավճարների հանրագումարը ամեն ամիս նվազում է
                    </li>
                    <li>
                      Ճկուն մարման ժամանակացույց – վարկի մայր գումարի մարումները
                      բաշխվում են մարման ժամանակաշրջանի վրա անհավասարաչափ
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">8</td>
                <td colSpan="2">Արտոնյալ ժամկետ մայր գումարի համար</td>
                <td colSpan="2">
                  Առավելագույնը 6 ամիս
                </td>
              </tr>
              <tr>
                <td colSpan="1">9</td>
                <td colSpan="2">Վարկի տրամադրման եղանակ</td>
                <td colSpan="2">
                Վարկը տրամադրվում է անկանխիկ եղանակով՝ Վարկառուի բանկային հաշվին կամ «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ գործընկեր բանկում առկա համապատասխան հաշվին փոխանցում կատարելու միջոցով կամ բանկային չեկի/հանձնարարականի միջոցով, իսկ գործող պարտավորությունների մարման մասով՝ համապատասխան ֆինանսական կազմակերպություն փոխանցում կատարելու միջոցով:
                </td>
              </tr>
              <tr>
                <td colSpan="1">10</td>
                <td colSpan="2">Վաղաժամկետ մարում</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Վարկառուն իրավունք ունի վաղաժամկետ ամբողջական կամ մասնակի
                      մարելու վարկը և կատարելու պայմանագրով սահմանված այլ
                      պարտականությունները առանց տույժ վճարելու,
                    </li>
                    <li>
                      Վարկի վաղաժամկետ ամբողջական մարման պարագայում վարկառուն
                      վճարում է վճարման օրվա դրությամբ հաշվարկված վարկի
                      սպասարկման և այլ վճարները, տոկոսները և վարկի մայր գումարը,
                    </li>
                    <li>
                      Վարկի վաղաժամկետ մասնակի մարման պարագայում գումարը
                      ուղղվում է վարկի մայր գումարի մարմանը և համամասնորեն
                      նվազեցվում են վարկի հետագա ամսվա (ամիսների) մարման ենթակա
                      տոկոսագումարները, եթե հաճախորդը չի տրամադրել այլ
                      հանձնարարական:
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">11</td>
                <td colSpan="2">
                  Զուգահեռ վարկեր վարկառուների խմբին/փոխկապակցված անձանց տվյալ
                  վարկատեսակի շրջանակներում
                </td>
                <td colSpan="2">
                  <ul>
                    <li>Զուգահեռ վարկերի առավելագույն քանակ - 3</li>
                    <li>
                      Զուգահեռ վարկերի առավելագույն գումարը – 10,000,000 ՀՀ դրամ
                      կամ 20,000 ԱՄՆ դոլար
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">12</td>
                <td colSpan="2">Տրանշեր</td>
                <td colSpan="2">-</td>
              </tr>
              <tr>
                <td colSpan="1">13</td>
                <td colSpan="2">Ապահովվածություն (նվազագույն)</td>
                <td colSpan="2">
                  <ul>
                    <li>3-րդ անձանց երաշխավորություն</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">14</td>
                <td colSpan="2">Վճարունակության գնահատում</td>
                <td colSpan="2">
                  <ul>
                    <li>հաճախորդի սոցիալական բնութագիր, </li>
                    <li>հաճախորդի վարկային պատմություն,</li>
                    <li>իրավական ռիսկեր,</li>
                    <li>Ֆինանսատնտեսական վերլուծություն, համաձայն վարկատեսակի գծով  ֆինանսատնտեսական վերլուծության ուղեցույցի</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">15</td>
                <td colSpan="2">Վարկերի դիտարկում (հաստատում/մերժում)</td>
                <td colSpan="2">
                    Համաձայն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ Վարկավորման ընթացակարգով սահմանված որոշումների կայացման իրավասությունների
                </td>
              </tr>
              <tr>
                <td colSpan="1">16</td>
                <td colSpan="2">Որոշման կայացման ժամկետ</td>
                <td colSpan="2">
                1 աշխատանքային օր (հաճախորդի կողմից ամբողջական փաթեթի առկայության դեպքում)
                </td>
              </tr>
              <tr>
                <td colSpan="1">17</td>
                <td colSpan="2">Որոշման մասին տեղեկացնելու ժամկետ</td>
                <td colSpan="2">1 աշխատանքային օր</td>
              </tr>
              <tr>
                <td colSpan="1">18</td>
                <td colSpan="2">Վարկը տրամադրելու ժամկետ</td>
                <td colSpan="2">
                1 աշխատանքային օր (վարկի ձևակերպման համար անհրաժեշտ փաստաթղթերը ներկայացնելու դեպքում)
                </td>
              </tr>
              <tr>
                <td colSpan="1">19</td>
                <td colSpan="2">Վարկի ձևակերպման վայրը</td>
                <td colSpan="2">
                «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ գրասենյակներում կամ «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ հետ համագործակցող/գործընկեր կազմակերպության գործունեության վայրում
                </td>
              </tr>
              <tr>
                <td colSpan="1">20</td>
                <td colSpan="2">Վարկային հայտի մերժման հնարավոր պատճառներ</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      սահմանված նվազագույն պահանջներին չբավարարման դեպքում (1 –
                      3 կետեր)
                    </li>
                    <li>
                      հաճախորդի կողմից անհրաժեշտ փաստաթղթերի և այլ
                      հիմնավորումների չներկայացման, կեղծ փաստաթղթերի կամ
                      տեղեկատվության ներկայացման դեպքում
                    </li>
                    <li>
                      վարկային կոմիտեն չի բավարարել վարկային հայտը` հաշվի
                      առնելով գործարքի ռիսկայնությունը կամ առկա այլ ռիսկերը,
                      վարկային կոմիտեի իրավասությամբ սահմանված այլ դեպքերում,
                      այդ թվում.
                      <ul>
                        <li>դրական վարկային պատմության բացակայություն,</li>
                        <li>իրավական ռիսկերի առկայություն,</li>
                        <li>
                          «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ կողմից
                          չֆինանսավորվող ոլորտների ցանկին համապատասխանություն,
                        </li>
                        <li>ֆինանսական ցուցանիշների անբավարարություն</li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">21</td>
                <td colSpan="2">Մոնիթորինգ</td>
                <td colSpan="2">
                Նպատակային մոնիթորինգը միայն հաճախորդի՝ ՀՀ ֆինանսական կազմակերպություններում գործող վարկային պարտավորությունների մարմանն ուղղվող գումարի մասով։<br/>
ՀՀ ֆինանսական կազմակերպություններում գործող վարկային պարտավորությունների մարման հավաստումը վարկային միջոցների տրամադրումից 5 (հինգ)աշխատանքային օրվա ընթացքում։  
                </td>
              </tr>
              <tr>
                <td colSpan="1">22</td>
                <td colSpan="2">Անհրաժեշտ փաստաթղթերի ցանկ</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Հաճախորդի (առկայության դեպքում կազմակերպության
                      հիմնադիրների, կոլեգիալ կամ միանձնյա գործադիր մարմնի
                      (տնօրենի, գլխավոր տնօրենի, գործադիր տնօրենի և այլն),
                      բաժնետերերի և երաշխավորների) անձնագիր և սոցիալական քարտ
                      կամ նույնականացման քարտ,
                    </li>
                    <li>Պետական գրանցման կամ հաշվառման վկայական, ներդիր,</li>
                    <li>ՀՎՀՀ,</li>
                    <li>Կանոնադրություն (իրավաբանական անձանց դեպքում),</li>
                    <li>
                      Լիազորված մարմնի որոշում վարկի համար դիմելու վերաբերյալ
                      (անհրաժեշտության դեպքում),
                    </li>
                    <li>	Վերջին 12 (տասներկու) ամսվա հարկային հաշվետվություններ,</li>
                    <li>Այլ փաստաթղթեր (Վարկային Կոմիտեի պահանջով)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">23</td>
                <td colSpan="2">
                  Տարեկան տոկոսադրույք և վարկատեսակի այլ պայմաններ
                </td>
                <td colSpan="2">
                Վարկատեսակի տոկոսադրույքը, տրամադրման վճարը և այլ պայմանները սահմանվում են «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ լիազոր մարմնի որոշմամբ սահմանված վարկատեսակների պայմաններով և սակագներով, որի մասին հաճախորդները ծանուցվում են կազմակերպության բոլոր տարածքային ստորաբաժանումներում, ինչպես նաև պայմանները նկարագրող ինֆորմացիոն թերթիկների և կազմակերպության ինտերնետային կայքի միջոցով
                </td>
              </tr>
              <tr>
                <td colSpan="1">24</td>
                <td colSpan="2">Այլ պայմաններ</td>
                <td colSpan="2">
                1 (մեկ) հաճախորդին տրամադրված առանց գրավ վարկերի ընդհանուր մնացորդը բոլոր վարկատեսակների գծով չի կարող գերազանցել 10,000,000 ՀՀ դրամը (համարժեք – 20,000 ԱՄՆ դոլար)
                </td>
              </tr>
              <tr>
                <td colSpan="1">ԶԳՈՒՇԱՑՈՒՄ</td>
                <td colSpan="4">
                  <ul>
                  <li>
                        ԱՄՆ ԴՈԼԱՐՈՎ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԵՐԻ ՊԱՐԱԳԱՅՈՒՄ ԱՐՏԱՐԺՈՒՅԹԻ ՓՈԽԱՐԺԵՔԻ ՓՈՓՈԽՈՒԹՅՈՒՆՆԵՐԸ ԿԱՐՈՂ ԵՆ ԱԶԴԵՑՈՒԹՅՈՒՆ ՈՒՆԵՆԱԼ ՎԱՐԿԻ ՄԱՐՄԱՆ ԳՈՒՄԱՐՆԵՐԻ ՎՐԱ,
                    </li>
                    <li>
                        ԱՄՆ ԴՈԼԱՐՈՎ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԵՐԻ ՊԱՐԱԳԱՅՈՒՄ ԱՐՏԱՐԺՈՒՅԹԻ ՓՈՓՈԽՈՒԹՅԱՆ ԳՈՐԾՈՆԸ ՉԻ ՆԵՐԱՌՎՈՒՄ ՎԱՐԿԻ ՏՐԱՄԱԴՐՄԱՆ ԺԱՄԱՆԱԿ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՄԱՆ ՄԵՋ, ՀԵՏԵՎԱԲԱՐ ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԸ ԿԱՐՈՂ Է ՓՈՓՈԽՎԵԼ՝ ԿԱԽՎԱԾ ՀԱՅԱՍՏԱՆԻ ՀԱՆՐԱՊԵՏՈՒԹՅԱՆ ԿԵՆՏՐՈՆԱԿԱՆ ԲԱՆԿԻ ՊԱՇՏՈՆԱԿԱՆ ԿԱՅՔ ԷՋՈՒՄ ՀՐԱՊԱՐԱԿՎԱԾ ՓՈԽԱՐԺԵՔԻ ՄԵԾՈՒԹՅԱՆ ՓՈՓՈԽՈՒԹՅՈՒՆԻՑ,
                    </li>
                    <li>
                        ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՎԱՐԿԻ ՄԱՅՐ ԳՈՒՄԱՐԻ, ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ ԵՎ ՍՊԱՍԱՐԿՄԱՆ ՎՃԱՐՆԵՐԻ ՄԱՐՈՒՄՆԵՐԸ ԺԱՄԱՆԱԿԻՆ ԵՎ ՊԱՏՇԱՃ ՉԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ ՎԱՐԿԱՌՈՒԻ ԵՎ ԵՐԱՇԽԱՎՈՐԻ ՄԱՍԻՆ ՀԱՄԱՊԱՏԱՍԽԱՆ ՏԵՂԵԿԱՏՎՈՒԹՅՈՒՆԸ ԿԳՐԱՆՑՎԻ ԿԲ ՎԱՐԿԱՅԻՆ ՌԵԳԻՍՏՐՈՒՄ ԵՎ «ԱՔՌԱ» ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈՅԻ ՌԵԳԻՍՏՐՈՒՄ,
                    </li>
                    <li>
                        ՎԱՐԿԱՌՈՒՆ ԻՐ ՊԱՅՄԱՆԱԳՐԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ ՆՅՈՒԹԱԿԱՆ ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ Է ԿՐՈՒՄ ՆԱԵՎ ՍԵՓԱԿԱՆՈՒԹՅԱՆ ԻՐԱՎՈՒՆՔՈՎ ԻՐԵՆ ՊԱՏԿԱՆՈՂ ՑԱՆԿԱՑԱԾ ԱՅԼ ԳՈՒՅՔՈՎ (ՏՈՒՆ, ԲՆԱԿԱՐԱՆ, ԱՎՏՈՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԵՎ ԱՅԼՆ), ԵԹԵ ԱՌԿԱ ԳՐԱՎԻ ԱՌԱՐԿԱՆ ԲԱՎԱՐԱՐ ՉԷ «ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ – Ի ՆԿԱՏՄԱՄԲ ՍՏԱՆՁՆԱԾ ԻՐ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԱՄԲՈՂՋԱԿԱՆ ԵՎ ՊԱՏՇԱՃ ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                    </li>
                    <li>
                        ՎԱՐԿԱՌՈՒՆ ԵՎ ԵՐԱՇԽԱՎՈՐԸ ԻՐԵՆՑ ԱՆՁՆԱԿԱՆ ԱՄԲՈՂՋ ԳՈՒՅՔՈՎ (ՏՈՒՆ, ՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԿԱՄ ԱՅԼ ԳՈՒՅՔ) ՀԱՎԱՍԱՐԱՊԵՍ ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ ԵՆ ԿՐՈՒՄ «ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ – Ի ԱՌՋԵՎ ՍՏԱՆՁՆԱԾ ԻՐԵՆՑ ՎԱՐԿԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                    </li>
                    <li>
                        ՎԱՐԿԱՌՈՒԻ ԵՎ/ԿԱՄ ԵՐԱՇԽԱՎՈՐԻ ԱՆՁՆԱԿԱՆ ԳՈՒՅՔԸ ԿԱՐՈՂ Է ԲՌՆԱԳԱՆՁՎԵԼ ՕՐԵՆՔՈՎ ՍԱՀՄԱՆՎԱԾ ԿԱՐԳՈՎ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ ԺԱՄԱՆԱԿԻՆ ՉԻ ԿԱՏԱՐՈՒՄ ԻՐ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ, ՍՊԱՍԱՐԿՄԱՆ ՎՃԱՐՆԵՐԻ ԵՎ ՎԱՐԿԻ ԳՈՒՄԱՐԻ ՄԱՐՈՒՄՆԵՐԸ,
                    </li>
                    <li>
                      ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆԸ ՉԿԱՏԱՐԵԼՈՒ ԿԱՄ ԹԵՐԻ ԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ ՎԱՐԿԱՏՈՒՆ 3 (ԵՐԵՔ) ԱՇԽԱՏԱՆՔԱՅԻՆ ՕՐ ՀԵՏՈ ԱՅԴ ՏՎՅԱԼՆԵՐԸ ՈՒՂԱՐԿՈՒՄ Է ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈ, ՈՐՏԵՂ ՁԵՎԱՎՈՐՎՈՒՄ Է ՎԱՐԿԱՌՈՒԻ ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ: ՎԱՐԿԱՌՈՒՆ ԻՐԱՎՈՒՆՔ ՈՒՆԻ ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈՅԻՑ ՏԱՐԵԿԱՆ ՄԵԿ ԱՆԳԱՄ ԱՆՎՃԱՐ ՁԵՌՔ ԲԵՐԵԼ ԻՐ ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ:
                      ՎԱՏ ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ ԿԱՐՈՂ Է ԽՈՉԸՆԴՈՏԵԼ ՎԱՐԿԱՌՈՒԻ ԱՊԱԳԱՅՈՒՄ ՎԱՐԿ ՍՏԱՆԱԼՈՒ ԺԱՄԱՆԱԿ:

                    </li>
                    <li>
                      ՄԻՆՉԵՎ 5,000,000 ՀՀ ԴՐԱՄ ԿԱՄ 11,000 ԱՄՆ ԴՈԼԱՐ ՎԱՐԿ ՍՏԱՆԱԼՈՒ ՀԱՄԱՐ ԴԻՄԵԼԻՍ ՎԱՐԿԱՏՈՒՆ ՎԱՐԿԱՌՈՒԻՆ ԿՏՐԱՄԱԴՐԻ ՎԱՐԿԻ ԷԱԿԱՆ ՊԱՅՄԱՆՆԵՐԻ ԱՆՀԱՏԱԿԱՆ ԹԵՐԹԻԿ, ՈՐՈՒՄ ԿՆԵՐԿԱՅԱՑՎԵՆ ՎԱՐԿԱՌՈՒԻՆ ՏՐԱՄԱԴՐՎԵԼԻՔ ՎԱՐԿԻ ԱՆՀԱՏԱԿԱՆ ՊԱՅՄԱՆՆԵՐԸ
                    </li>
                    <li>
                      ՄԻՆՉԵՎ 5,000,000 ՀՀ ԴՐԱՄ ԿԱՄ 11,000 ԱՄՆ ԴՈԼԱՐ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԱՅԻՆ ՊԱՅՄԱՆԱԳՐԻ ԳՈՐԾՈՂՈՒԹՅԱՆ ԸՆԹԱՑՔՈՒՄ ՎԱՐԿԱՌՈՒԻ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ՎԵՐԱԲԵՐՅԱԼ ՔԱՂՎԱԾՔՆԵՐԸ ԱՄԻՍԸ ՄԵԿ ԱՆԳԱՄ ՈՒՂԱՐԿՎՈՒՄ ԵՆ ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՏՐԱՄԱԴՐՎԱԾ ԷԼԵԿՏՐՈՆԱՅԻՆ ՓԱՍՏԻ ՀԱՍՑԵԻՆ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ ՔԱՂՎԱԾՔԻ ՍՏԱՑՄԱՆ ԱՅԼ ԵՂԱՆԱԿ ՉԻ ԸՆՏՐՈՒՄ: ԱՆԿԱԽ ՆՇՎԱԾ ԵՂԱՆԱԿՈՎ ՔԱՂՎԱԾՔԸ ՍՏԱՆԱԼՈՒՑ՝ ՎԱՐԿԱՌՈՒՆ ԻՐԱՎՈՒՆՔ ՈՒՆԻ ԱՅՆ ՍՏԱՆԱԼ (ԴԻՄԵԼՈՒ ՊԱՀԻՆ) ՎԱՐԿԱՏՈՒԻ ՏԱՐԱԾՔՈՒՄ՝ ԱՌՁԵՌՆ։ ՔԱՂՎԱԾՔՆԵՐԻ ՏՐԱՄԱԴՐՈՒՄՆ ԱՆՎՃԱՐ Է:
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mainHeader">
        «ԲԻԶՆԵՍ ԹԵԹԵՎԱՑՈՒՄ» ՎԱՐԿԱՏԵՍԱԿԻ ՊԱՅՄԱՆՆԵՐԸ ԵՎ ՍԱԿԱԳՆԵՐԸ
        </div>
        <div className="termsContainer">
          <table className="termsTable smallAmount">
            <tbody>

              <tr>
                <td>Վարկի գումար</td>
                <td colSpan="2">1,000,000 – 10,000,000 ՀՀ դրամ
                         <br/>2,000 – 20,000 ԱՄՆ դոլար
                </td>

              </tr>
              <tr>
                <td>Վարկի ժամկետ (ամիս)</td>
                <td colSpan="2">12 - 60</td>
              </tr>
              <tr>
                <td>Արժույթ</td>
                <td>ՀՀ դրամ</td>
                <td>ԱՄՆ դոլար</td>
              </tr>
              <tr>
                <td>Անվանական տարեկան տոկոսադրույք*</td>
                <td>12.9%</td>
                <td>9.9</td>

              </tr>
              <tr>
                <td>Սպասարկման ամենամսյա վճար</td>
                <td colSpan="2">0.5% - 0.7%</td>
              </tr>
              <tr>
                <td>Տարեկան փաստացի տոկոսադրույք</td>
                <td>25.4% - 40.9%</td>
                <td>22.9% - 36.9%</td>
              </tr>
              <tr>
                  <td>Տրամադրման միանվագ միջնորդավճար</td>
                  <td colSpan="2">3.5%, առավելագույնը 250,000 ՀՀ դրամ</td>
              </tr>
              <tr>
                  <td>Ապահովվածություն (նվազագույն)</td>
                    <td colSpan="2">
                        <ul>	
                            <li>1,000,000  – 2,500,000 ՀՀ դրամ (համարժեք 2,000 –   5,000 ԱՄՆ դոլար) – 1 (մեկ) երաշխավոր* + 60% ծածկույթով գրավ կամ <br/>
                                1 (մեկ) ֆինանսական երաշխավոր կամ 90% ծածկույթով գրավ, </li>
                            <li>2,500,001  – 7,500,000 ՀՀ դրամ (համարժեք 5,001– 15,000 ԱՄՆ դոլար) – 1 (մեկ) ֆինանսական երաշխավոր + 40% ծածկույթով գրավ կամ <br/>
                                2 (երկու) ֆինանսական երաշխավոր կամ 90% ծածկույթով գրավ,
                                </li>
                            <li>7,500,001–10,000,000 ՀՀ դրամ (համարժեք 15,001 – 20,000 ԱՄՆ դոլար)– 2 (երկու) ֆինանսական երաշխավոր + 40% ծածկույթով գրավ կամ <br/>
                            1 (մեկ) ֆինանսական երաշխավոր + 70% ծածկույթով գրավ կամ <br/>
                            3 (երեք) ֆինանսական երաշխավոր կամ <br/>
                            90% ծածկույթով գրավ:
                            </li>
                        </ul>
                    </td>
              </tr>
              <tr>
                  <td>Մարման եղանակ</td>
                  <td colSpan="3">անուիտետային, հավասարաչափ, ճկուն գրաֆիկ</td>
              </tr>
              <tr>
                  <td>Արտոնյալ ժամկետ</td>
                  <td colSpan="3">առավելագույնը 6 ամիս</td>
              </tr>
              <tr>
                  <td>Վաղաժամկետ մարում</td>
                  <td colSpan="3">0%</td>
              </tr>
              <tr>
                  <td>Տույժեր</td>
                  <td colSpan="3">ժամկետանց տոկոսագումարի և մայր գումարի նկատմամբ օրական 0.13%</td>
              </tr>
              <tr>
                  <td>Նշումներ</td>
                  <td colSpan="3">
                  * Հաճախորդի ձեռնարկատիրական (բիզնես) գործունեությունում առկա այլ անձանց մասնակցության (փայատիրության) առկայության դեպքում անկախ վարկի գումարի չափից, տվյալ մասնակցի երաշխավորությունը հանդիսանում է վարկի
                  ապահովման պարտադիր պայման:
                </td>
              </tr>
            </tbody>
          </table>
        </div>

          <div className="termsContainer">
            <table className="termsTable mainDesc">
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "green" }}>
                    ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՈՒՄԸ
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">
                    ՈՒՇԱԴՐՈՒԹՅՈՒՆ՝ ՎԱՐԿԻ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԸ ՀԱՇՎԱՐԿՎՈՒՄ ԵՆ
                    ԱՆՎԱՆԱԿԱՆ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԻՄԱՆ ՎՐԱ: ԻՍԿ ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ
                    ՏՈԿՈՍԱԴՐՈՒՅՔԸ ՑՈՒՅՑ Է ՏԱԼԻՍ, ԹԵ ՈՐՔԱՆ ԿԱՐԺԵՆԱ ՎԱՐԿԸ ՁԵԶ
                    ՀԱՄԱՐ՝ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԸ ԵՎ ՎԱՐԿԻ ՏՐԱՄԱԴՐՄԱՆ ԵՎ ՍՊԱՍԱՐԿՄԱՆ
                    ԳԾՈՎ ԲՈԼՈՐ ՊԱՐՏԱԴԻՐ ՎՃԱՐՆԵՐԸ ՍԱՀՄԱՆՎԱԾ ԺԱՄԿԵՏՆԵՐՈՒՄ ԵՎ
                    ՉԱՓԵՐՈՎ ԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ: Տարեկան փաստացի տոկոսադրույքի
                    չափը կախված է վարկի պայմաններից (գումար, ժամկետ, վարկի
                    մարմանն ուղված վճարների գումար և մարումների քանակ)
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    Տարեկան փաստացի տոկոսադրույքը հաշվարկվում է հետևյալ
                    բանաձևով`
                    <img width="180" height="60" class="banacev" />
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">
                    որտեղ`
                    <strong>A</strong> վարկի գումարը (վարկատուի կողմից
                    վարկառուին տրամադրված վարկի սկզբնական գումարը)
                    <strong>n</strong> վարկի մարմանն ուղղված վճարի թիվը N վարկի
                    մարմանն ուղղված վերջին վճարի թիվը
                    <strong>Kn</strong> վարկի մարմանն ուղղված n-րդ վճարի գումարը
                    <strong>Dn</strong> վարկի տրամադրման օրից մինչև վարկի
                    մարմանն ուղղված n-րդ վճարի կատարման օրն ընկած
                    ժամանակահատվածը` արտահայտված օրերի թվով
                    <strong>i</strong> տարեկան փաստացի տոկոսադրույքը, որը կարող
                    է հաշվարկվել, եթե հավասարման մյուս տվյալները հայտնի են
                    վարկավորման պայմանագրից կամ այլ կերպ
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՄԱՆ ՕՐԻՆԱԿ
                  </td>
                </tr>
                <tr>
                  <td>Վարկի գումար</td>
                  <td>5,000,000 ՀՀ դրամ </td>
                </tr>
                <tr>
                  <td>Անվանական տարեկան տոկոսադրույք</td>
                  <td>13.9%</td>
                </tr>
                <tr>
                  <td>Վարկի ժամկետ</td>
                  <td>48 ամիս</td>
                </tr>
                <tr>
                  <td>Վարկի տրամադրման միանվագ վճար </td>
                  <td>վարկի գումարի 3.5%, առավելագույնը 250,000 ՀՀ դրամ</td>
                </tr>
                <tr>
                  <td>Սպասարկման ամենամսյա վճար</td>
                  <td>0.3%</td>
                </tr>
                <tr>
                  <td>Մարումներ</td>
                  <td>անուիտետային </td>
                </tr>
                <tr>
                  <td>Վարկի տրամադրման օր</td>
                  <td>15 ապրիլ 2021թ. </td>
                </tr>
                <tr>
                  <td>Առաջին մարման օր</td>
                  <td>15 մայիս 2021թ.</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <strong> Տարեկան փաստացի տոկոսադրույք` 28.7% </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsTetevacum;
