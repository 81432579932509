import React from "react";
import "./Terms.css";

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <div>
        
        <div className="tHead">
        Հաստատված է «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ <br/>
        Տնօրենների Խորհրդի Թիվ 37/22 որոշմամբ <br/>
        «Հատուկ Առաջարկ» - ը ուժի մեջ է 26.09.2022թ. – 30.12.2022թ
        </div>
        <div className="mainHeader">
          ԲԻԶՆԵՍ ՎԱՐԿ «ԱՌԱՆՑ ԳՐԱՎԻ» ՎԱՐԿԱՏԵՍԱԿԻ ՊԱՅՄԱՆՆԵՐԸ ԵՎ ՍԱԿԱԳՆԵՐԸ
        </div>
        <div className="termsContainer">
          <table className="termsTable smallAmount">
            <tbody>
              <tr>
                <td></td>
                <td colSpan="2">ՀՀ դրամ</td>
                <td colSpan="2">ԱՄՆ դոլար</td>
                <td colSpan="1">ՀՀ դրամ</td>
                <td colSpan="1">ԱՄՆ դոլար</td>
              </tr>
              <tr>
                <td>Վարկի գումար</td>
                <td >1,500,000 – 2,500,000</td>
                <td >2,500,001 – 5,000,000</td>
                <td >2,500 – 6,000</td>
                <td >6,001 – 12,000</td>
                <td >5,000,001 – 15,000,000</td>
                <td >12,001 – 35,000</td>
              </tr>
              <tr>
                <td>Տտարեկան անվանական տոկոսադրույք*</td>
                <td>21%</td>
                <td>17%</td>
                <td>17%</td>
                <td>13%</td>
                <td>17%</td>
                <td>13%</td>
              </tr>
              <tr>
                <td>Տարեկան փաստացի տոկոսադրույք</td>
                <td>36.9% - 38.5%</td>
                <td>31․6% - 33.2%</td>
                <td>31․6% - 33.2%</td>
                <td>26.6% - 28.1%</td>
                <td>28․7% - 34․9%</td>
                <td>24.1% - 29.8%</td>
              </tr>
              <tr >
                <td  rowSpan="2">Սպասարկման ամենամսյա վճար</td>
                <td colSpan="4" rowSpan="2" >0.9% - 1.1% <br/> վարկի մնացորդի վրա</td>
                <td colSpan="2">0.7% - 0.75% <br/> վարկի մնացորդի վրա</td>
                
              </tr>
              <tr>
              <td colspan="2" style={{ fontWeight: "normal",textAlign:"center" }}>0.45% - 0.5%<br/> պայմանագրի գումարից </td>
              </tr>
              <tr>
                <td>Վարկի ժամկետ (ամիս)</td>
                <td colSpan="4">12 - 48</td>
                <td colSpan="2">12 - 60</td>
              </tr>
              <tr>
                <td>Տրամադրման միանվագ միջնորդավճար</td>
                <td colSpan="4">0% <br/> վարկի մնացորդի վրա</td>
                <td colSpan="2">2% <br/> վարկի մնացորդի վրա</td>
              </tr>
              <tr>
                <td>Վաղաժամկետ մարում</td>
                <td colSpan="4">0% <br/> վարկի մնացորդի վրա</td>
                <td colSpan="2">1.5% <br/>վաղաժամկետ մարված մայր գումարից</td>
              </tr>
              <tr>
                <td>Արտոնյալ ժամկետ (մայր գումար)</td>
                <td colSpan="4">առավելագույնը 3 ամիս</td>
                <td colSpan="2">առավելագույնը 6 ամիս</td>
              </tr>
              <tr>
                <td>Ապահովվածություն (նվազագույն)*</td>
                <td colSpan="4">0 – 2 երաշխավոր</td>
                <td colSpan="2">2 – 3 երաշխավոր</td>
              </tr>
              <tr>
                <td>Տույժեր</td>
                <td colSpan="6">
                ժամկետանց տոկոսագումարի և մայր գումարի նկատմամբ օրական 0.1%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="termsContainer">
            <table className="termsTable mainDesc">
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "green" }}>
                    ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՈՒՄԸ
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">
                    ՈՒՇԱԴՐՈՒԹՅՈՒՆ՝ ՎԱՐԿԻ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԸ ՀԱՇՎԱՐԿՎՈՒՄ ԵՆ
                    ԱՆՎԱՆԱԿԱՆ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԻՄԱՆ ՎՐԱ: ԻՍԿ ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ
                    ՏՈԿՈՍԱԴՐՈՒՅՔԸ ՑՈՒՅՑ Է ՏԱԼԻՍ, ԹԵ ՈՐՔԱՆ ԿԱՐԺԵՆԱ ՎԱՐԿԸ ՁԵԶ
                    ՀԱՄԱՐ՝ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԸ ԵՎ ՎԱՐԿԻ ՏՐԱՄԱԴՐՄԱՆ ԵՎ ՍՊԱՍԱՐԿՄԱՆ
                    ԳԾՈՎ ԲՈԼՈՐ ՊԱՐՏԱԴԻՐ ՎՃԱՐՆԵՐԸ ՍԱՀՄԱՆՎԱԾ ԺԱՄԿԵՏՆԵՐՈՒՄ ԵՎ
                    ՉԱՓԵՐՈՎ ԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ: Տարեկան փաստացի տոկոսադրույքի
                    չափը կախված է վարկի պայմաններից (գումար, ժամկետ, վարկի
                    մարմանն ուղված վճարների գումար և մարումների քանակ)
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    Տարեկան փաստացի տոկոսադրույքը հաշվարկվում է հետևյալ
                    բանաձևով`
                    <img width="180" height="60" class="banacev" />
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">
                    որտեղ`
                    <strong>A</strong> վարկի գումարը (վարկատուի կողմից
                    վարկառուին տրամադրված վարկի սկզբնական գումարը)
                    <strong>n</strong> վարկի մարմանն ուղղված վճարի թիվը N վարկի
                    մարմանն ուղղված վերջին վճարի թիվը
                    <strong>Kn</strong> վարկի մարմանն ուղղված n-րդ վճարի գումարը
                    <strong>Dn</strong> վարկի տրամադրման օրից մինչև վարկի
                    մարմանն ուղղված n-րդ վճարի կատարման օրն ընկած
                    ժամանակահատվածը` արտահայտված օրերի թվով
                    <strong>i</strong> տարեկան փաստացի տոկոսադրույքը, որը կարող
                    է հաշվարկվել, եթե հավասարման մյուս տվյալները հայտնի են
                    վարկավորման պայմանագրից կամ այլ կերպ
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՄԱՆ ՕՐԻՆԱԿ
                  </td>
                </tr>
                <tr>
                  <td>Վարկի գումար</td>
                  <td>5,000,000 ՀՀ դրամ </td>
                </tr>
                <tr>
                  <td>Անվանական տարեկան տոկոսադրույք</td>
                  <td>17%</td>
                </tr>
                <tr>
                  <td>Վարկի ժամկետ</td>
                  <td>48 ամիս</td>
                </tr>
                <tr>
                  <td>Վարկի տրամադրման միանվագ վճար </td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>Սպասարկման ամենամսյա վճար</td>
                  <td>0.9% վարկի մնացորդի վրա</td>
                </tr>
                <tr>
                  <td>Մարումներ</td>
                  <td>անուիտետային </td>
                </tr>
                <tr>
                  <td>Վարկի տրամադրման օր</td>
                  <td>03 հոկտեմբեր 2022թ.</td>
                </tr>
                <tr>
                  <td>Առաջին մարման օր</td>
                  <td>03 նոյեմբեր 2022թ.</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <strong> Տարեկան փաստացի տոկոսադրույք` 31.6% </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        <h2>
          ԲԻԶՆԵՍ ՎԱՐԿ «ԱՌԱՆՑ ԳՐԱՎԻ» ՎԱՐԿԱՏԵՍԱԿԻ ՆԿԱՐԱԳԻՐԸ ԵՎ ՀԻՄՆԱԿԱՆ
          ԲՆՈՒԹԱԳՐԵՐԸ
        </h2>
        <div className="termsContainer">
          <table className="termsTable mainDesc">
            <tbody>
              <tr>
                <td colSpan="1"></td>
                <td colSpan="2">ՉԱՓԱՆԻՇՆԵՐ</td>
                <td colSpan="2">ՀԻՄՆԱԿԱՆ ԲՆՈՒԹԱԳՐԵՐ</td>
              </tr>
              <tr>
                <td colSpan="1">1</td>
                <td colSpan="2">Վարկառուի նկարագիր</td>
                <td colSpan="2">
                ՀՀ ռեզիդենտ իրավաբանական անձինք և անհատ ձեռնարկատերեր, որոնք ունեն եկամուտներ
                ձեռնարկատիրական (բիզնես) գործունեությունից
                </td>
              </tr>
              <tr>
                <td colSpan="1">2</td>
                <td colSpan="2">
                  Վարկառուին/երաշխավորին ներկայացվող պահանջներ
                </td>
                <td colSpan="2">
                  <ul>
                  <li>
                      Վարկառուի տարիքը 21 – 65 տարեկան` վարկային պայմանագրի ժամկետի ավարտի
                      պահին, ընդ որում 21 – 27 տարեկան արական սեռի վարկառուների համար պարտադիր
                      պայման է հանդիսանում զինվորական գրքույկի առկայությունը, որը կհավաստի
                      զինվորական ծառայության ավարտը (հակառակ դեպքում` ֆինանսական երաշխավորի կամ
                      ընտանիքի անդամի երաշխավորության առկայություն),
                      </li>
                      <li>
                      «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ վճարունակության գնահատման ներքին
                      չափանիշներին համապատասխանող վարկառուի ձեռնարկատիրական (բիզնես)
                      գործունեության առկայություն` առնվազն 6 ամիս, իսկ առանց երաշխավորության
                      վարկավորման դեպքում՝ առնվազն 12 ամիս,
                      </li>
                      <li>
                      Վարկառուի և երաշխավորների դրական վարկային պատմություն՝ «Միկրո Կապիտալ
                      Հայաստան» ՈՒՎԿ ՓԲԸ–ի ներքին չափանիշներին համապատասխան,
                      </li>
                      <li>
                      Վարկառուի և երաշխավորների համապատասխանություն «Միկրո Կապիտալ Հայաստան»
                      ՈՒՎԿ ՓԲԸ - ի վարկային քաղաքականությանը և ներքին կանոնակարգերով սահմանված
                      այլ չափանիշներին,
                      </li>
                      <li>
                      Առանց երաշխավորության վարկավորման դեպքում՝ անշարժ գույքի
                      սեփականատեր/համասեփականատեր հանդիսանալու փատի առկայություն (կարող է
                      դիտարկվել նաև հայտատուի ընտանիքի անդամի անշարժ գույքի
                      սեփականատեր/համասեփականատեր հանդիսանալու փաստի առկայությունը, պայմանով,
                      որ վերոնշյալ անձը հանդես կգա որպես երաշխավոր),
                      </li>
                      <li>
                      Ֆինանսական երաշխավորներ կարող են համարվել ֆիզիկական/իրավաբանական անձիք,
                      ովքեր ունեն փաստաթղթորեն հիմնավորված կայուն եկամուտ կամ անշարժ գույք, որը
                      համարժեք է վարկի գումարին
                      </li>

                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">3</td>
                <td colSpan="2">Վարկի նպատակ</td>
                <td colSpan="2">
                  <ul>
                    <li>Շրջանառու միջոցների ձեռքբերում,</li>
                    <li>Հիմնական միջոցների ձեռքբերում,</li>
                    <li>Գործառնական ծախսերի կատարում,</li>
                    <li>
                      Ձեռնարկատիրական (բիզնես) գործունեության հետ կապված
                      նպատակներ
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">4</td>
                <td colSpan="2">Վարկի արժույթ</td>
                <td colSpan="2"> 
                    <ul> <li>ՀՀ դրամ</li>
                    <li>ԱՄՆ դոլար</li>
                    </ul></td>
              </tr>
              <tr>
                <td colSpan="1">5</td>
                <td colSpan="2">
                  Վարկի տրամադրման նվազագույն և առավելագույն սահմանաչափեր
                </td>
                <td colSpan="2">1,000,000 – 15,000,000 ՀՀ դրամ/драм РА</td>
              </tr>
              <tr>
                <td colSpan="1">6</td>
                <td colSpan="2">
                    Վարկի նվազագույն և առավելագույն ժամկետներ
                </td>
                <td colSpan="2">
                  <ul>
                  <li>12 – 42 ամիս` շրջանառու միջոցների ձեռքբերման համար,</li>
                  <li>12 – 60 ամիս՝ հիմնական միջոցների և այլ ձեռնարկատիրական (բիզնես) նպատակների համար,</li>
                  <li>12 – 24 ամիս՝ առանց երաշխավորության վարկավորման դեպքում</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">7</td>
                <td colSpan="2">Մարման ժամանակացույց</td>
                <td colSpan="2">
                  <ul>
                  <li>Անուիտետային վճարումներ` հավասարաչափ վճարումներ, որի դեպքում վարկի ամբողջ
                  ժամանակահատվածի ընթացքում յուրաքանչյուր ամիս հաճախորդը կատարում է միևնույն
                  չափի վճարումներ,</li>

                  <li>Հավասարաչափ վճարումներ` հաճախորդը վճարում է յուրաքանչյուր ամիս հավասարաչափ
                  մայր գումար, որի արդյունքում տոկոսավճարների հանրագումարը ամեն ամիս նվազում է,</li>

                  <li>Ճկուն մարման ժամանակացույց` վարկի մայր գումարի մարումները բաշխվում են մարման
                  ժամանակաշրջանի վրա անհավասարաչափ</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">8</td>
                <td colSpan="2">Արտոնյալ ժամկետ մայր գումարի համար</td>
                <td colSpan="2">
                    մայր գումարի համար առավելագույնը 6 ամիս
                </td>
              </tr>
              <tr>
                <td colSpan="1">9</td>
                <td colSpan="2">Վարկի տրամադրման եղանակ</td>
                <td colSpan="2">
                    Անկանխիկ
                </td>
              </tr>
              <tr>
                <td colSpan="1">10</td>
                <td colSpan="2">Վաղաժամկետ մարում</td>
                <td colSpan="2">
                  <ul>
                  <li>
                  Վարկառուն իրավունք ունի վաղաժամկետ ամբողջական կամ մասնակի մարելու վարկը և
                  պայմանագրով սահմանված այլ պարտավորությունները,
                  </li>
                  <li>
                  Վարկի վաղաժամկետ ամբողջական մարման պարագայում վարկառուն վճարում է
                  վճարման օրվա դրությամբ հաշվարկված վարկի սպասարկման և այլ վճարները, տոկոսները
                  և վարկի մայր գումարը,
                  </li>
                  <li>
                  Վարկի վաղաժամկետ մասնակի մարման պարագայում գումարը ուղղվում է վարկի մայր
                  գումարի մարմանը և համամասնորեն նվազեցվում են վարկի հետագա ամսվա (ամիսների)
                  մարման ենթակա տոկոսագումարները, եթե հաճախորդը չի տրամադրել այլ
                  հանձնարարական:
                  </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">11</td>
                <td colSpan="2">
                  Զուգահեռ վարկեր վարկառուների խմբին/փոխկապակցված անձանց տվյալ
                  վարկատեսակի շրջանակներում
                </td>
                <td colSpan="2">
                  <ul>
                    <li>Զուգահեռ վարկերի առավելագույն քանակ - 3</li>
                    <li>
                      Զուգահեռ վարկերի առավելագույն գումարը – 15,000,000 ՀՀ դրամ
                      կամ 35,000 ԱՄՆ դոլար
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">12</td>
                <td colSpan="2">Տրանշեր</td>
                <td colSpan="2">-</td>
              </tr>
              <tr>
                <td colSpan="1">13</td>
                <td colSpan="2">Ապահովվածություն (նվազագույն)</td>
                <td colSpan="2">
                  <ul>
                  <li>
                  առանց երաշխավորության կամ
                  </li>
                  <li>
                  3 – րդ անձանց երաշխավորություն
                  </li>
                  * հաճախորդի ձեռնարկատիրական (բիզնես) գործունեությունում առկա այլ անձանց
                  մասնակցության (փայատիրության) առկայության դեպքում անկախ վարկի գումարի չափից,
                  տվյալ մասնակցի երաշխավորությունը հանդիսանում է վարկի ապահովման պարտադիր
                  պայման
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">14</td>
                <td colSpan="2">Վճարունակության գնահատում</td>
                <td colSpan="2">
                  <ul>
                    <li>հաճախորդի սոցիալական բնութագիր, </li>
                    <li>հաճախորդի վարկային պատմություն,,</li>
                    <li>
                      հաճախորդի ֆինանսական վերլուծություն (համաձայն հաստատված
                      ձևանմուշի)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">15</td>
                <td colSpan="2">Վարկերի դիտարկում (հաստատում/մերժում)</td>
                <td colSpan="2">
                  Համաձայն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ Վարկավորման
                  ընթացակարգով սահմանված որոշումների կայացման իրավասությունների
                </td>
              </tr>
              <tr>
                <td colSpan="1">16</td>
                <td colSpan="2">Որոշման կայացման ժամկետ</td>
                <td colSpan="2">
                  1 աշխատանքային օր (հաճախորդի կողմից ամբողջական փաթեթի
                  առկայության դեպքում)
                </td>
              </tr>
              <tr>
                <td colSpan="1">17</td>
                <td colSpan="2">Որոշման մասին տեղեկացնելու ժամկետ</td>
                <td colSpan="2">1 աշխատանքային օր</td>
              </tr>
              <tr>
                <td colSpan="1">18</td>
                <td colSpan="2">Վարկը տրամադրելու ժամկետ</td>
                <td colSpan="2">
                  1 աշխատանքային օր (վարկի ձևակերպման համար անհրաժեշտ
                  փաստաթղթերը ներկայացնելու դեպքում)
                </td>
              </tr>
              <tr>
                <td colSpan="1">19</td>
                <td colSpan="2">Վարկի ձևակերպման վայրը</td>
                <td colSpan="2">
                «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ գրասենյակներում կամ «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ հետ համագործակցող/գործընկեր կազմակերպության գործունեության վայրում
                </td>
              </tr>
              <tr>
                <td colSpan="1">20</td>
                <td colSpan="2">Վարկային հայտի մերժման հնարավոր պատճառներ</td>
                <td colSpan="2">
                  «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ գրասենյակներում կամ «Միկրո
                  Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ հետ համագործակցող/գործընկեր
                  կազմակերպության գործունեության վայրում
                </td>
              </tr>
              <tr>
                <td colSpan="1">21</td>
                <td colSpan="2">Վարկային հայտի մերժման հնարավոր պատճառներ</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      սահմանված նվազագույն պահանջներին չբավարարման դեպքում (1 –
                      5 կետեր)
                    </li>
                    <li>
                      հաճախորդի կողմից անհրաժեշտ փաստաթղթերի և այլ
                      հիմնավորումների չներկայացման, կեղծ փաստաթղթերի կամ
                      տեղեկատվության ներկայացման դեպքում
                    </li>
                    <li>
                      վարկային կոմիտեն չի բավարարել վարկային հայտը` հաշվի
                      առնելով գործարքի ռիսկայնությունը կամ առկա այլ ռիսկերը,
                      վարկային կոմիտեի իրավասությամբ սահմանված այլ դեպքերում,
                      այդ թվում.
                    </li>
                    
                      <ul>
                        <li>դրական վարկային պատմության բացակայություն,</li>
                        <li>իրավական ռիսկերի առկայություն,</li>
                        <li>
                          «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ կողմից
                          չֆինանսավորվող ոլորտների ցանկին համապատասխանություն,
                        </li>
                        <li>ֆինանսական ցուցանիշների անբավարարություն</li>
                      </ul>
                    
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">22</td>
                <td colSpan="2">Մոնիթորինգ</td>
                <td colSpan="2">
                  Համաձայն «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ – ի Վարկավորման
                  ընթացակարգի
                </td>
              </tr>
              <tr>
                <td colSpan="1">23</td>
                <td colSpan="2">Անհրաժեշտ փաստաթղթերի ցանկ</td>
                <td colSpan="2">
                  <ul>
                    <li>
                      Հաճախորդի (առկայության դեպքում կազմակերպության
                      հիմնադիրների, կոլեգիալ կամ միանձնյա գործադիր մարմնի
                      (տնօրենի, գլխավոր տնօրենի, գործադիր տնօրենի և այլն),
                      բաժնետերերի և երաշխավորների) անձնագիր և սոցիալական քարտ
                      կամ նույնականացման քարտ,
                    </li>
                    <li>Պետական գրանցման կամ հաշվառման վկայական, ներդիր,</li>
                    <li>ՀՎՀՀ,</li>
                    <li>Կանոնադրություն (իրավաբանական անձանց դեպքում)</li>
                    <li>
                      Լիազորված մարմնի որոշում վարկի համար դիմելու վերաբերյալ
                      (անհրաժեշտության դեպքում)
                    </li>
                    <li>Այլ փաստաթղթեր (Վարկային Կոմիտեի պահանջով)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="1">24</td>
                <td colSpan="2">
                  Տարեկան տոկոսադրույք և վարկատեսակի այլ պայմաններ
                </td>
                <td colSpan="2">
                Վարկատեսակի տոկոսադրույքը, տրամադրման վճարը և այլ պայմանները սահմանվում են
                «Միկրո Կապիտալ Հայաստան» ՈՒՎԿ ՓԲԸ լիազոր մարմնի որոշմամբ հաստատված
                վարկատեսակների պայմանների և սակագների մեջ, որի մասին հաճախորդները ծանուցվում են
                կազմակերպության բոլոր տարածքային ստորաբաժանումներում, ինչպես նաև պայմանները
                նկարագրող ինֆորմացիոն թերթիկների և կազմակերպության ինտերնետային կայքի միջոցով
                </td>
              </tr>
              <tr>
                <td colSpan="1">25</td>
                <td colSpan="2">Այլ պայմաններ</td>
                <td colSpan="2">
                1 (մեկ) հաճախորդին տրամադրված առանց գրավ վարկերի ընդհանուր մնացորդը բոլոր
                վարկատեսակների գծով չի կարող գերազանցել 15,000,000 ՀՀ դրամը (համարժեք – 35,000 ԱՄՆ
                դոլար)
                </td>
              </tr>
              <tr>
                <td colSpan="1">ԶԳՈՒՇԱՑՈՒՄ</td>
                <td colSpan="4">
                  <ul>
                  <li>
                      «ՁԵՐ ՖԻՆԱՆՍԱԿԱՆ ՏԵՂԵԿԱՏՈՒՆ»` <a href="https://www.fininfo.am" target="_blank">WWW.FININFO.AM</a>,
                      «ՈՒՇԱԴՐՈՒԹՅՈՒՆ` «ՁԵՐ ՖԻՆԱՆՍԱԿԱՆ ՏԵՂԵԿԱՏՈՒՆ» ՖԻԶԻԿԱԿԱՆ ԱՆՁԱՆՑ
                      ԱՌԱՋԱՐԿՎՈՂ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԻ ՈՐՈՆՄԱՆ, ՀԱՄԵՄԱՏՄԱՆ և ՁԵԶ ՀԱՄԱՐ
                      ԱՄԵՆԱԱՐԴՅՈՒՆԱՎԵՏ ՏԱՐԲԵՐԱԿԻ ԸՆՏՐՈՒԹՅՈՒՆԸ ՀԵՇՏԱՑՆՈՂ
                      ԷԼԵԿՏՐՈՆԱՅԻՆ ՀԱՄԱԿԱՐԳ Է»,
                      </li><li>«ԴՈՒՔ ԻՐԱՎՈՒՆՔ ՈՒՆԵՔ ՀԱՂՈՐԴԱԿՑՎԵԼ ՖԻՆԱՆՍԱԿԱՆ
                      ԿԱԶՄԱԿԵՐՊՈՒԹՅԱՆ ՀԵՏ ՁԵՐ ՆԱԽԸՆՏՐԱԾ ԵՂԱՆԱԿՈՎ՝ ՓՈՍՏԱՅԻՆ ԿԱՊԻ
                      ՄԻՋՈՑՈՎ ԿԱՄ ԷԼԵԿՏՐՈՆԱՅԻՆ ԵՂԱՆԱԿՈՎ: ԷԼԵԿՏՐՈՆԱՅԻՆ ԵՂԱՆԱԿՈՎ
                      ՏԵՂԵԿԱՏՎՈՒԹՅԱՆ ՍՏԱՑՈՒՄԸ ԱՄԵՆԱՀԱՐՄԱՐԱՎԵՏՆ Է: ԱՅՆ ՀԱՍԱՆԵԼԻ Է 24/7
                      ՌԵԺԻՄՈՎ, ԶԵՐԾ Է ԹՂԹԱՅԻՆ ՏԵՂԵԿԱՏՎՈՒԹՅԱՆ ԿՈՐՍՏԻ ՌԻՍԿԵՐԻՑ ԵՎ
                      ԱՊԱՀՈՎՈՒՄ Է ԳԱՂՏՆԻՈՒԹՅՈՒՆ»,
                      </li><li>ԱՄՆ ԴՈԼԱՐՈՎ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԵՐԻ ՊԱՐԱԳԱՅՈՒՄ ԱՐՏԱՐԺՈՒՅԹԻ
                      ՓՈԽԱՐԺԵՔԻ ՓՈՓՈԽՈՒԹՅՈՒՆՆԵՐԸ ԿԱՐՈՂ ԵՆ ԱԶԴԵՑՈՒԹՅՈՒՆ ՈՒՆԵՆԱԼ
                      ՎԱՐԿԻ ՄԱՐՄԱՆ ԳՈՒՄԱՐՆԵՐԻ ՎՐԱ,
                      </li><li>ԱՄՆ ԴՈԼԱՐՈՎ ՏՐԱՄԱԴՐՎԱԾ ՎԱՐԿԵՐԻ ՊԱՐԱԳԱՅՈՒՄ ԱՐՏԱՐԺՈՒՅԹԻ
                      ՓՈՓՈԽՈՒԹՅԱՆ ԳՈՐԾՈՆԸ ՉԻ ՆԵՐԱՌՎՈՒՄ ՎԱՐԿԻ ՏՐԱՄԱԴՐՄԱՆ ԺԱՄԱՆԱԿ
                      ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԻ ՀԱՇՎԱՐԿՄԱՆ ՄԵՋ, ՀԵՏԵՎԱԲԱՐ ՏԱՐԵԿԱՆ
                      ՓԱՍՏԱՑԻ ՏՈԿՈՍԱԴՐՈՒՅՔԸ ԿԱՐՈՂ Է ՓՈՓՈԽՎԵԼ՝ ԿԱԽՎԱԾ ՀԱՅԱՍՏԱՆԻ
                      ՀԱՆՐԱՊԵՏՈՒԹՅԱՆ ԿԵՆՏՐՈՆԱԿԱՆ ԲԱՆԿԻ ՊԱՇՏՈՆԱԿԱՆ ԿԱՅՔ ԷՋՈՒՄ
                      ՀՐԱՊԱՐԱԿՎԱԾ ՓՈԽԱՐԺԵՔԻ ՄԵԾՈՒԹՅԱՆ ՓՈՓՈԽՈՒԹՅՈՒՆԻՑ,
                      </li><li>ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՎԱՐԿԻ ՄԱՅՐ ԳՈՒՄԱՐԻ, ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ ԵՎ
                      ՍՊԱՍԱՐԿՄԱՆ ՎՃԱՐՆԵՐԻ ՄԱՐՈՒՄՆԵՐԸ ԺԱՄԱՆԱԿԻՆ ԵՎ ՊԱՏՇԱՃ
                      ՉԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ ՎԱՐԿԱՌՈՒԻ ԵՎ ԵՐԱՇԽԱՎՈՐԻ ՄԱՍԻՆ
                      ՀԱՄԱՊԱՏԱՍԽԱՆ ՏԵՂԵԿԱՏՎՈՒԹՅՈՒՆԸ ԿԳՐԱՆՑՎԻ ԿԲ ՎԱՐԿԱՅԻՆ
                      ՌԵԳԻՍՏՐՈՒՄ ԵՎ «ԱՔՌԱ» ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈՅԻ ՌԵԳԻՍՏՐՈՒՄ,
                      </li><li>«ՈՒՇԱԴՐՈՒԹՅՈՒՆ՝ ՁԵՐ ԿՈՂՄԻՑ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆԸ ՉԿԱՏԱՐԵԼՈՒ ԿԱՄ
                      ԹԵՐԻ ԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ ՎԱՐԿԱՏՈՒՆ 3 ԱՇԽԱՏԱՆՔԱՅԻՆ ՕՐ ՀԵՏՈ ԱՅԴ
                      ՏՎՅԱԼՆԵՐԸ ՈՒՂԱՐԿՈՒՄ Է ՎԱՐԿԱՅԻՆ ԲՅՈՒՐՈ, ՈՐՏԵՂ ՁԵՎԱՎՈՐՎՈՒՄ Է ՁԵՐ
                      ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ: ԴՈՒՔ ԻՐԱՎՈՒՆՔ ՈՒՆԵՔ ՎԱՐԿԱՅԻՆ
                      ԲՅՈՒՐՈՅԻՑ ՏԱՐԵԿԱՆ ՄԵԿ ԱՆԳԱՄ ԱՆՎՃԱՐ ՁԵՌՔ ԲԵՐԵԼ ՁԵՐ ՎԱՐԿԱՅԻՆ
                      ՊԱՏՄՈՒԹՅՈՒՆԸ: ՈՒՇԱԴՐՈՒԹՅՈՒՆ՝ ՎԱՏ ՎԱՐԿԱՅԻՆ ՊԱՏՄՈՒԹՅՈՒՆԸ ԿԱՐՈՂ
                      Է ԽՈՉԸՆԴՈՏԵԼ ՁԵԶ` ԱՊԱԳԱՅՈՒՄ ՎԱՐԿ ՍՏԱՆԱԼՈՒ ԺԱՄԱՆԱԿ»
                      </li><li>ՎԱՐԿԱՌՈՒՆ ԻՐ ՊԱՅՄԱՆԱԳՐԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԿԱՏԱՐՄԱՆ
                      ՀԱՄԱՐ ՆՅՈՒԹԱԿԱՆ ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ Է ԿՐՈՒՄ ՆԱԵՎ
                      ՍԵՓԱԿԱՆՈՒԹՅԱՆ ԻՐԱՎՈՒՆՔՈՎ ԻՐԵՆ ՊԱՏԿԱՆՈՂ ՑԱՆԿԱՑԱԾ ԱՅԼ ԳՈՒՅՔՈՎ
                      (ՏՈՒՆ, ԲՆԱԿԱՐԱՆ, ԱՎՏՈՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԵՎ ԱՅԼՆ), ԵԹԵ ԱՌԿԱ ԳՐԱՎԻ
                      ԱՌԱՐԿԱՆ ԲԱՎԱՐԱՐ ՉԷ «ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ – Ի
                      ՆԿԱՏՄԱՄԲ ՍՏԱՆՁՆԱԾ ԻՐ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ԱՄԲՈՂՋԱԿԱՆ ԵՎ
                      ՊԱՏՇԱՃ ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                      </li><li>ՎԱՐԿԱՌՈՒՆ ԵՎ ԵՐԱՇԽԱՎՈՐԸ ԻՐԵՆՑ ԱՆՁՆԱԿԱՆ ԱՄԲՈՂՋ ԳՈՒՅՔՈՎ (ՏՈՒՆ,
                      ՄԵՔԵՆԱ, ԱՇԽԱՏԱՎԱՐՁ ԿԱՄ ԱՅԼ ԳՈՒՅՔ) ՀԱՎԱՍԱՐԱՊԵՍ
                      ՊԱՏԱՍԽԱՆԱՏՎՈՒԹՅՈՒՆ ԵՆ ԿՐՈՒՄ «ՄԻԿՐՈ ԿԱՊԻՏԱԼ ՀԱՅԱՍՏԱՆ» ՈՒՎԿ ՓԲԸ
                      – Ի ԱՌՋԵՎ ՍՏԱՆՁՆԱԾ ԻՐԵՆՑ ՎԱՐԿԱՅԻՆ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ
                      ԿԱՏԱՐՄԱՆ ՀԱՄԱՐ,
                      </li><li>ՎԱՐԿԱՌՈՒԻ ԵՎ/ԿԱՄ ԵՐԱՇԽԱՎՈՐԻ ԱՆՁՆԱԿԱՆ ԳՈՒՅՔԸ ԿԱՐՈՂ Է
                      ԲՌՆԱԳԱՆՁՎԵԼ ՕՐԵՆՔՈՎ ՍԱՀՄԱՆՎԱԾ ԿԱՐԳՈՎ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ
                      ԺԱՄԱՆԱԿԻՆ ՉԻ ԿԱՏԱՐՈՒՄ ԻՐ ՏՈԿՈՍԱԳՈՒՄԱՐՆԵՐԻ, ՍՊԱՍԱՐԿՄԱՆ
                      ՎՃԱՐՆԵՐԻ ԵՎ ՎԱՐԿԻ ԳՈՒՄԱՐԻ ՄԱՐՈՒՄՆԵՐԸ,
                      </li><li>ՄԻՆՉԵՎ 5,000,000 ՀՀ ԴՐԱՄ ԿԱՄ ՀԱՄԱՐԺԵՔ ԱՄՆ ԴՈԼԱՐ ՎԱՐԿ ՍՏԱՆԱԼՈՒ
                      ՀԱՄԱՐ ԴԻՄԵԼԻՍ ՎԱՐԿԱՏՈՒՆ ՎԱՐԿԱՌՈՒԻՆ ԿՏՐԱՄԱԴՐԻ ՎԱՐԿԻ ԷԱԿԱՆ
                      ՊԱՅՄԱՆՆԵՐԻ ԱՆՀԱՏԱԿԱՆ ԹԵՐԹԻԿ, ՈՐՈՒՄ ԿՆԵՐԿԱՅԱՑՎԵՆ ՎԱՐԿԱՌՈՒԻՆ
                      ՏՐԱՄԱԴՐՎԵԼԻՔ ՎԱՐԿԻ ԱՆՀԱՏԱԿԱՆ ՊԱՅՄԱՆՆԵՐԸ
                      </li><li>ՄԻՆՉԵՎ 5,000,000 ՀՀ ԴՐԱՄ ԿԱՄ ՀԱՄԱՐԺԵՔ ԱՄՆ ԴՈԼԱՐ ՏՐԱՄԱԴՐՎԱԾ
                      ՎԱՐԿԱՅԻՆ ՊԱՅՄԱՆԱԳՐԻ ԳՈՐԾՈՂՈՒԹՅԱՆ ԸՆԹԱՑՔՈՒՄ ՎԱՐԿԱՌՈՒԻ
                      ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԻ ՎԵՐԱԲԵՐՅԱԼ ՔԱՂՎԱԾՔՆԵՐԸ ԱՄԻՍԸ ՄԵԿ ԱՆԳԱՄ
                      ՈՒՂԱՐԿՎՈՒՄ ԵՆ ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ՏՐԱՄԱԴՐՎԱԾ ԷԼԵԿՏՐՈՆԱՅԻՆ ՓՈՍՏԻ
                      ՀԱՍՑԵԻՆ, ԵԹԵ ՎԱՐԿԱՌՈՒՆ ՔԱՂՎԱԾՔԻ ՍՏԱՑՄԱՆ ԱՅԼ ԵՂԱՆԱԿ ՉԻ ԸՆՏՐՈՒՄ:
                      ԱՆԿԱԽ ՆՇՎԱԾ ԵՂԱՆԱԿՈՎ ՔԱՂՎԱԾՔԸ ՍՏԱՆԱԼՈՒՑ՝ ՎԱՐԿԱՌՈՒՆ ԻՐԱՎՈՒՆՔ
                      ՈՒՆԻ ԱՅՆ ՍՏԱՆԱԼ (ԴԻՄԵԼՈՒ ՊԱՀԻՆ) ՎԱՐԿԱՏՈՒԻ ՏԱՐԱԾՔՈՒՄ՝ ԱՌՁԵՌՆ։
                      ՔԱՂՎԱԾՔՆԵՐԻ ՏՐԱՄԱԴՐՈՒՄՆ ԱՆՎՃԱՐ Է:
                      </li><li>ԿԱԶՄԱԿԵՐՊՈՒԹՅՈՒՆԸ «ՓՈՂԵՐԻ ԼՎԱՑՄԱՆ ԵՎ ԱՀԱԲԵԿՉՈՒԹՅԱՆ
                      ՖԻՆԱՆՍԱՎՈՐՄԱՆ ԴԵՄ ՊԱՅՔԱՐԻ ՄԱՍԻՆ» ՀՀ ՕՐԵՆՔՈՎ ՍԱՀՄԱՆՎԱԾ
                      ՀԱՃԱԽՈՐԴԻ ՊԱՏՇԱՃ ՈՒՍՈՒՄՆԱՍԻՐՈՒԹՅԱՆ ՆՊԱՏԱԿՈՎ ԿԱՐՈՂ Է
                      ՍՊԱՌՈՂԻՑ «ՃԱՆԱՉԻՐ ՔՈ ՀԱՃԱԽՈՐԴԻՆ» («KNOW YOUR CUSTOMER»)
                      ՍԿԶԲՈՒՆՔԻ ՀԻՄԱՆ ՎՐԱ ՊԱՀԱՆՋԵԼ ԼՐԱՑՈՒՑԻՉ ՓԱՍՏԱԹՂԹԵՐ ԿԱՄ ԱՅԼ
                      ՏԵՂԵԿՈՒԹՅՈՒՆՆԵՐ, ԻՆՉՊԵՍ ՆԱԵՎ ԲԱՆԱՎՈՐ ՀԱՂՈՐԴԱԿՑՄԱՆ ԸՆԹԱՑՔՈՒՄ
                      ԼՐԱՑՈՒՑԻՉ ՀԱՐՑԵՐ ՏԱԼ ՍՊԱՌՈՂԻՆ (ՆՄԱՆ ՊԱՀԱՆՋԻ ԱՌԿԱՅՈՒԹՅԱՆ
                      ԴԵՊՔՈՒՄ)
                      </li><li>ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ԻՐ ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆՆԵՐԸ ՉԿԱՏԱՐԵԼՈՒ ԴԵՊՔՈՒՄ
                      ԵՐԱՇԽԱՎՈՐՆ Է ԴՐԱՆՔ ԿԱՏԱՐԵԼՈՒ, ՎԱՏԱՆԱԼՈՒ Է ԵՐԱՇԽԱՎՈՐԻ ՎԱՐԿԱՅԻՆ
                      ՊԱՏՄՈՒԹՅՈՒՆԸ ԵՎ ՀՆԱՐԱՎՈՐ Է ԶՐԿՎԻ ԻՐ ՍԵՓԱԿԱՆ ԳՈՒՅՔԻՑ։
                      ԿԱԶՄԱԿԵՐՊՈՒԹՅՈՒՆԸ ԵՐԱՇԽԱՎՈՐԻՆ ՏՐԱՄԱԴՐՈՒՄ Է
                      ԵՐԱՇԽԱՎՈՐՈՒԹՅԱՆ ՊԱՅՄԱՆԱԳՐԻ ՕՐԻՆԱԿԸ, ԻՆՉՊԵՍ ՆԱԵՎ
                      ԵՐԱՇԽԱՎՈՐՈՒԹՅԱՄԲ ԱՊԱՀՈՎՎԱԾ ՊԱՐՏԱՎՈՐՈՒԹՅԱՆ ՊԱՅՄԱՆԱԳՐԻ
                      ՊԱՏՃԵՆԸ։
                      ԿԱԶՄԱԿԵՐՊՈՒԹՅՈՒՆԸ ԵՐԱՇԽԱՎՈՐԻ ՊԱՀԱՆՋՈՎ ԵՐԱՇԽԱՎՈՐՈՒԹՅԱՄԲ
                      ԾԱԳՈՂ ՀԱՐԱԲԵՐՈՒԹՅՈՒՆՆԵՐԻ ԱՄԲՈՂՋ ԸՆԹԱՑՔՈՒՄ ՏՐԱՄԱԴՐՈՒՄ Է ՆՐԱՆ
                      ՎԱՐԿԻ (ՊԱՐՏԱՎՈՐՈՒԹՅԱՆ) ՄՆԱՑՈՐԴԻ ՎԵՐԱԲԵՐՅԱԼ ՏԵՂԵԿԱՏՎՈՒԹՅՈՒՆ։
                      ԿԱԶՄԱԿԵՐՊՈՒԹՅՈՒՆԸ ԵՐԱՇԽԱՎՈՐԻՆ ՏՐԱՄԱԴՐՈՒՄ Է ԳՐԱՎՈՐ
                      ՀԱՂՈՐԴԱԿՑՄԱՆ ԱՌՆՎԱԶՆ ԵՐԵՔ ԵՂԱՆԱԿԻ ԸՆՏՐՈՒԹՅԱՆ
                      ՀՆԱՐԱՎՈՐՈՒԹՅՈՒՆ (ԸՆԴ ՈՐՈՒՄ՝ ԿԱԶՄԱԿԵՐՊՈՒԹՅԱՆ ՏԱՐԱԾՔՈՒՄ
                      ՏԵՂԵԿԱՏՎՈՒԹՅԱՆ ԱՌՁԵՌՆ ՀԱՆՁՆՄԱՆ ԵՂԱՆԱԿԸ ՉԻ ԿԱՐՈՂ ԼԻՆԵԼ ՄԻԱԿ
                      ՀԱՂՈՐԴԱԿՑՄԱՆ ԵՂԱՆԱԿԸ):
                      ՎԱՐԿԱՌՈՒԻ՝ ՄԱՐՄԱՆ ԺԱՄԱԿԱՑՈՒՅՑՈՎ ՍԱՀՄԱՆՎԱԾ ՊԱՐՏԱՎՈՐՈՒԹՅԱՆ
                      ԿԱՏԱՐՄԱՆ ՕՐԻՑ ԱՌՆՎԱԶՆ 1 ՕՐ ԱՌԱՋ ԿԱԶՄԱԿԵՐՊՈՒԹՅՈՒՆԸ
                      ԵՐԱՇԽԱՎՈՐԻՆ ԾԱՆՈՒՑՈՒՄ Է ՄԱՐՄԱՆ ԵՆԹԱԿԱ ՊԱՐՏԱՎՈՐՈՒԹՅԱՆ
                      ԱՌԿԱՅՈՒԹՅԱՆ ՄԱՍԻՆ։
                      ՎԱՐԿԱՌՈՒԻ ԿՈՂՄԻՑ ԵՐԱՇԽԱՎՈՐՈՒԹՅԱՄԲ ԱՊԱՀՈՎՎԱԾ
                      ՊԱՐՏԱՎՈՐՈՒԹՅՈՒՆԸ ՉԿԱՏԱՐՎԵԼՈՒ ԿԱՄ ՈՉ ՊԱՏՇԱՃ ԿԱՏԱՐՐՎԵԼՈՒ
                      ԴԵՊՔՈՒՄ 3 ԱՇԽԱՏԱՆՔԱՅԻՆ ՕՐՎԱ ԸՆԹԱՑՔՈՒՄ ԿԱԶՄԱԿԵՐՊՈՒԹՅՈՒՆԸ
                      ԾԱՆՈՒՑՈՒՄ Է ԱՅԴ ՄԱՍԻՆ ԵՐԱՇԽԱՎՈՐԻՆ։
                      </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    );
  }
}

export default Terms;
