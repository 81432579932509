import React from 'react';
import label from '../../data/labels';
import './BorrowerType.css';

class BorrowerType extends React.Component {

    constructor(props) {
        super(props);
        this.state = { ...props };
        this.handler = this.handler.bind(this);
    }

    handler = event => {
        let clone = { ...this.state };
        clone.borrower.borrowerType = event.target.value;
        this.setState({ ...clone }, () => this.props.handler(this.state));
    };

    render() {

        return (
            <div>
                <div className="row ">
                    <div className="col-sm-3">
                        <div className="form-group">
                            <div className="inputBorrowersGroup">
                                <input id="rdbPersonal" name="borrowerType" type="radio" value="personal" checked  onChange={this.handler} />
                                <label htmlFor="rdbPersonal">{label.personalLabel}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                            <div className="inputBorrowersGroup">
                                <input id="rdbSelfEmployee" name="borrowerType" type="radio" value="selfEmployee" checked={this.state.borrower.borrowerType === "selfEmployee"} onChange={this.handler} />
                                <label htmlFor="rdbSelfEmployee">{label.selfEmployeeLabel}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                            <div className="inputBorrowersGroup">
                                <input id="rdbCorp" name="borrowerType" type="radio" value="corp" checked={this.state.borrower.borrowerType === "corp"} onChange={this.handler} />
                                <label htmlFor="rdbCorp">{label.corpLabel}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BorrowerType;
