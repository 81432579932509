import React from 'react';
import '../App.css';
import label from '../data/labels';

function WeProvide() {
  return (
    <div>
      <div className="pageSection weProvide background">
        <div className="info">
          <h1 dangerouslySetInnerHTML={{ __html: label.weProvide }}></h1>
          <div dangerouslySetInnerHTML={{ __html: label.weProvideCreditsNoPledge }} />
          {/*<div dangerouslySetInnerHTML={{ __html: label.weProvideCredits }} />*/}
        </div>
      </div>
    </div>
  );
}

export default WeProvide;