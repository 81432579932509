import React from 'react';
import '../App.css';
import label from '../data/labels';
import logo from "../images/logo-mk-main-footer.png";

function Footer() {
    return (
        <div className="container">

            {/* <div>
                <div>
                    <div>
                        <div>
                            {label.mikroKapitalAddress}
                        </div>
                        <div>
                            {label.mikroKapitalPhone}
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div> */}

            <table>
                <tbody>
                    <tr>
                        <td>
                            <div><i className="fa fa-map-marker" aria-hidden="true"></i>    {label.mikroKapitalAddress}</div>
                            <div><i className="fa fa-phone" aria-hidden="true"></i>    {label.mikroKapitalPhone}</div>
                        </td>
                        <td>
                            {label.mikroKapitalNotice}
                        </td>
                        <td>
                            <img src={logo} alt="MikroKapital" className="compLogo" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Footer;
