import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import label from '../../data/labels';
import CustomerType from '../partitions/CustomerType';
import BorrowerType from '../partitions/BorrowerType';
import styles from './../../styles/borrowerMainInfoStyles';
import BorrowerAsCorp from './BorrowerAsCorp';
import BorrowerAsPerson from './BorrowerAsPerson';
import BorrowerAsAnhat from './BorrowAsAnhat';
class BorrowerMainInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.handler = this.handler.bind(this);
  }

  handler(newState) {
    this.setState({ ...newState }, () => this.props.handler(this.state));
  }

  getContent() {
    switch (this.state.borrower.borrowerType) {
      case "personal":
        return <BorrowerAsPerson handler={this.handler} {...this.state} />;
      // case "selfEmployee":
      //   return <BorrowerAsAnhat handler={this.handler} {...this.state} />;
      // case "corp":
      //   return <BorrowerAsCorp handler={this.handler} {...this.state} />;
      default:
        return 'Unknown step';
    }
  }

  render() {

    return (
      <div>
        {/*<div>*/}
        {/*  <div className="thin">{label.dearCustomerSteps}</div>*/}
        {/*</div>*/}
        <CustomerType handler={this.handler} {...this.state}   />
        {/*<BorrowerType handler={this.handler} {...this.state} />*/}
        {this.getContent()}
      </div>
    );
  }
}
BorrowerMainInfo.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(BorrowerMainInfo);
